/**
 * sheets: Array of length 30 arrays.
 * sheet: Array of 30 labels.
 */

const binLabelsSheetTemplate = (sheets: any) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>30 labels sheet template</title>

  <style>
       body,
      html {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-family: sans-serif;
      }

      .container {
        position: relative;
        width: 8.5in;
        height: 11in;
        letter-spacing: 0;
        line-height: 0;
      }

      .container > .safe-area-container {
        position: absolute;
        top: 0.5in;
        bottom: 0.5in;
        left: 0.18in;
        right: 0.18in;
        line-height: 0;
        letter-spacing: 0;
      }

      .label-root {
        float: left;
        position: relative;
        width: 3.93in;
        height: 2in;
        line-height: normal;
        margin: 0 0.07in 0 0;
        background: #ffffff;
      }

      .label-root > .safe-area-container {
        position: absolute;
        top: 0.035in;
        bottom: 0.035in;
        left: 0.035in;
        right: 0.035in;
      }

      .label-root p {
        margin: 0;
      }

      .label-root .qr-code-wrapper {
        float: left;
        width: 1.53in;
        line-height: 0;
        margin-right: 0.07in;
        box-sizing: border-box;
        padding: 0.105in 0 0.07in 0.105in;
      }

      .qr-code-wrapper .qr-code {
        max-width: 100%;
      }

      .details-wrapper .label-meta {
        margin-right: 0.07in;
        margin-bottom: 0.07in;
        margin-top: 0.1in;
      }
      .details-wrapper .label-meta p {
        font-size: 20px;
      }
      .label-meta .icon {
        display: inline-block;
        max-width: 32px;
        max-height: 32px;
        vertical-align: middle;
        margin-right: 0.035in;
      }

      .label-meta p {
        display: inline;
      }

      .details-wrapper .label-info {
        overflow: hidden;
      }

      .label-info .title {
        font-weight: 700;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .label-info .subtitle {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .label-root .label-footer {
        clear: both;
        letter-spacing: 1px;
        line-height: 0.1in;
        font-size: 12px;
        padding-left: 0.035in;
        height: 0.1in;
      }
  </style>
</head>
<body>
  ${sheets
    .map(
      (sheet: any) => `
    <div class="container">
      <div class="safe-area-container">
    
      <di
        ${sheet
          .map(
            (label: any) => `
          <!-- BEGIN: Label -->
          <div class="label-root">
            <div class="safe-area-container">
              <div class="qr-code-wrapper">
                <img src="https://api.3hd.us/app/qrcode_gen.create?data=${label.url}&logo=momenttrack" class="qr-code"/>
              </div>
              <div class="details-wrapper">
                <div class="label-meta">
                  <img src="${label.iconUrl}" class="icon">
                  <p>${label.type}</p>
                </div>
                <div class="label-info">
                  <p class="title">${label.product_name}</p>
                  <p class="subtitle"><b>PN:</b> ${label.part_number}</p>
                </div>
              </div>
              <p class="label-footer">${label.url}</p>
            </div>
          </div>
          <!-- END: Label -->
        `
          )
          .join("")}

      </div>
    </div>
  `
    )
    .join("")}
</body>
</html>
`;

export default binLabelsSheetTemplate;
