import React from "react";
import {DialogProps} from "./types";
import MuiDialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import {useState} from "react";

const Dialog: React.FunctionComponent<DialogProps> = props => {
  const {
    title,
    buttonText = "Open Dialog",
    buttonProps,
    disableGutters,
    ...muiDialogProps
  } = props;

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button {...buttonProps} onClick={() => setOpen(true)}>
        {buttonText}
      </Button>
      <MuiDialog
        onClose={() => setOpen(false)}
        fullWidth
        {...muiDialogProps}
        open={open}
      >
        {!!title && <MuiDialogTitle>{title}</MuiDialogTitle>}
        <MuiDialogContent style={disableGutters ? {padding: 0} : undefined}>
          {props.children}
        </MuiDialogContent>
      </MuiDialog>
    </>
  );
};

export default Dialog;
