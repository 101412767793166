import React from "react";
import {Box, Card, CardHeader, makeStyles} from "@material-ui/core";
import {qtyStyles} from "../styles";
import {usePlaceReport} from "../../../core/providers/PlaceReportProvider";
import mtLogo from "../../../assets/images/mtlogo.png";
const useStyles = makeStyles(qtyStyles);

const QuantityReport: React.FC = () => {
  const classes = useStyles();

  const placeReport = usePlaceReport();

  return (
    <Card style={{padding: "1rem", background: "#a5cbf0", borderRadius: 8}}>
      <Box className={classes.content}>
        <CardHeader style={{fontSize: "2.5rem"}} title={`Quantity`} />
        <h1 className={classes.qty}>{placeReport?.lp_qty}</h1>
        <p className={classes.name}>{placeReport?.name}</p>
      </Box>
      <Box className={classes.footer}>
        <div>
          <img
            src={`https://api.3hd.us/app/qrcode_gen.create?data=${
              process.env.REACT_APP_QR_BASENAME || "3hd.us"
            }/${placeReport?.beacon_id}`}
            alt=""
          />
          <p style={{margin: 0}}>Details</p>
        </div>
        <Box>
          Powered By{" "}
          <img
            src={mtLogo}
            alt="Momenttrack trademark"
            style={{
              maxHeight: 16,
              verticalAlign: "middle",
              marginLeft: "8px",
            }}
          ></img>
        </Box>
      </Box>
    </Card>
  );
};

export default QuantityReport;
