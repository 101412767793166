
import React from 'react';
import { MainToolbarProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import styles from './styles';

const useStyles = makeStyles(styles);

const MainToolbar: React.FC<MainToolbarProps> = props => {
  const {
    contentStart,
    contentEnd,
    contentRef,

    ...toolbarProps
  } = props;

  const classes = useStyles();

  return (
    <AppBar position="relative" color="transparent">
      <Toolbar {...toolbarProps}>
        {!!contentStart && (
          <Box>{ contentStart }</Box>
        )}
        <div
          ref={contentRef}
          className={classes.contentContainer}

        >{ props.children }</div>
        {!!contentEnd && (
          <Box ml={2}>{ contentEnd }</Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default MainToolbar;
