import {useState, useEffect, useCallback} from "react";
import {BinFamily, getBinFamilyById} from "../system/bin-families";
import useNotify from "./use-notify";

export default function useSingleBinFamily(
  binFamilyId: number
): [BinFamily | null, boolean, string] {
  const [binFamily, setBinFamily] = useState<BinFamily | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const notify = useNotify();

  const fetchBinFamily = useCallback(async () => {
    try {
      setLoading(true);
      const binFamily = await getBinFamilyById(binFamilyId);
      setBinFamily(binFamily);
      setMessage("");
    } catch (err) {
      setMessage("Failed to fetch bin family.");
      notify(err as Error);
    } finally {
      setLoading(false);
    }
  }, [binFamilyId, notify]);

  useEffect(() => {
    fetchBinFamily();
  }, [fetchBinFamily]);

  return [binFamily, loading, message];
}
