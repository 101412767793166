
import { createContext } from 'react';
import { AccordionGroupContext } from './types';

const context = createContext<AccordionGroupContext>({
  expanded: null,
  setExpanded: () => {}
});

export default context;
