import React, {forwardRef, useImperativeHandle} from "react";
import validationSchema from "./validation-schema";
import {useFormik} from "formik";
import {VendorFormProps, VendorFormRef} from "./types";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import {VendorInputData} from "../../system/vendors";

const VendorForm: React.ForwardRefRenderFunction<
  VendorFormRef,
  VendorFormProps
> = function (props, ref) {
  const {initialValues = {}, onSubmit = () => {}, disabled} = props;

  const formik = useFormik<VendorInputData>({
    initialValues: {
      name: initialValues.name || "",
      erp_number: initialValues.erp_number || "",
    },
    validationSchema,
    onSubmit,
  });

  useImperativeHandle(ref, () => formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="NAME"
            {...formik.getFieldProps("name")}
            error={formik.touched["name"] && !!formik.errors["name"]}
            helperText={formik.errors["name"]}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            variant="standard"
            label="ERP NUMBER"
            {...formik.getFieldProps("erp_number")}
            error={
              formik.touched["erp_number"] && !!formik.errors["erp_number"]
            }
            helperText={formik.errors["erp_number"]}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(VendorForm);
