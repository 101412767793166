import * as Yup from "yup";

const schema = Yup.object({
  title: Yup.string().required("Required."),
  details: Yup.string().notRequired(),
  reports: Yup.array()
    .of(
      Yup.object({
        station: Yup.object({
          id: Yup.number().required("Choose a station."),
          beacon_id: Yup.string().required("Choose a station."),
          name: Yup.string(),
        }),
        default_report: Yup.string().notRequired(),
        text: Yup.array().of(Yup.string()).notRequired(),
      })
    )
    .min(1, "Add atleast 1 station."),
});

export default schema;
