import React, {useState, useImperativeHandle, forwardRef} from "react";
import {OptionsDialogProps, OptionsDialogApi} from "./types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";

const OptionsDialog: React.ForwardRefRenderFunction<
  OptionsDialogApi,
  OptionsDialogProps
> = (props, ref) => {
  const [open, setOpen] = useState(false);
  const [option, setOption] = useState<string>(props.options[0].value);

  useImperativeHandle(
    ref,
    () => ({
      openDialog: () => setOpen(true),
      closeDialog: () => setOpen(false),
    }),
    []
  );

  return (
    <Dialog open={open} fullWidth maxWidth="sm" onClose={() => setOpen(false)}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            value={option}
            onChange={(ev, val: any) => setOption(val)}
          >
            {props.options.map(el => (
              <FormControlLabel
                key={el.value}
                value={el.value}
                label={el.label}
                control={<Radio />}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => props.onSubmit(option)}
        >
          Get labels
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(OptionsDialog);
