
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme)=>createStyles({
  contentContainer: {
    flex: 1
  }
});

export default styles;
