import React, {useLayoutEffect, useState} from "react";
import {FiltersMap} from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useNotify from "../core/hooks/use-notify";
import FiltersBar from "./FiltersBar";
import useConfirmations from "../core/hooks/use-confirmations";
import {
  deleteShoppingList,
  deleteShoppingLists,
  fetchShoppingLists,
} from "../core/system/shopping-list";
import ShoppingList from "../core/system/shopping-list/types";
import DataGrid, {ColumnDef, RowData} from "../core/components/DataGrid";
import moment from "moment";
import {
  Button,
  CircularProgress,
  Fab,
  IconButton,
  Paper,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import HistoryIcon from "@material-ui/icons/History";
import {generateUrl} from "../core/system/utils/parse-momenttrack-id";
import Layout, {Content, Header, Slot} from "../core/components/Layout";
import {Link} from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@material-ui/icons/GetApp";
import {ArrowLeft} from "@material-ui/icons";
import dataToCsv from "../core/system/utils/data-to-csv";
type ListType = "added" | "deleted" | "exported";

const columnDefinitions = (type: ListType): ColumnDef[] => {
  return [
    {
      field: "productName",
      headerName: "PRODUCT",
      valueGetter: (data: ShoppingList) => data.product.name,
      sortable: true,
      // width: 250
    },
    {
      field: "quantity",
      headerName: "QTY",
      sortable: true,
      valueGetter: (data: ShoppingList) => data.quantity,
    },
    {
      field: "vendorName",
      headerName: "VENDOR",
      valueGetter: (data: ShoppingList) => data.vendor.name,
      sortable: true,
    },
    {
      field: "created_at",
      headerName: "CREATED AT",
      valueGetter: (data: ShoppingList) =>
        moment.utc(data.created_at).local().format("MMM DD, YYYY @ hh:mma"),
      sortable: true,
    },
    {
      field: "actions",
      headerName: "MARK DONE",
      // width: 200,
      renderCell: (data: ShoppingList) => {
        function DoneButton() {
          const notify = useNotify();
          const [working, setWorking] = useState(false);
          const [confirmMode, setConfirmMode] = useState(false);
          const shoppingListId = data.id;

          async function handleDelete(ev: any) {
            try {
              ev.stopPropagation();
              ev.preventDefault();

              setConfirmMode(false);
              setWorking(true);
              await deleteShoppingList(shoppingListId);
              notify("Item removed.");
            } catch (err) {
              notify(err as Error);
              setWorking(false);
            }
          }

          return (
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {!working && !confirmMode && (
                <Fab
                  size="small"
                  color="primary"
                  onClick={() => setConfirmMode(true)}
                >
                  <CheckIcon />
                </Fab>
              )}
              {working && <CircularProgress size={32} />}
              {confirmMode && !working && (
                <>
                  <Typography variant="caption" style={{marginRight: 8}}>
                    Continue?
                  </Typography>

                  <IconButton
                    onClick={() => setConfirmMode(false)}
                    size="small"
                  >
                    <CloseIcon color="error" />
                  </IconButton>

                  <IconButton onClick={handleDelete} size="small">
                    <CheckIcon color="primary" />
                  </IconButton>
                </>
              )}
            </Box>
          );
        }

        return <DoneButton />;
      },
    },
    {
      field: "bin",
      headerName: "BIN (product QR)",
      valueGetter: (data: ShoppingList) => generateUrl(data.bin.id, "product"),
      // width: 290
    },
    {
      field: "placeName",
      headerName: "PLACE",
      valueGetter: (data: ShoppingList) => data.location.name,
      sortable: true,
      // width: 150
    },
    {
      field: "placeUrl",
      headerName: "PLACE (url)",
      valueGetter: (data: ShoppingList) =>
        generateUrl(data.location.id, "place"),
      // width: 200
    },
  ].filter(el => {
    if (type !== "added" && el.field === "actions") {
      return false;
    } else {
      return true;
    }
  });
};

const ShoppingListPage: React.FC<{type: ListType}> = function ({
  type = "added",
}) {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<FiltersMap>({});
  const [shoppingLists, setshoppingLists] = useState<ShoppingList[]>([]);
  const [loading, setLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState({
    total_count: 0,
    count: 20,
    has_next: true,
    page: 0,
  });
  const [selection, setSelection] = useState<RowData[]>([]);

  const [working, setWorking] = useState(false);
  const notify = useNotify();
  const confirm = useConfirmations();

  useLayoutEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const response = await fetchShoppingLists(page, type, filters);
        setshoppingLists(response.items);
        setPaginationInfo({
          total_count: response.total_count,
          count: response.count,
          has_next: response.has_next,
          page: response.page,
        });
        setLoading(false);
        setRefetch(false);
      } catch (err) {
        // if (!active) return;
        notify(err as Error);
        setLoading(false);
      }
    })();
  }, [filters, notify, page, refetch, type]);

  function generateCsv() {
    // downloadCsv(shoppingLists);
    const rows = shoppingLists.map(el => ({
      ...el,
      bin: el.bin.name,
      location: el.location.name,
      product: el.product.name,
      vendor: el.vendor.name,
    }));
    dataToCsv(rows, `shopping_lists`);
  }

  async function handleRemove(selection: ShoppingList[]) {
    try {
      if (selection.length === 0)
        throw new Error("No rows has been selected to remove.");

      if (
        await confirm(`Continue?, Removing ${selection.length} rows of data.`)
      ) {
        setWorking(true);
        await deleteShoppingLists(selection);
        notify("Items removed.");
      }
    } catch (err) {
      notify(err as Error);
    } finally {
      setWorking(false);
    }
  }

  return (
    <>
      <Slot name="main-toolbar">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            {loading && <CircularProgress size={24} />}
            {!loading && (
              <Typography variant="h6">
                Shopping Lists {type === "added" ? "" : "History"}
              </Typography>
            )}
          </Grid>
          <Grid item xs />

          <Grid item>
            {type === "deleted" ? (
              <Link to="/shopping-lists">
                <Button
                  disabled={loading}
                  color="secondary"
                  variant="contained"
                >
                  <ArrowLeft /> Go Back
                </Button>
              </Link>
            ) : (
              <Link to="/shopping-lists/history">
                <Button
                  disabled={loading}
                  color="secondary"
                  variant="contained"
                >
                  <HistoryIcon /> View History
                </Button>
              </Link>
            )}
          </Grid>
        </Grid>
      </Slot>

      <Layout>
        <Paper>
          <Header style={{padding: "0 1rem"}}>
            <Grid container>
              <Grid item>
                <FiltersBar
                  value={filters}
                  onChange={filters => setFilters(filters)}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs zeroMinWidth />

              {type === "added" && (
                <Grid item>
                  <IconButton
                    onClick={() => handleRemove(selection as ShoppingList[])}
                    disabled={selection.length === 0}
                  >
                    <DeleteIcon
                      color={selection.length === 0 ? "inherit" : "error"}
                    />
                  </IconButton>
                </Grid>
              )}

              <Grid item>
                <IconButton
                  onClick={generateCsv}
                  color="primary"
                  disabled={selection.length === 0}
                >
                  <DownloadIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Header>
          <Content style={{background: "white", height: "calc(100vh - 140px)"}}>
            <DataGrid
              checkboxSelection
              rows={shoppingLists}
              windowHeight="calc(100% - 52px)"
              onSelectionChange={newSelection => setSelection(newSelection)}
              columns={columnDefinitions(type)}
              rowCount={paginationInfo.total_count}
              pageSize={paginationInfo.count}
              rowsPerPageOptions={[paginationInfo.count]}
              loading={loading || working}
              onPageChange={(page: number) => setPage(page)}
              isPaginated
            />
          </Content>
        </Paper>
      </Layout>
    </>
  );
};

export default ShoppingListPage;
