import { useMemo } from "react";
// import moment from "moment/moment";
import useStationReport from "./use-station-report";

const useArrivalStats = (
  placeId: number
  // dateRange = { from: new Date(0), to: new Date() }
) => {
  const report = useStationReport(placeId);

  // return useMemo(()=>{
  //   if(!report) return null;
  //   const lps = new Set<string>();
  //   const timestampRange = {
  //     from: dateRange.from.getTime(),
  //     to: dateRange.to.getTime()
  //   };

  //   return (report.logs || []).reduce((stats, log)=>{
  //     const arrivalTimestamp = moment.utc(log.arrived_at).toDate().getTime();

  //     if(!lps.has(log.license_plate.lp_id) && arrivalTimestamp >= timestampRange.from && arrivalTimestamp <= timestampRange.to){
  //       lps.add(log.license_plate.lp_id);

  //       stats.quantity += log.license_plate.quantity;
  //       const qty = stats.partsDistribution[log.product.part_number] || 0;
  //       stats.partsDistribution[log.product.part_number] = qty + log.license_plate.quantity;
  //     }

  //     return stats;
  //   }, {quantity: 0, partsDistribution: {} as Record<string, number>});
  // }, [report, dateRange]);

  const arrivalStats = useMemo(() => {
    if (!report?.line_graph_data?.dateFrom || !report?.line_graph_data?.dateTo)
      return null;

    const oneDayTime = 1000 * 60 * 60 * 24;

    // get first day Time
    const dateFrom = report.line_graph_data.dateFrom.slice(0, 10);
    const startDay = new Date(dateFrom);
    const startDayTime = startDay.getTime();

    // get last day Time
    const dateTo = report.line_graph_data.dateTo.slice(0, 10);
    const endDay = new Date(dateTo);
    const endDayTime = endDay.getTime();

    const graphData = report.line_graph_data.data.map((i) => {
      const data = i.values.reduce(
        (previousValue, value, valuesIndex, valuesArray) => {
          // get arriving Time
          const arrivedDay = new Date(value.date);
          const arrivedTime = arrivedDay.getTime();

          // add empty values
          let extraValuesBefore = [];
          let extraValuesAfter = [];
          if (valuesIndex === 0) {
            for (let i = startDayTime; i < arrivedTime; i += oneDayTime) {
              extraValuesBefore.push([i, 0]);
            }
          }

          if (valuesIndex !== 0 && valuesIndex !== valuesArray.length - 1) {
            // get previous arrived Time
            const prevElement = valuesArray[valuesIndex - 1];
            const prevArrivedDay = new Date(prevElement.date);
            const prevArrivedTime = prevArrivedDay.getTime();

            for (let i = prevArrivedTime; i < arrivedTime; i += oneDayTime) {
              extraValuesBefore.push([i, 0]);
            }
          }

          if (valuesIndex === valuesArray.length - 1) {
            if (arrivedTime !== endDayTime) {
              for (let i = arrivedTime; i < endDayTime; i += oneDayTime) {
                extraValuesAfter.push([i, 0]);
              }
            }
          }

          return [
            ...previousValue,
            ...extraValuesBefore,
            [arrivedTime, value.quantity],
            ...extraValuesAfter,
          ];
        },
        [] as number[][]
      );
      return { name: i.name, data };
    });

    return graphData;
    // eslint-disable-next-line
  }, [report, placeId]);

  return arrivalStats;
};

export default useArrivalStats;
