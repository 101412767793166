
import { useMemo } from 'react';
import usePlaceReport from './usePlaceReport';

const usePartsDistributionReport = () => {
  const report = usePlaceReport();

  return useMemo(()=>{
    if(!report) return null;

    return (report.license_plates || []).reduce((map, lp)=>{
      const currCount = map.distribution[lp.product.part_number] || 0;
      map.distribution[lp.product.part_number] = currCount + lp.quantity;
      map.totalQuantity += lp.quantity;

      return map;
    }, { totalQuantity: 0, distribution: {} as Record<string, number> });
  }, [report]);
}

export default usePartsDistributionReport;
