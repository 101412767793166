import {createStyles, Theme} from "@material-ui/core/styles";
const styles = (theme: Theme) =>
  createStyles({
    thumbnailWrapper: {
      display: "flex",
      flexWrap: "wrap",
      gap: "1rem",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "80dvh",
    },
    thumbnailButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      padding: `${theme.spacing(2)}px`,
      cursor: "pointer",
      width: "350px",
      height: "350px",
      maxWidth: "100%",
      maxHeight: "100%",
      margin: "auto",
    },
    thumbnailLabel: {
      height: "35%",
    },
    iconWrapper: {
      fontSize: 60,
    },
  });

export default styles;
