import {
  forwardRef,
  useEffect,
  useState,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {
  Dialog,
  DialogContent,
  TextField,
  Button,
  IconButton,
} from "@material-ui/core";
import EmailIcon from "../../../assets/images/email.png";
import CloseIcon from "@material-ui/icons/Close";
import {useAuthentication} from "../../providers/AuthenticationProvider";
import useNotify from "../../hooks/use-notify";
import email_template from "../../system/litmon/email-template";
import htmlToPdf, {PdfOptions} from "../../system/utils/html-to-pdf";
import sendEmail from "../../system/utils/send-email";
import {SendEmailModalProps, SendEmailModalApi} from "./types";
const useStyles = makeStyles(styles);

const defaultPdfOptions: PdfOptions = {
  pdf_mode: "landscape",
  pdf_name: `email-labels`,
  // page_size: "A4",
  margin_top: "0mm",
  margin_bottom: "0mm",
  margin_left: "0mm",
  margin_right: "0mm",
  page_height: "213mm",
  page_width: "297mm",
  disable_smart_shrinking: null,
};
const SendEmailModal: ForwardRefRenderFunction<
  SendEmailModalApi,
  SendEmailModalProps
> = ({message, title, template, pdfOptions, mailSubject, mailTitle}, ref) => {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setloading] = useState(false);
  const [email, setEmail] = useState("");
  const [person] = useAuthentication();

  useImperativeHandle(
    ref,
    () => ({
      openDialog: () => {
        setOpen(true);
      },
    }),
    []
  );

  const handleSendEmail = async () => {
    try {
      setloading(true);
      const pdf_url = await htmlToPdf(template, {
        ...defaultPdfOptions,
        ...pdfOptions,
      });

      await sendEmail({
        email: [email],
        subject: `${mailTitle}`,
        body: email_template({
          message: mailSubject,
          email,
          pdf_url,
        }),
      });
      notify("Email has been sent successfully.");
      setOpen(false);
    } catch (err) {
      notify(err as Error);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (person?.email) {
      setEmail(person.email);
    }
  }, [person?.email]);

  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
    >
      <IconButton
        onClick={() => {
          setOpen(false);
        }}
        className={classes.modalCloseBtn}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent className={classes.modalContentWrap}>
        <Box className={classes.emailModal} py={4}>
          <img src={EmailIcon} alt="Send mail icon" />
          <Typography variant="h5">{title}</Typography>
          <Typography variant="body1">{message}</Typography>
          <form
            className={classes.emailForm}
            onSubmit={async ev => {
              ev.preventDefault();
              handleSendEmail();
            }}
          >
            <TextField
              variant="outlined"
              fullWidth
              label="Email Address"
              color="secondary"
              type="email"
              value={email}
              disabled={loading}
              onChange={ev => setEmail(ev.target.value)}
              placeholder="Enter Email Address"
              inputProps={{
                tabIndex: 8,
                height: "45px",
              }}
            />
            <Button
              type="submit"
              variant={loading ? "text" : "contained"}
              color="secondary"
              tabIndex={7}
              style={{height: 45}}
              fullWidth
              disabled={loading !== false || !!email.trim() === false}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(SendEmailModal);
