
import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'relative',
    height: '100%',
    '& $invisible': {
      transition: 'opacity 300ms',
      opacity: 0.25,
      color: theme.palette.action.disabled
    },
    '&:hover': {
      boxShadow: `rgba(0,0,0,0.15) 0px 8px 8px -4px`
    },
    '&:hover $invisible': {
      opacity: 1,
      color: theme.palette.primary['main']
    },
    '&:hover $header': {
      backgroundImage: `repeating-linear-gradient(
        -45deg,
        rgba(255,255,255,0),
        rgba(255,255,255,0) 10px,
        ${theme.palette.grey['100']} 10px,
        ${theme.palette.grey['100']} 20px
      )`
    }
  },
  header: {
    backgroundColor: 'rgba(0,0,0,0)',
    backgroundImage: `repeating-linear-gradient(
      -45deg,
      rgba(255,255,255,0),
      rgba(255,255,255,0) 10px,
      ${theme.palette.grey['50']} 10px,
      ${theme.palette.grey['50']} 20px
    )`
  },
  invisible: {
    opacity: 0
  }
});

export default styles;
