import React, {useCallback, useEffect, useState} from "react";
import {
  StationsInputProps,
  StationInput,
  TodayLogs,
  stationType,
} from "./types";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Icon from "@material-ui/core/Icon";
import {
  getStationHealthReport,
  StationHealthReport,
} from "../../../core/system/reports";
import {useDateRange} from "../../../core/components/DateRangePicker";
import {
  getTodayReportText,
  getTodayStationLogs,
  getTodayStationQuantity,
} from "../../report-sections";
import usePagination from "../../../core/hooks/use-pagination";
// import {InputAdornment} from "@material-ui/core";
import {
  // ProductionOrder,
  // getProductionOrder,
  lookupProductionOrder,
} from "../../../core/system/production-orders";
// import {useFormikContext} from "formik";
// import {ReportsSectionData} from "../types";

const report_type = [
  {label: "Location", value: "location"},
  {label: "Production Order", value: "production-order"},
  {label: "Distribution Report", value: "distribution-report"},
];

const OrderInput = React.memo(
  ({
    item,
    index,
    itemChangeHandler,
  }: {
    item: StationInput;
    index: number;
    itemChangeHandler: (
      item: StationInput
    ) => (updatedValue: StationInput) => void;
  }) => {
    // const [orderInfo, setorderInfo] = useState<ProductionOrder>();
    const [docId, setdocId] = useState("");
    const [isSearching, setisSearching] = useState(false);
    const [isValid, setisValid] = useState<boolean | null>();

    useEffect(() => {
      setdocId(item.station.beacon_id);
      setisValid(true);
    }, [item.station.beacon_id]);

    const onOrderSearch = async (order_id: string) => {
      try {
        setisSearching(true);
        const lookupRes = await lookupProductionOrder(order_id);
        // const orderRes = await getProductionOrder(lookupRes.id);
        // setorderInfo(orderRes);
        itemChangeHandler(item)({
          ...item,
          station: {
            ...item.station,
            beacon_id: lookupRes?.docid,
            id: lookupRes.id,
          },
        });
        setisValid(true);
      } catch (error) {
        setisValid(false);
        alert("Invalid order id");
      } finally {
        setisSearching(false);
      }
    };

    return (
      <>
        <Grid item xs>
          <TextField
            label="Enter Order Id"
            value={docId}
            disabled={isSearching}
            onChange={ev => {
              setdocId(ev.target.value);
            }}
            onBlur={() => onOrderSearch(docId)}
            fullWidth
            error={isValid === null ? false : !isValid}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       <IconButton
            //         aria-label="find order"
            //         onClick={() => onOrderSearch(docId)}
            //         edge="end"
            //       >
            //         <Icon color="secondary">search</Icon>
            //       </IconButton>
            //     </InputAdornment>
            //   ),
            // }}
            variant="filled"
            size="small"
          ></TextField>
        </Grid>
        {/* <Grid
          style={{
            background: "rgba(0, 0, 0, 0.09)",
            padding: "0.5rem 1rem",
            margin: "0.5rem 0",
          }}
          item
          xs={12}
        >
          <p
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <span>{isSearching ? "Searching..." : "Search Result"}</span>
            {orderInfo && (
              <>
                <span>Part Number: {orderInfo?.product.part_number}</span>
                <span>Work Order: {orderInfo?.external_docid}</span>
                <span>Description: {orderInfo?.product.description}</span>
              </>
            )}
          </p>
        </Grid> */}
      </>
    );
  }
);

const StationsInput: React.FC<StationsInputProps> = props => {
  const {value, onChange, stationOptions, reportOptions, disabled} = props;
  const [placeInfo, setPlaceInfo] = useState<StationHealthReport | null>(null);
  const [todayLogs, setTodayLogs] = useState<TodayLogs | null>(null);
  const dateRange = useDateRange();
  const [
    page,
    ,
    loadingLogs,
    setPage,
    setLoadingLogs,
    getAvailablePages,
    handleLoadMore,
  ] = usePagination();

  const handleDrop = useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      const srcIndex = result.source.index;
      const destIndex = result.destination.index;
      const items = [...value];
      const item = items[srcIndex];

      items.splice(srcIndex, 1);
      items.splice(destIndex, 0, item);

      onChange(items);
    },
    [value, onChange]
  );

  const handleRemove = useCallback(
    index => {
      const items = [...value];
      if (index > -1) {
        items.splice(index, 1);
        onChange(items);
      }
    },
    [value, onChange]
  );

  const handleAddItem = useCallback(() => {
    onChange([
      ...value,
      {
        station: {
          id: NaN,
          beacon_id: "",
          lp_qty: NaN,
        },
        default_report: "",
        type: "location",
      },
    ]);
  }, [value, onChange]);

  const getStationReport = useCallback(
    async (stationId: string | number, page: number) => {
      try {
        setLoadingLogs(true);
        const info = await getStationHealthReport(stationId, {
          page,
          // start_date: dateRange.from,
          // end_date: dateRange.to,
        });

        setPlaceInfo(previousInfo => {
          if (!previousInfo || previousInfo.location_id !== stationId) {
            return info;
          }
          return {
            ...previousInfo,
            logs:
              previousInfo.logs && info.logs
                ? [...previousInfo.logs, ...info.logs]
                : previousInfo.logs,
          };
        });
        setLoadingLogs(false);
        getAvailablePages(info.logs_total);
      } catch (err) {
        alert((err as Error).message);
        setLoadingLogs(false);
      }
    },
    // eslint-disable-next-line
    [placeInfo]
  );

  const itemChangeHandler = useCallback(
    (item: StationInput) => (updatedValue: StationInput) => {
      const items = [...value];
      const index = items.indexOf(item);
      if (index > -1) {
        items[index] = updatedValue;
        onChange(items);
        if (updatedValue?.station && updatedValue.type === "location") {
          if (Number(placeInfo?.location_id) === updatedValue.station.id)
            return;
          setPage(1);
          getStationReport(updatedValue.station.id!, 1);
        }
      }
    },
    // eslint-disable-next-line
    [value, onChange, getStationReport, placeInfo]
  );

  useEffect(() => {
    if (!placeInfo || !placeInfo.logs) return;

    const logs = getTodayStationLogs(placeInfo.logs, dateRange);
    if (!logs) return;
    if (logs.length === 50 * page) {
      handleLoadMore();
      return;
    }

    if (!todayLogs) {
      setTodayLogs({[placeInfo.location_id]: logs});
    } else {
      setTodayLogs(prevState => ({
        ...prevState,
        [placeInfo.location_id]: logs,
      }));
    }
    // eslint-disable-next-line
  }, [placeInfo, placeInfo?.logs?.length]);

  useEffect(() => {
    // If todayLogs or value was changed, getText.
    if (!todayLogs || !placeInfo || !value) return;
    // But do Not Update Value (do not get Text), if each item of value:
    // has id which is not equal to current placeInfo id
    // OR already has text
    // OR doesn't have moved today logs.
    const doNotUpdateValue = value.every(i => {
      return (
        !i.station ||
        i.station.id !== Number(placeInfo.location_id) ||
        (i.station.id === Number(placeInfo.location_id) && i.text) ||
        (i.station.id === Number(placeInfo.location_id) &&
          !todayLogs[i.station.id])
      );
    });
    if (doNotUpdateValue) return;

    const texts = getTodayReportText(todayLogs[placeInfo.location_id]);
    const newValue = value.map(i => {
      if (i.station?.id === Number(placeInfo.location_id)) {
        return {...i, text: texts};
      }
      return i;
    });
    onChange(newValue);
    // eslint-disable-next-line
  }, [todayLogs, value]);

  useEffect(() => {
    // If page was changed, fetch.
    if (page === 1) return;
    if (!placeInfo) return;
    getStationReport(placeInfo.location_id, page);
    // eslint-disable-next-line
  }, [page]);
  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Droppable droppableId="selected-stations" type="SELECTED_STATIONS">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {value.map((item, i) => (
              <Draggable
                key={`${i}-${item.station?.id}`}
                draggableId={`${i}-${item.station?.id}`}
                index={i}
              >
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.draggableProps}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: "1rem",
                        padding: "0.5rem 0",
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        <Icon
                          color={snapshot.isDragging ? "primary" : "action"}
                        >
                          drag_indicator
                        </Icon>
                      </div>
                      <Grid
                        container
                        spacing={1}
                        alignItems="center"
                        // wrap="nowrap"
                      >
                        <Grid item xs>
                          <TextField
                            select
                            label="Report Type"
                            value={item.type}
                            disabled={disabled || loadingLogs}
                            onChange={ev =>
                              itemChangeHandler(item)({
                                ...item,
                                type: ev.target.value,
                              })
                            }
                            fullWidth
                            variant="filled"
                            size="small"
                          >
                            {report_type.map(opt => (
                              <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}
                              </MenuItem>
                            ))}
                          </TextField>
                        </Grid>

                        {item.type === "location" ? (
                          <>
                            <Grid item xs>
                              <Autocomplete
                                id="combo-box-demo"
                                options={stationOptions}
                                getOptionLabel={option => option?.name || ""}
                                value={item.station}
                                onChange={(ev, val) => {
                                  itemChangeHandler(item)({
                                    ...item,
                                    station: val as stationType,
                                  });
                                }}
                                getOptionSelected={(opt, val) =>
                                  val?.beacon_id === opt?.beacon_id
                                }
                                disabled={disabled}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Station"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs>
                              <TextField
                                select
                                label="Default report"
                                value={item.default_report}
                                disabled={
                                  disabled ||
                                  loadingLogs ||
                                  isNaN(item.station.id)
                                }
                                onChange={ev =>
                                  itemChangeHandler(item)({
                                    ...item,
                                    default_report: ev.target.value,
                                  })
                                }
                                fullWidth
                                variant="filled"
                                size="small"
                              >
                                {reportOptions.map(opt => (
                                  <MenuItem key={opt.value} value={opt.value}>
                                    {opt.label}
                                  </MenuItem>
                                ))}
                                {item?.station &&
                                  todayLogs &&
                                  todayLogs[item.station.id] && (
                                    <MenuItem value="moved-today">
                                      {getTodayStationQuantity(
                                        todayLogs[item.station.id]
                                      )}
                                      &nbsp;moved today
                                    </MenuItem>
                                  )}
                              </TextField>
                            </Grid>
                          </>
                        ) : item.type === "distribution-report" ? (
                          <>
                            <Grid item xs>
                              <Autocomplete
                                id="distribution-report"
                                multiple
                                disableCloseOnSelect
                                options={stationOptions}
                                getOptionLabel={option => option?.name || ""}
                                value={item?.stations || []}
                                onChange={(ev, val) => {
                                  itemChangeHandler(item)({
                                    ...item,
                                    station: val[0] as stationType, // TODO: Find a better way to handle
                                    stations: val as stationType[],
                                  });
                                }}
                                getOptionSelected={(opt, val) =>
                                  val?.beacon_id === opt?.beacon_id
                                }
                                disabled={disabled}
                                renderInput={params => (
                                  <TextField
                                    {...params}
                                    label="Location"
                                    fullWidth
                                    variant="filled"
                                    size="small"
                                  />
                                )}
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <OrderInput
                              itemChangeHandler={itemChangeHandler}
                              index={i}
                              item={item}
                            />
                          </>
                        )}
                      </Grid>
                      {!snapshot.isDragging && (
                        <IconButton
                          onClick={() => handleRemove(i)}
                          color="secondary"
                          disabled={disabled}
                        >
                          <Icon>remove</Icon>
                        </IconButton>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}

            <IconButton
              onClick={handleAddItem}
              color="secondary"
              disabled={disabled}
            >
              <Icon>add</Icon>
            </IconButton>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default StationsInput;
