import {Theme, createStyles} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      position: "relative",
      height: "100%",
    },
    table: {
      tableLayout: "auto",
      width: "100%",
      marginBottom: theme.spacing(6),
    },
    tableCell: {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      // overflow: "hidden",
    },
    noData: {
      display: "flex",
      height: "300px",

      "& svg": {
        fontSize: 64,
      },
      "& p": {
        fontSize: 28,
        fontWeight: 500,
      },
    },
    loadingOverlay: {
      position: "absolute",
      zIndex: 999,
      backgroundColor: "rgba(255,255,255,0.5)",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    circularProgressContainer: {
      padding: theme.spacing(3),
      backgroundColor: "rgba(255,255,255,0.9)",
      borderRadius: 1000,
      lineHeight: 0,
      boxShadow: theme.shadows[2],
      display: "flex",
      justifyCenter: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  });

export default styles;
