import {ProtectedRouteComponent, LocationState} from "./types";
import {Route, Redirect, useLocation} from "react-router-dom";

const ProtectedRoute: ProtectedRouteComponent = ({
  allow,
  redirectPath,
  children,
  ...routeProps
}) => {
  const currentLocation = useLocation<LocationState>();

  const redirectTo = redirectPath && {
    pathname: redirectPath,
    state: {
      referrer: currentLocation,
    },
  };

  return allow ? (
    <Route {...routeProps}>{children}</Route>
  ) : (
    <Redirect to={redirectTo || currentLocation.state?.referrer || "/"} />
  );
};

export default ProtectedRoute;
