
import React, { useContext, useState, useLayoutEffect } from 'react';
import Portal from '@material-ui/core/Portal';
import layoutContext from './context';

export interface SlotProps {
  name: string;
}

const Slot: React.FC<SlotProps> = props => {
  const [containerExists, setContainerExists] = useState(false);
  const { getSlot } = useContext(layoutContext);
  const slotContainer = getSlot(props.name);

  useLayoutEffect(()=>{
    setContainerExists(typeof slotContainer === 'function' ? !!slotContainer() : !!slotContainer);
  }, [slotContainer]);

  return (
    <Portal container={slotContainer} disablePortal={!containerExists}>{ props.children }</Portal>
  );
};

export default Slot;
