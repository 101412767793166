
import React, { useState, useLayoutEffect, useCallback } from 'react';
import context from './context';
import useNotify from '../../hooks/use-notify';
import { useAuthentication } from '../AuthenticationProvider';
import { getAllProducts, getProduct, createProduct, updateProduct, Product, ProductInputData } from '../../system/products';

const ProductsProvider: React.FC = ({ children }) => {
  const authenticated = !!useAuthentication()[0];
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, _setLoading] = useState(0);
  const notify = useNotify();

  const setLoading = (increment: boolean) => _setLoading(prevVal=>increment ? ++prevVal : --prevVal);

  /**
   * Get product by id.
   */
  const getById = useCallback(async (productId: number, forceFetch = false): Promise<Product | null> => {
    try{
      const product = products.find(p=>p.id === productId) || null;
      if(!product || forceFetch){
        setLoading(true);
        const product = await getProduct(productId);
        setLoading(false);
        return product;
      }

      return product;
    }
    catch(err: any){
      notify(err as Error);
      setLoading(false);
      return null;
    }

  }, [notify, products]);

  /**
   * Create new product.
   */
  const create = useCallback(async (productData: ProductInputData): Promise<Product | null> => {
    try{
      setLoading(true);
      const product = await createProduct(productData);
      setProducts(prevState=>[product, ...prevState]);
      setLoading(false);
      return product;
    }
    catch(err: any){
      setLoading(false);
      notify(err as Error);
      return null;
    }

  }, [notify]);

  const update = useCallback(async (productId: number, updatedData: ProductInputData) => {
    try{
      setLoading(true);
      const updatedProduct = await updateProduct(productId, updatedData);
      setProducts(products=>{
        const index = products.findIndex(p=>p.id === productId);
        if(index >= 0)
          products.splice(index, 1, updatedProduct);
        else
          return products;
        
        return [...products];
      });
      setLoading(false);
      notify(`Updated "${updatedProduct.name}".`);
      return updatedProduct;
    }
    catch(err: any){
      setLoading(false);
      notify(err as Error);
      return null;
    }
  }, [notify]);

  useLayoutEffect(()=>{
    if(!authenticated) return;
    let active = true;
    (async ()=>{
      try{
        setLoading(true);
        const products = await getAllProducts();

        if(!active) return;
        setProducts(products);
        setLoading(false);
      }
      catch(err: any){
        if(!active) return;
        notify(err as Error);
      }
    })();

    return ()=>{ active=false; };
  }, [notify, authenticated]);

  return (
    <context.Provider value={[products, !!loading, { getById, create, update }]}>
      {children}
    </context.Provider>
  );
}

export default ProductsProvider;
