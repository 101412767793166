
import React, { useState, useEffect, useCallback, useRef } from 'react';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import { RouteComponentProps } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Countdown, { CountdownRef } from '../core/components/Countdown';

import { acceptPersonInvite } from '../core/system/authentication';
import { publish } from '../core/system/pubsub';
import styles from './styles';

const useStyles = makeStyles(styles);

const AcceptInvite: React.FC<RouteComponentProps> = function(props) {
  const {
    location,
    history
  } = props;

  const timer = useRef<CountdownRef>(null);

  const [message, setMessage] = useState('');
  const [accountActivated, setAccountActivated] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const { token, org } = qs.parse(location.search, {ignoreQueryPrefix: true}) as { token: string, org: string };

  const acceptInvite = useCallback(async ()=>{
    try{
      if(!token || !org)
        return setMessage('This link seems to be broken/incomplete.');
        
      setLoading(true);
      setMessage('Verifying...');
      await acceptPersonInvite(token);

      setAccountActivated(true);
      setMessage('Account activated! Redirecting in');
      timer.current?.start();
    }
    catch(err: any){
      setMessage((err as Error).message);
    }
    finally{
      setLoading(false);
    }
  }, [token, org]);

  const handleContinue = useCallback(()=>{
    timer.current?.reset();
    history.replace({ ...location, pathname: '/reset-password', search: `?token=${token}&org=${org}` });

  }, [history, token, org, location]);

  useEffect(() => {
    publish('update:authentication', { org_slug: org || 'default' }, { trigger: 'auth-initialization' });
  }, [org]);

  useEffect(()=>{
    acceptInvite();

  }, [acceptInvite]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <Paper>
          <Box p={3}>
            <Grid container spacing={2} alignItems="center">
              {loading && <Grid item>
                <CircularProgress size={24}/>
              </Grid>}
              <Grid item>
                <Typography>
                  { message }
                  &nbsp;
                  <Countdown
                    ref={timer}
                    hidden={!accountActivated || loading}
                    startValue={5}
                    onEnd={handleContinue}
                  />
                </Typography>
              </Grid>
              {accountActivated && (
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    disabled={loading}
                    onClick={handleContinue}
                  >
                    Continue...
                  </Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default AcceptInvite;
