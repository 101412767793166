
import React from 'react';
import { StationReportsProviderProps } from './types';
import context from './context';

const StationReportsProvider: React.FC<StationReportsProviderProps> = props => {  
  return (
    <context.Provider value={props.reportsData}>
      {props.children}
    </context.Provider>
  );
};

export default StationReportsProvider;
