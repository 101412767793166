import {
  Drawer,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Radio,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from "@material-ui/core";
import {EditTemplateDrawerProps} from "./types";
import {ReactNode, useEffect, useState} from "react";
import "./style.css";
import {TemplateComponent} from "../TemplateParser";
import {MappedData, componentTypes} from "../../../TemplateBuilder";
import {generateId} from "../../../utils";
import InfoIcon from "@material-ui/icons/Info";

const EditTemplateDrawer = ({
  children,
  type,
  componentId,
  onEditSave,
  data,
  ...props
}: EditTemplateDrawerProps) => {
  const [editData, setEditData] = useState(data);

  const editComponentProps = (otherProps: any) => {
    setEditData(prev => ({
      ...prev,
      children: prev.children.map(el =>
        el.id === componentId
          ? {
              ...el,
              children: {
                ...(el.children as TemplateComponent),
                props: {
                  ...(el.children as TemplateComponent).props,
                  ...otherProps,
                },
              },
            }
          : el
      ),
    }));
  };

  const getComponentData = () =>
    editData.children.find(el => el.id === componentId)
      ?.children as TemplateComponent;
  useEffect(() => {
    setEditData(data);
  }, [data]);

  const editSocialMedia = (type: string, value: string) => {
    setEditData(prev => {
      let newData = prev?.children?.find(el => el.id === componentId)
        ?.children as TemplateComponent[];
      newData[0].props.icons[type] = value;

      return {
        ...prev,
        children: prev.children.map(el =>
          el.id === componentId ? {...el, ...newData} : el
        ),
      };
    });
  };

  const backgroundColorField = (
    <TextField
      fullWidth
      label="Background color"
      type="color"
      name="src"
      value={
        editData.children.find(el => el.id === componentId)?.props?.style
          ?.backgroundColor
      }
      onChange={e => {
        setEditData(prev => ({
          ...prev,
          children: prev.children.map(el =>
            el.id === componentId
              ? {
                  ...el,
                  props: {
                    ...el.props,
                    style: {
                      ...el.props.style,
                      backgroundColor: e.target.value,
                    },
                  },
                }
              : el
          ),
        }));
      }}
    />
  );

  // Handles click event for adding a component
  const handleAddComponent = (componentType: componentTypes) => {
    const defaultData = MappedData[componentType];
    const id = generateId();
    const accordionParent: any = editData.children.find(
      el => el.id === componentId
    );

    // Check if accordionParent is defined and has children
    if (accordionParent && accordionParent.children.children) {
      let accordionData: any =
        (accordionParent.children.children as TemplateComponent[]) || [];

      // Update the children property
      accordionParent.children.children = [
        ...accordionData,
        {
          ...defaultData,
          id,
          editType: componentType,
        },
      ];
      setEditData(prev => ({
        ...prev,
        children: prev.children.map(el => {
          return el.id === componentId ? {...el, ...accordionParent} : el;
        }),
      }));
    }
  };

  // return the appropriate component based on the type string
  function getEditType(type: string): string | ReactNode {
    switch (type.trim()) {
      case "Button":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Button Text"
              name="children"
              value={getComponentData()?.children}
              onChange={e => {
                setEditData(prev => ({
                  ...prev,
                  children: prev.children.map(el =>
                    el.id === componentId
                      ? {
                          ...el,
                          children: {
                            ...(el.children as TemplateComponent),
                            children: e.target.value,
                          },
                        }
                      : el
                  ),
                }));
              }}
            />
            <TextField
              fullWidth
              label="Button url"
              type="url"
              name="href"
              value={getComponentData()?.props?.href}
              onChange={e => {
                editComponentProps({href: e.target.value, component: "a"});
              }}
            />
            <FormControl style={{marginTop: "1rem"}} component="fieldset">
              <FormLabel component="legend">Variant</FormLabel>
              <RadioGroup
                row
                aria-label="variant"
                name="variant"
                value={getComponentData()?.props?.variant}
                onChange={e => {
                  editComponentProps({variant: e.target.value});
                }}
              >
                {["Contained", "Outlined", "Text"].map(el => (
                  <FormControlLabel
                    key={el}
                    value={el.toLowerCase()}
                    control={<Radio color="primary" />}
                    label={el}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        );
      case "Text":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Edit Text"
              name="children"
              value={getComponentData()?.children}
              onChange={e => {
                setEditData(prev => ({
                  ...prev,
                  children: prev.children.map(el =>
                    el.id === componentId
                      ? {
                          ...el,
                          children: {
                            ...(el.children as TemplateComponent),
                            children: e.target.value,
                          },
                        }
                      : el
                  ),
                }));
              }}
            />
            <FormControl>
              <InputLabel id="text-variant-select-label">
                Change text variant
              </InputLabel>
              <Select
                labelId="text-variant-select-label"
                id="text-variant-select"
                value={getComponentData()?.props?.variant}
                onChange={e => {
                  editComponentProps({variant: e.target.value});
                }}
              >
                <MenuItem value={"h1"}>Heading 1</MenuItem>
                <MenuItem value={"h2"}>Heading 2</MenuItem>
                <MenuItem value={"h3"}>Heading 3</MenuItem>
                <MenuItem value={"h4"}>Heading 4</MenuItem>
                <MenuItem value={"h5"}>Heading 5</MenuItem>
                <MenuItem value={"subtitle1"}>Subtitle</MenuItem>
                <MenuItem value={"body1"}>Body</MenuItem>
                <MenuItem value={"caption"}>Caption</MenuItem>
                <MenuItem value={"overline"}>Overline</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{marginTop: "1rem"}} component="fieldset">
              <FormLabel component="legend">Text align</FormLabel>
              <RadioGroup
                row
                aria-label="align"
                name="align"
                value={getComponentData()?.props?.style?.textAlign}
                onChange={e => {
                  editComponentProps({
                    style: {
                      ...getComponentData()?.props?.style,
                      textAlign: e.target.value,
                    },
                  });
                }}
              >
                {["Left", "Center", "Right", "Justify"].map(el => (
                  <FormControlLabel
                    key={el}
                    value={el.toLowerCase()}
                    control={<Radio color="primary" />}
                    label={el}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <TextField
              fullWidth
              label="Text color"
              type="color"
              name="color"
              value={getComponentData()?.props?.style?.color}
              onChange={e => {
                editComponentProps({
                  style: {
                    ...getComponentData()?.props?.style,
                    color: e.target.value,
                  },
                });
              }}
            />
          </div>
        );
      case "Header":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Logo url"
              type="url"
              name="src"
              value={getComponentData()?.props?.src}
              onChange={e => {
                editComponentProps({src: e.target.value});
              }}
            />
            {backgroundColorField}
          </div>
        );
      case "Image":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Image url"
              type="url"
              name="src"
              value={getComponentData()?.props?.src}
              onChange={e => {
                editComponentProps({src: e.target.value});
              }}
            />
            <TextField
              fullWidth
              label="Image width"
              name="width"
              // type="number"
              value={
                editData.children.find(el => el.id === componentId)?.props
                  ?.style?.width
              }
              onChange={e => {
                // editComponentProps({style: {width: e.target.value}});
                setEditData(prev => ({
                  ...prev,
                  children: prev.children.map(el =>
                    el.id === componentId
                      ? {
                          ...el,
                          props: {
                            ...el.props,
                            style: {
                              ...el.props.style,
                              display: "inline-block",
                              width: e.target.value,
                            },
                          },
                        }
                      : el
                  ),
                }));
              }}
            />
          </div>
        );
      case "Video":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Video url"
              type="url"
              name="src"
              value={getComponentData()?.props?.src}
              onChange={e => {
                editComponentProps({src: e.target.value});
              }}
            />
            <TextField
              fullWidth
              label="Video Title"
              name="title"
              value={getComponentData()?.props?.title}
              onChange={e => {
                editComponentProps({title: e.target.value});
              }}
            />
          </div>
        );
      case "Footer":
        return (
          <div className="form-group">
            <TextField
              fullWidth
              label="Footer image url"
              type="url"
              name="src"
              value={
                (
                  editData?.children?.find(el => el.id === componentId)
                    ?.children as TemplateComponent[]
                )?.[1]?.props?.src
              }
              onChange={e => {
                setEditData(prev => {
                  let newData = prev?.children?.find(
                    el => el.id === componentId
                  )?.children as TemplateComponent[];
                  newData[1].props.src = e.target.value;
                  return {
                    ...prev,
                    children: prev.children.map(el =>
                      el.id === componentId ? {...el, ...newData} : el
                    ),
                  };
                });
              }}
            />
            {backgroundColorField}

            {["Youtube", "Twitter", "Instagram", "Linkedin", "Facebook"].map(
              el => (
                <div>
                  <TextField
                    fullWidth
                    key={el}
                    label={`${el} url`}
                    name={`${el} url`}
                    type="url"
                    value={
                      (
                        editData?.children?.find(el => el.id === componentId)
                          ?.children as TemplateComponent[]
                      )?.[0]?.props?.icons?.[el.toLowerCase()]
                    }
                    onChange={e => {
                      editSocialMedia(el.toLowerCase(), e.target.value);
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">@</InputAdornment>
                      ),
                    }}
                  />
                </div>
              )
            )}

            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                margin: "0.3rem 0 0.5rem",
              }}
            >
              <InfoIcon />{" "}
              <span style={{lineHeight: "normal"}}>
                Leave the social media field empty to remove it from the footer
              </span>
            </span>
          </div>
        );
      case "Accordion":
        return (
          <>
            <TextField
              fullWidth
              label="Accordion Title"
              name="children"
              value={
                (getComponentData()?.children as TemplateComponent)?.props
                  ?.title
              }
              onChange={e => {
                setEditData(prev => {
                  let newData: any = prev?.children?.find(
                    el => el.id === componentId
                  );
                  if ((newData?.children as TemplateComponent)?.props?.title) {
                    newData.children.props.title = e.target.value;
                  }
                  return {
                    ...prev,
                    children: prev.children.map(el =>
                      el.id === componentId ? {...el, ...newData} : el
                    ),
                  };
                });
              }}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">
                Add Element To Accordion
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                onChange={e => {
                  const {value} = e.target;
                  handleAddComponent(value as componentTypes);
                  return;
                }}
              >
                <MenuItem value={"Button"}>Button</MenuItem>
                <MenuItem value={"Text"}>Text</MenuItem>
                <MenuItem value={"Image"}>Image</MenuItem>
              </Select>
            </FormControl>
          </>
        );
      default:
        return type.trim().toLowerCase();
    }
  }

  return (
    <>
      <Drawer
        PaperProps={{style: {maxWidth: 400, width: "90%"}}}
        anchor={"right"}
        open={props.open}
        onClose={e => {
          if (props.onClose) {
            props.onClose(e, "backdropClick");
          }
        }}
      >
        <form
          onSubmit={e => {
            e.preventDefault();
            onEditSave(editData);
          }}
          style={{
            padding: "2rem",
            display: "flex",
            gap: "1rem",
            flexDirection: "column",
            minHeight: "100%",
          }}
        >
          {getEditType(type)}
          <div className="btn-wrap">
            <Button
              onClick={e => {
                if (props.onClose) {
                  props.onClose(e, "backdropClick");
                }
              }}
              type="button"
              variant="outlined"
              color="primary"
            >
              Close
            </Button>
            <Button type="submit" color="primary">
              Save
            </Button>
          </div>
        </form>
      </Drawer>
    </>
  );
};
export default EditTemplateDrawer;
