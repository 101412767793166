import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import styles from "./styles";
import { MovedTodayProps } from "./types";

const useStyles = makeStyles(styles);

const MovedToday: React.FC<MovedTodayProps> = ({ text }) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h6" className={classes.title}>
        Today
      </Typography>
      {text &&
        text.map((item, index) => (
          <Typography
            key={`moved-today-report-${index}`}
            className={classes.text}
          >
            {item}
          </Typography>
        ))}
    </Box>
  );
};

export default MovedToday;
