import axios from "axios";
import {TrainingDocument, TrainingInputData, User} from "./types";
import randomize from "randomatic";
import {subscribe} from "../pubsub";
import html2Pdf, {PdfOptions} from "../utils/html-to-pdf";
import labelTemplate from "./label-templates/training-labels-sheet";
import singleLabelTemplate, {
  SingleLabelInfo,
} from "./label-templates/training-label-single";
import {generateIds} from "../utils/parse-momenttrack-id";

const FIREBASE_BASE_URL = process.env.REACT_APP_FIREBASE_BASE_URL;
const authData = {
  org_slug: "",
};
let currUser: Omit<User, "org_slug"> | null = null;

subscribe("update:authentication", ({data}) => {
  Object.assign(authData, data || {org_slug: ""});
});
subscribe<Omit<User, "org_slug"> | null>("update:curr_person", ({data}) => {
  currUser = data;
});

const labelPdfOptions: PdfOptions = {
  pdf_mode: "portrait",
  pdf_name: `training-labels-${randomize("a0", 12)}`,
  page_size: "Letter",
  margin_top: "0mm",
  margin_bottom: "0mm",
  margin_left: "0mm",
  margin_right: "0mm",
  disable_smart_shrinking: null,
};
const singlelabelPdfOptions: PdfOptions = {
  pdf_mode: "portrait",
  pdf_name: `single-training-label-${randomize("a0", 12)}`,
  margin_top: "0mm",
  margin_bottom: "0mm",
  margin_left: "0mm",
  margin_right: "0mm",
  page_height: "155mm",
  page_width: "90mm",
  disable_smart_shrinking: null,
};

export const generateSingleLabel = (doc: SingleLabelInfo) => {
  return html2Pdf(singleLabelTemplate(doc), {
    ...singlelabelPdfOptions,
    pdf_name: `${doc.title}-training-label`,
  });
};

export const generateTrainingQrs = (qty = 30) => {
  const emptyDocs = generateIds("document", qty).map(docid => ({
    docid,
    training_versions: [
      {
        title: "",
      },
    ],
    curr_version_index: 0,
  }));

  return html2Pdf(labelTemplate(emptyDocs), {
    ...labelPdfOptions,
    pdf_name: `${qty}-training-labels-empty`,
  });
};

export const lookupTraining = async (
  docid: string
): Promise<TrainingDocument> => {
  const res = await axios.get<TrainingDocument | null>(
    `${FIREBASE_BASE_URL}/document/${docid}.json`
  );
  if (!res.data) throw new Error("Training not found.");
  return res.data;
};

export const saveTraining = async (
  trainingDoc: Partial<TrainingDocument> &
    Pick<TrainingDocument, "docid" | "visibility">,
  trainingData: TrainingInputData
) => {
  if (!currUser) throw new Error("Not authenticated.");

  const timestamp = Date.now();
  const ownershipInfo = {
    user_id: currUser.id,
    user: {...currUser, org_slug: authData.org_slug},
    organization_id: currUser.organization_id,
    org_slug: authData.org_slug,
  };

  const newTraining: TrainingDocument = Object.assign(
    {},
    {
      id: timestamp,
      pick_type: "TRAINING",
      pdf: "",
      training_versions: [],
      curr_version_index: 0,
      visibility: "PUBLIC",
      redirect_url: null,
      ...ownershipInfo,
      created_at: timestamp,
    },
    trainingDoc
  );

  newTraining.training_versions[0] = {
    ...trainingData,
    ...ownershipInfo,
    created_at: timestamp,
  };

  // Patch document to firebase.
  await axios.patch(
    `${FIREBASE_BASE_URL}/document/${newTraining.docid}.json`,
    newTraining
  );
  // Send label pdf to printer.

  return newTraining;
};
