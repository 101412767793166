
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  dropdown: {
    minWidth: 300
  },
  tabs: {
    minHeight: 'auto',
    backgroundColor: theme.palette.background.paper,
    // borderBottom: `1px solid ${theme.palette.grey[200]}`
  },
  tab: {
    minHeight: 'auto',
    ...theme.typography.subtitle2,
    textTransform: 'initial'
  },
  selectedTab: {
    backgroundColor: theme.palette.grey['800'],
    color: theme.palette.common.white
  },
  tableHead: {
    backgroundColor: theme.palette.grey['100'],
    color: theme.palette.text.primary
  },
  tableCell: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: 'inherit',

    '& > svg': {
      verticalAlign: 'bottom'
    }
  }
});

export default styles;
