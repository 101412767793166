
import React from 'react';
import { ProductInfoDialogProps } from './types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const ProductInfoDialog: React.FC<ProductInfoDialogProps> = ({ product, title='Product', children, ...dialogProps }) => {
  return (
    <Dialog {...dialogProps}>
      <DialogTitle><b>{title}</b></DialogTitle>
      <Divider light />
      <DialogContent>
        <Box py={2}>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">NAME</Typography>
              <Typography variant="body1" noWrap>{product.name || '--'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">PART #</Typography>
              <Typography variant="body1">{product.part_number || '--'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
              <Typography variant="body1" noWrap>{product.description || '--'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">ERP PART #</Typography>
              <Typography variant="body1">{product.erp_part_number || '--'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">VENDOR</Typography>
              <Typography variant="body1">{product.preferred_vendor?.name || '--'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="textSecondary">VENDOR PART #</Typography>
              <Typography variant="body1">{product.preferred_vendor_part_number || '--'}</Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={ev=>{
            ev.stopPropagation();
            dialogProps.onClose && dialogProps.onClose({}, 'escapeKeyDown');
          }}
          variant="contained"
          color="primary"
        >Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductInfoDialog;
