
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  formContainer: {
    marginBottom: theme.spacing(2)
  },
  formHeader: {
    textAlign: 'center',
    marginBottom: theme.spacing(6)
  },
  submitBtn: {
    marginTop: theme.spacing(4)
  }
});

export default styles;
