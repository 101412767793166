import React from "react";
import {PersonalReportProps} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Layout, {Header, Content, Footer} from "../../core/components/Layout";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import LinearProgress from "@material-ui/core/LinearProgress";
import Divider from "@material-ui/core/Divider";
import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {
  PersonalLog,
  // getEverythingCsv,
  getPersonalReport,
} from "../../core/system/reports";
import DateTag from "../../core/components/DateTag/DateTag";
// import moment from "moment";
import DateRangePicker from "../../core/components/DateRangePicker/DateRangePicker";

const useStyles = makeStyles(styles);

const PersonalReports: React.FC<PersonalReportProps> = props => {
  const classes = useStyles();
  const [reportsData, setreportsData] = useState<PersonalLog[]>([]);
  const [isLoading, setisLoading] = useState(false);
  const [paginationData, setpaginationData] = useState<{
    page: number;
    total_pages: number;
    total_items: number;
    per_page: number;
  }>({
    per_page: 20,
    total_items: 0,
    total_pages: 0,
    page: 1,
  });
  const [paginationParams, setpaginationParams] = useState<{
    page: number;
    per_page: number;
  }>({
    per_page: 20,
    page: 0,
  });

  useEffect(() => {
    // only fetch if org_id is present
    if (!!props.params.org_id) {
      setisLoading(true);
      getPersonalReport({
        ...props.params,
        page: paginationParams.page + 1,
        per_page: paginationParams.per_page,
      })
        .then(rep => {
          setreportsData(rep.data);
          setpaginationData(rep.pagination);
        })
        .catch(err => {
          alert((err as Error).message);
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  }, [paginationParams.page, paginationParams.per_page, props.params]);

  return (
    <Layout>
      <Header bgcolor="grey.200">
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton onClick={props.onClose} color="inherit" edge="start">
              <Icon>close</Icon>
            </IconButton>
            <Typography className={classes.title} variant="h6">
              Personal reports
            </Typography>
            {/* <Button
              variant="contained"
              style={{marginRight: "1rem"}}
              onClick={() =>
                getEverythingCsv(
                  reportsData.map((row, i) => ({
                    sn: i + 1,
                    "part number": row.part_number,
                    name: `${row.first_name} ${row.last_name}`,
                    quantity: row.quantity,
                    "activity type":
                      row.activity_type.split("_").join(" ") || "--",
                    date: moment
                      .unix(parseInt(row.created_at))
                      .format("DD MMM, YYYY [at] hh:mma"),
                  }))
                )
              }
              startIcon={<Icon>download</Icon>}
              disabled={isLoading || reportsData?.length === 0}
            >
              Download CSV
            </Button> */}
            <DateRangePicker />
          </Toolbar>
        </AppBar>
      </Header>
      <Divider light />
      <Content>
        {!isLoading && (
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {/* <TableCell className={classes.tableHeaderCell}>S/N</TableCell> */}
                  <TableCell className={classes.tableHeaderCell}>
                    PART #
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    NAME
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    QTY.
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    ACTIVITY TYPE
                  </TableCell>
                  <TableCell className={classes.tableHeaderCell}>
                    DATE
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && reportsData?.length === 0 && (
                  <Box p={2}>
                    <Typography>No rows.</Typography>
                  </Box>
                )}
                {reportsData.map((row, i) => (
                  <TableRow key={`${row.activity_id}-${row.created_at}`}>
                    {/* <TableCell>
                      {paginationParams.page * paginationParams.per_page +
                        (i + 1)}
                    </TableCell> */}
                    <TableCell>{row.part_number}</TableCell>
                    <TableCell>
                      {row.first_name} {row.last_name}
                    </TableCell>
                    <TableCell>{row.quantity}</TableCell>
                    <TableCell>
                      {row.activity_type.split("_").join(" ") || "--"}
                    </TableCell>
                    <TableCell>
                      <DateTag
                        date={row.created_at || undefined}
                        dateInputType="unix"
                      >
                        --
                      </DateTag>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {isLoading && (
          <Container maxWidth="xs">
            <Box py={5}>
              <Typography gutterBottom align="center">
                Loading data ...
              </Typography>
              <LinearProgress variant="indeterminate" />
            </Box>
          </Container>
        )}
      </Content>
      <Divider light />
      <Footer textAlign="right" bgcolor="grey.200">
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          count={paginationData?.total_items || 0}
          rowsPerPage={paginationParams?.per_page || 10}
          page={paginationParams.page}
          onRowsPerPageChange={ev => {
            setpaginationParams(prev => ({
              ...prev,
              per_page: parseInt(ev.target.value),
            }));
          }}
          onPageChange={(ev, page) => {
            setpaginationParams(prev => ({
              ...prev,
              page,
            }));
          }}
        />
      </Footer>
    </Layout>
  );
};

export default PersonalReports;
