import React, {useState, useImperativeHandle, forwardRef} from "react";
import {PersonLabelsDialogComponent} from "./types";
import {generatePersonLabels} from "../../core/system/persons";
import useNotify from "../../core/hooks/use-notify";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import LinearProgress from "@material-ui/core/LinearProgress";

const PersonLabelsDialog: PersonLabelsDialogComponent = (props, ref) => {
  const {title = "Generate PDF", persons} = props;

  const [templateType, setTemplateType] = useState<"sheet" | "single">("sheet");
  const [pdfUrl, setPdfUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [generatingPdf, setGeneratingPdf] = useState(false);
  const notify = useNotify();

  async function handleGeneratePdf() {
    try {
      setGeneratingPdf(true);
      const pdfUrl = await generatePersonLabels(persons, templateType);
      setPdfUrl(pdfUrl);
      setGeneratingPdf(false);
    } catch (err: any) {
      notify(err);
      setGeneratingPdf(false);
    }
  }

  useImperativeHandle(ref, () => ({openDialog: () => setOpen(true)}), []);

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <FormControl>
          <FormLabel>Template</FormLabel>
          <RadioGroup
            value={templateType}
            onChange={(ev, val: any) => setTemplateType(val)}
          >
            <FormControlLabel
              value="sheet"
              control={<Radio />}
              label="30 x labels sheet"
              disabled={generatingPdf}
            />
            <FormControlLabel
              value="single"
              control={<Radio />}
              label={'2 5/8" x 1" stickers'}
              disabled={generatingPdf}
            />
            <FormControlLabel
              value="large"
              control={<Radio />}
              label={'3" x 3" stickers'}
              disabled={generatingPdf}
            />
          </RadioGroup>
        </FormControl>
      </DialogContent>
      {generatingPdf && <LinearProgress />}
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setPdfUrl("");
            setTemplateType("sheet");
          }}
          disabled={generatingPdf}
        >
          {pdfUrl ? "Close" : "Cancel"}
        </Button>

        <Button
          onClick={handleGeneratePdf}
          color="primary"
          variant="contained"
          disabled={generatingPdf}
        >
          Generate PDF
        </Button>

        {!!pdfUrl && (
          <Button
            onClick={() => window.open(`${pdfUrl}?t=${Date.now()}`, "_BLANK")}
            color="secondary"
            variant="contained"
            disabled={generatingPdf}
          >
            View Pdf
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(PersonLabelsDialog);
