import apiClient, {ApiResponse, PaginatedData} from "../ordertrack-client";
import ShoppingList, {ShoppingListData} from "./types";

export async function fetchShoppingLists(
  page = 1,
  status = "added",
  params?: any
) {
  const response = await apiClient.get<
    ApiResponse<PaginatedData<ShoppingList>>
  >("/shopping_list", {
    params: {page, status, ...(params || {})},
  });

  return {
    ...response.data.data,
  };
}

export async function createShoppingList(data: ShoppingListData) {
  const response = await apiClient.post("/shopping_list", data);

  return {
    ...response.data.data,
  };
}

export async function deleteShoppingList(id: number) {
  await apiClient.delete(`/shopping_list/${id}`);
  return true;
}
export async function deleteShoppingLists(shoppingLists: ShoppingList[]) {
  const ids = shoppingLists.map(item => item.id);
  await apiClient.delete("/shopping_list", {
    params: {
      ids: ids.join(","),
    },
  });
  return true;
}
