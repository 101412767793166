
import React from 'react';
import { PersonBadgeProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import Icon from '@material-ui/core/Icon';
import styles from './styles';

const useStyles = makeStyles(styles);

const PersonBadge: React.FC<PersonBadgeProps> = props => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Avatar className={classes.avatar}>
        <Icon>person</Icon>
      </Avatar>

      <div style={{ overflow: 'hidden' }}>
        <Typography variant="body2" noWrap>{props.name}</Typography>
        <Typography variant="caption" display="block" color="textSecondary" noWrap>{props.email}</Typography>
      </div>
    </div>
  );
};

export default PersonBadge;
