
import { createContext } from 'react';
import { PersonsContext } from './types';

const context = createContext<PersonsContext>([[], false, {
  sendInvite: ()=>Promise.resolve(false),
  fetchAll: ()=>null,
  getById: ()=>Promise.resolve(null),
  remove: ()=>Promise.resolve(false),
  update: ()=>Promise.resolve(null)
}]);

export default context;
