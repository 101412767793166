
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    '&:hover,&:focus': {
      outline: `2px dashed ${theme.palette.primary['main']}`
    }
  },
  infoContainer: {
    overflow: 'hidden',
    flex: 1,
    "&>*": {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    }
  }
});

export default styles;
