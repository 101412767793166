import {Person} from "../types";

const template = (persons: Person[]) => `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Single label</title>

    <style>
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
      }
      .label-root {
        position: relative;
        width: 3in;
        margin: auto;
        height: 5.5in;
        line-height: normal;
        background: #ffffff;
        padding: 0.2in;
      }
      .label-root .logo {
        margin: 1rem auto 0.5rem auto;
        width: 0.7in;
        display: block;
        height: 0.7in;
        border: 1px solid;
        border-radius: 50%;
      }
      .label-root .qr-code {
        width: 3in;
        height: 3in;
      }
      .title {
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        margin-bottom: 0.05in;
        margin-top: 0.1in;
      }
      .subtitle {
        font-size: 18px;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-align: center;
        font-style: italic;
        margin-bottom: 0.2in;
        margin-top: 0;
        text-overflow: ellipsis;
      }
      .label-root .label-footer {
        letter-spacing: 1px;
        font-size: 14px;
        padding-left: 0.035in;
        word-wrap: break-word;
        margin-top: 0.05in;
      }
    </style>
  </head>
  <body>
  ${persons
    .map(
      person => `
      <div class="label-root">
      <img
        alt="learning in the moment logo"
        src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/person.svg"
        class="logo"
      />
      <p class="title">${
        (person?.first_name || "--") + " " + (person?.last_name || "--")
      }</p>
      <p class="subtitle">${person.email || "--"}</p>
      <img
        src="https://api.3hd.us/app/qrcode_gen.create?data=${
          process.env.REACT_APP_QR_BASENAME || "3hd.us"
        }/${person.person_id}&logo=momenttrack"
        class="qr-code"
      />
      <p class="label-footer">${
        process.env.REACT_APP_QR_BASENAME || "3hd.us"
      }/${person.person_id}</p>
    </div>
  `
    )
    .join("")}
  </body>
</html>
`;

export default template;
