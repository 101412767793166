import React, {useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useAuthentication} from "../core/providers/AuthenticationProvider";
import AuthenticationForm, {
  AuthenticationFormRef,
} from "../core/forms/AuthenticationForm";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import {Link as RouterLink} from "react-router-dom";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles";
import productionTrackLogo from "../assets/images/logo-productiontrack.png";

const useStyles = makeStyles(styles);

const Login: React.FC = () => {
  const authForm = useRef<AuthenticationFormRef>(null);
  const classes = useStyles();
  const [, busy, {login}] = useAuthentication();

  const handleLogin = ({email, password}: any) => login(email, password);
  return (
    <div className={classes.root}>
      <Container disableGutters maxWidth="xs">
        <Paper square>
          <Box p={3}>
            <div className={classes.formHeader}>
              <img
                alt="Production track trademark"
                src={productionTrackLogo}
                height={60}
              />
              {/* <Typography variant="body2" color="textSecondary" align="left">Login to access the system</Typography> */}
            </div>
            {busy && (
              <Box textAlign="center">
                <Typography align="center" gutterBottom>
                  Authenticating
                </Typography>
                <CircularProgress size={24} />
              </Box>
            )}
            {!busy && (
              <>
                <div className={classes.formContainer}>
                  <AuthenticationForm
                    ref={authForm}
                    onSubmit={handleLogin}
                    disabled={busy}
                  />
                </div>

                <Box textAlign="right">
                  <Link
                    component={RouterLink}
                    to="/forgot-password"
                    align="right"
                  >
                    Forgot password?
                  </Link>
                </Box>

                <Button
                  className={classes.submitBtn}
                  onClick={() => authForm.current?.submitForm()}
                  disabled={busy}
                  size="large"
                  variant="contained"
                  color="secondary"
                  fullWidth
                >
                  Login
                </Button>
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </div>
  );
};

export default Login;
