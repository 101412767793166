
import React, { useState, useEffect, useCallback, useMemo, FormEvent } from 'react';
import qs from 'qs';
import { RouteComponentProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import useNotify from '../core/hooks/use-notify';
import { verifyResetToken, updatePassword } from '../core/system/authentication';
import { publish } from '../core/system/pubsub';

const ResetPassword: React.FC<RouteComponentProps> = function(props) {
  const {
    location,
    history
  } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tokenIsValid, setTokenIsValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  const { token, org } = useMemo(()=>{
    return qs.parse(location.search, {ignoreQueryPrefix: true}) as { token: string, org: string }
  }, [location.search]);

  const handleSubmit = useCallback(async (ev: FormEvent)=>{
    try{
      ev.preventDefault();
      if(!password.trim() || password !== confirmPassword)
        throw new Error('Passwords doesn\'t match.');

      setLoading(true);
      await updatePassword(password);
      
      notify('Password successfully changed.');
      history.replace('/login');
    }
    catch(err: any){
      notify((err as Error));
      setLoading(false);
    }

  }, [password, confirmPassword, history, notify]);

  useEffect(()=>{
    if(!token || !org) return;

    (async ()=>{
      try{
        publish('update:authentication', { org_slug: org }, { isTemporary: true });

        setLoading(true);
        const { access_token } = await verifyResetToken(token);
        publish('update:authentication', { access_token }, { isTemporary: true });
        setTokenIsValid(true);
      }
      catch(err: any){
        notify((err as Error));
      }
      finally{
        setLoading(false);
      }

    })();
  }, [org, token, notify]);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Container maxWidth="xs">
        <Paper>
          {loading && <LinearProgress />}
          {(!token || !org) && (
            <Box p={3}>
              <Typography variant="h6" gutterBottom>Broken link</Typography>
              <Typography color="textSecondary">This link seems to be broken/incomplete.</Typography>
            </Box>
          )}
          {!!token && !!org && !tokenIsValid && !loading && (
            <Box p={3}>
              <Typography variant="h6" gutterBottom>Link expired</Typography>
              <Typography color="textSecondary">This password reset link seems to be expired. <Link component={RouterLink} to="/forgot-password">Get new link.</Link></Typography>
            </Box>
          )}
          {tokenIsValid && (
            <>
              <Box p={3}>
                <Typography variant="h6">Reset password</Typography>
                <Typography variant="body2" color="textSecondary">Choose a new password for your account.</Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box p={3}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        label="New password"
                        type="password"
                        value={password}
                        disabled={loading}
                        onChange={ev=>setPassword(ev.target.value)}
                        fullWidth
                        autoFocus
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Confirm password"
                        type="password"
                        value={confirmPassword}
                        disabled={loading}
                        onChange={ev=>setConfirmPassword(ev.target.value)}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box p={3}>
                  <Grid container spacing={2} alignItems="center" justify="flex-end">
                    <Grid item>
                      <Link
                        to="/login"
                        component={RouterLink}
                        color="textPrimary"
                        variant="body1"
                        underline="always"
                      >
                        back to login
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button type="submit" disabled={loading} variant="contained" color="primary">Continue</Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default ResetPassword;
