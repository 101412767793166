
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewProductionOrders from './ViewProductionOrders';
import NewProductionOrder from './NewProductionOrder';

const ProductionOrders: React.FC = () => {
  return (
    <Switch>
      <Route path="/orders/new" component={NewProductionOrder} />
      <Route path="/orders" exact component={ViewProductionOrders} />

      <Redirect to="/orders" />
    </Switch>
  );
};

export default ProductionOrders;
