
import { createContext } from 'react';
import { PlacesContext } from './types';

const context = createContext<PlacesContext>([[], false, {
  getById: async ()=>null,
  create: async ()=>null,
  update: async ()=>null
}]);

export default context;
