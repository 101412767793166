import React, {useState, useRef} from "react";
import {BinCardProps, BinDialogRef} from "./types";
import BinDialog from "./BinDialog";
import {Link as RouterLink} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import InfoIcon from "@material-ui/icons/InfoOutlined";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import useConfirmations from "../core/hooks/use-confirmations";
import {deleteBin, updateBin} from "../core/system/bin-families";
import useNotify from "../core/hooks/use-notify";
import {BinData} from "../core/forms/BinForm";

const BinCard: React.FC<BinCardProps> = function (props) {
  const {bin} = props;
  const confirm = useConfirmations();
  const notify = useNotify();
  const binDialog = useRef<BinDialogRef>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  function handleBinEdit() {
    setMenuAnchorEl(null);
    binDialog.current?.openDialog(true);
  }

  async function handleBinSave(data: BinData) {
    await updateBin(bin.id, data);
    notify("Bin updated.");
  }

  async function handleBinDelete() {
    try {
      setMenuAnchorEl(null);

      if (
        await confirm(
          `Are you sure?, You are about to delete bin "${bin.name}". This action cannot be undone.`
        )
      ) {
        setLoading(true);
        await deleteBin(bin.id);
        notify("Bin deleted.");
      } else return;
    } catch (err) {
      notify(err as Error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item xs zeroMinWidth>
            <Link
              component={RouterLink}
              to={`/bins/${bin.id}`}
              variant="body1"
              display="block"
              underline="always"
              color="textPrimary"
              noWrap
            >
              {bin.name}
            </Link>
          </Grid>
          <Grid item>
            <IconButton
              disabled={loading}
              size="small"
              onClick={() => binDialog.current?.openDialog()}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          {!loading && (
            <Grid item>
              <IconButton
                disabled={loading}
                size="small"
                edge="end"
                onClick={ev => setMenuAnchorEl(ev.currentTarget)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          )}
          {loading && (
            <Grid item style={{lineHeight: 0}}>
              <CircularProgress size={30} />
            </Grid>
          )}
        </Grid>
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={() => setMenuAnchorEl(null)}
      >
        <MenuItem onClick={handleBinEdit}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleBinDelete}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <BinDialog
        ref={binDialog}
        bin={bin}
        primaryActionText="Update"
        secondaryActionText="Close"
        primaryAction={handleBinSave}
      />
    </Paper>
  );
};

export default BinCard;
