import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useRef,
  useEffect,
} from "react";
import {
  PlaceFormProps,
  PlaceFormRef,
  PlaceFormData,
  PlaceIconsDialogApi,
} from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import validationSchema from "./validation-schema";
import {useFormik} from "formik";
import {Typography, makeStyles} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import PlaceIcons from "./Placeicons";
import {styles} from "./types";

const useStyles = makeStyles(styles);

const PlaceForm: React.ForwardRefRenderFunction<PlaceFormRef, PlaceFormProps> =
  function (props, ref) {
    const {
      initialValues = {} as PlaceFormData,
      disabled = false,
      onSubmit = () => {},
    } = props;

    const hasAdditionalData =
      !!initialValues.width ||
      !!initialValues.height ||
      !!initialValues.depth ||
      !!initialValues.logo_url;

    const [showAdditional, setShowAdditional] = useState(hasAdditionalData);
    const [unitMenuAnchor, setUnitMenuAnchor] =
      useState<HTMLButtonElement | null>(null);

    const formik = useFormik<PlaceFormData>({
      initialValues: {
        name: initialValues.name || "",
        width: initialValues.width || 0,
        height: initialValues.height || 0,
        depth: initialValues.depth || 0,
        unit: initialValues.unit || "METER",
      },
      validationSchema,
      onSubmit: values => onSubmit({...values, logo_url: selectedIcon}),
    });

    useImperativeHandle(ref, () => formik, [formik]);

    const handleUnitMenuTrigger: React.MouseEventHandler<HTMLButtonElement> =
      useCallback(ev => {
        setUnitMenuAnchor(ev.currentTarget);
      }, []);
    const handleUnitMenuSelect = useCallback(
      (val: string) => {
        formik.setFieldValue("unit", val);
        setUnitMenuAnchor(null);
      },
      [formik]
    );
    const placeIconDialog = useRef<PlaceIconsDialogApi>(null);
    const [selectedIcon, setSelectedIcon] = useState("");

    useEffect(() => {
      setSelectedIcon(initialValues?.logo_url || "");
    }, [initialValues.logo_url]);

    const classes = useStyles();

    return (
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="NAME"
          type="text"
          {...formik.getFieldProps("name")}
          error={formik.touched["name"] && !!formik.errors["name"]}
          helperText={formik.errors["name"]}
          disabled={disabled}
          size="small"
          variant="standard"
          fullWidth
          margin="normal"
        />

        <FormControlLabel
          label="Additional data"
          control={
            <Checkbox
              color="primary"
              checked={showAdditional}
              onChange={(ev, val) => setShowAdditional(val)}
            />
          }
        />

        {showAdditional && (
          <Box py={2}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Dimensions</FormLabel>
              <div>
                <br />
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Width"
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...formik.getFieldProps("width")}
                    disabled={disabled}
                    inputProps={{
                      min: 0,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            size="small"
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              marginRight: -12,
                            }}
                            disabled={disabled}
                            onClick={handleUnitMenuTrigger}
                          >
                            {formik.values["unit"]}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Height"
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...formik.getFieldProps("height")}
                    disabled={disabled}
                    inputProps={{
                      min: 0,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            size="small"
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              marginRight: -12,
                            }}
                            disabled={disabled}
                            onClick={handleUnitMenuTrigger}
                          >
                            {formik.values["unit"]}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Depth"
                    size="small"
                    variant="outlined"
                    fullWidth
                    type="number"
                    {...formik.getFieldProps("depth")}
                    disabled={disabled}
                    inputProps={{
                      min: 0,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button
                            size="small"
                            color="primary"
                            style={{
                              textTransform: "capitalize",
                              marginRight: -12,
                            }}
                            disabled={disabled}
                            onClick={handleUnitMenuTrigger}
                          >
                            {formik.values["unit"]}
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <Button
                className={classes.add_icon_btn}
                onClick={() => placeIconDialog.current?.openDialog()}
              >
                {selectedIcon ? (
                  <img
                    style={{width: 40, height: 40}}
                    src={selectedIcon}
                    alt={selectedIcon}
                  />
                ) : (
                  <AddIcon style={{width: 40, height: 40}} />
                )}
                <Typography variant="body2">
                  {selectedIcon ? "Change Icon" : "Add Icon"}
                </Typography>
              </Button>
            </FormControl>

            <Menu
              open={!!unitMenuAnchor}
              onClose={() => setUnitMenuAnchor(null)}
              anchorEl={unitMenuAnchor}
            >
              <MenuItem onClick={() => handleUnitMenuSelect("METER")}>
                METER
              </MenuItem>
              <MenuItem onClick={() => handleUnitMenuSelect("FEET")}>
                FEET
              </MenuItem>
              <MenuItem onClick={() => handleUnitMenuSelect("INCHES")}>
                INCHES
              </MenuItem>
            </Menu>
          </Box>
        )}
        <PlaceIcons
          ref={placeIconDialog}
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
        />
      </form>
    );
  };

export default forwardRef(PlaceForm);
