
import React, { useCallback, useState, useImperativeHandle, forwardRef, useRef, useEffect } from "react";
import QrReader from "react-qr-reader";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import QrCodeIcon from "../../../assets/icons/vectors/qr-code";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ImageIcon from '@material-ui/icons/Image';

const QrCodeScanner = function (props, ref) {
  const {
    className,
    title = 'Scan QR code',
    facingMode = "environment",
    onResult = () => null,
    label = "Scan QR",
    disabled = false,
    hidden,
    ButtonProps = {}

  } = props;

  const qrReaderRef = useRef();
  const userAgent = window.navigator.userAgent;
  const [open, setOpen] = useState(false);
  const [nativeScannerOpen, setNativeScannerOpen] = useState(false);
  const [legacyMode, setLegacyMode] = React.useState(false);
  const [message, setMessage] = React.useState("Loading...");

  const handleDialogClose = useCallback(() => {
    setMessage("");
    setOpen(false);
    setNativeScannerOpen(false);

  }, []);

  const handleScanResult = useCallback(result => {
    if (!result)
      return setMessage("No QR detected.");

    if (open)
      handleDialogClose();
    else if (nativeScannerOpen)
      setNativeScannerOpen(false);

    onResult(result);

  }, [open, nativeScannerOpen, handleDialogClose, onResult]);

  const handleScanError = useCallback(err => {
    console.dir(err);
    setMessage("Allow camera access for live scan.");

  }, []);

  const handleQrImageSelection = useCallback(() => {
    qrReaderRef.current.openImageDialog();

  }, []);

  const handleLegacyModeToggle = useCallback(() => {
    setMessage("");
    setLegacyMode(legacyMode => !legacyMode);

  }, []);

  const openScanner = useCallback(() => {
    if (userAgent === 'replenish') {
      window.ReactNativeWebView.postMessage("Hello!");
      setNativeScannerOpen(true);
    }
    else
      setOpen(true);

  }, [userAgent]);

  useImperativeHandle(ref, () => ({ openScanner }), [openScanner]);

  useEffect(() => {
    // Execute this effect only on mobile application.
    if (userAgent !== 'replenish' || !nativeScannerOpen)
      return;

    function handleFile(e) {
      handleScanResult(e.detail);
    }

    function handleCancel() {
      setNativeScannerOpen(false);
    }

    window.document.addEventListener("filefromnative", handleFile);
    window.document.addEventListener("cancel", handleCancel);

    return () => {
      window.document.removeEventListener("filefromnative", handleFile);
      window.document.removeEventListener("cancel", handleCancel);
    };

  }, [handleScanResult, userAgent, nativeScannerOpen]);

  return (
    <>
      {!hidden && (
        <Button
          className={className}
          size="large"
          startIcon={<QrCodeIcon style={{ width: 22, height: 22 }} />}
          onClick={openScanner}
          disabled={disabled}
          {...ButtonProps}
        >
          {label}
        </Button>
      )}

      <Dialog
        open={open}
        onClose={handleDialogClose}
        maxWidth="xs"
        fullWidth
        className="qrcode-scanner"
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent className="input-window">
          <DialogContentText>{message || "--"}</DialogContentText>
          <QrReader
            ref={qrReaderRef}
            facingMode={facingMode}
            onScan={handleScanResult}
            onError={handleScanError}
            legacyMode={legacyMode}
          />

          {legacyMode && (
            <Button
              startIcon={<ImageIcon />}
              onClick={handleQrImageSelection}
              size="large"
              style={{ marginTop: 8 }}
              className="image-selection-btn"
            >
              Choose image with QR
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          {!legacyMode && (
            <Button onClick={handleLegacyModeToggle} size="large">
              Scan from image
            </Button>
          )}
          {legacyMode && (
            <Button onClick={handleLegacyModeToggle} size="large">
              Live scan
            </Button>
          )}
          <Button color="primary" onClick={handleDialogClose} size="large">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default forwardRef(QrCodeScanner);
