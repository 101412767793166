
import { Theme, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  chip: {
    marginRight: theme.spacing(1)/2,
    marginBottom: theme.spacing(1)/2
  }
});

export default styles;
