import React from "react";
import {StationReportCardProps} from "./types";
import Link from "@material-ui/core/Link";
import Tooltip from "@material-ui/core/Tooltip";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import PlaceStats from "./charts/StationStats";
import DistributionPie from "./charts/DistributionPie";
import FirstTimeRightPie from "./charts/FirstTimeRightPie";
import MovedToday from "./charts/MovedToday";
import PlaceReportProvider from "../../../core/providers/PlaceReportProvider";

import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import QuantityReport from "./QuantityReport";

const useStyles = makeStyles(styles);

const QR_BASENAME = process.env.REACT_APP_QR_BASENAME || "3hd.us";
const RELOAD_INTERVAL = 15 * 60 * 1000; // 15 minutes

const StationReportCard: React.FC<StationReportCardProps> = props => {
  const classes = useStyles();

  const {onClickAnalytics, beacon_id, text} = props;

  return (
    <PlaceReportProvider id={props.id} reloadInterval={RELOAD_INTERVAL}>
      {props.default_report === "qty" ? (
        <QuantityReport />
      ) : (
        <Card className={classes.root}>
          <CardHeader
            className={classes.header}
            action={
              !onClickAnalytics ? undefined : (
                <Tooltip placement="top" arrow title="More reports">
                  <IconButton
                    className={classes.invisible}
                    color="primary"
                    onClick={() => onClickAnalytics(props.id)}
                  >
                    {/* <Icon fontSize="large">content_paste_search</Icon> */}
                    <Icon fontSize="large">assessment</Icon>
                  </IconButton>
                </Tooltip>
              )
            }
            title={props.name || "--"}
            subheader={
              <Link
                variant="caption"
                color="textSecondary"
                href={`https://${QR_BASENAME}/${beacon_id}`}
                target="_BLANK"
              >
                {QR_BASENAME}/...{(beacon_id || "").slice(-5)}
              </Link>
            }
          />
          <CardContent>
            {(!props.default_report || props.default_report === "stats") && (
              <PlaceStats />
            )}
            {props.default_report === "parts-distribution" && (
              <DistributionPie />
            )}
            {props.default_report === "first-time-right" && (
              <FirstTimeRightPie />
            )}
            {props.default_report === "moved-today" && (
              <MovedToday text={text ? text : undefined} />
            )}
          </CardContent>
        </Card>
      )}
    </PlaceReportProvider>
  );
};

export default StationReportCard;
