import React from "react";
import {ThemeProvider} from "@material-ui/core/styles";
import {createTheme} from "@material-ui/core/styles"; // Adjusted for forward compatibility

import {ThemeProps} from "./types";

const Theme: React.FC<ThemeProps> = props => {
  const {children, ...themeOptions} = props;

  // Directly using createTheme for forward compatibility
  const theme = createTheme({
    ...themeOptions,
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
