
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  dialogContentRoot: {
    padding: 0
  },
  activeTabIndicator: {
    height: 0
  },
  activeTab: {
    backgroundColor: theme.palette.primary['dark'],
    color: theme.palette.primary['contrastText']
  }
});

export default styles;
