import {createTheme} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";
import yellow from "@material-ui/core/colors/yellow";

const mainThemeLight = createTheme({
  palette: {
    background: {
      default: grey["100"],
    },
    secondary: {
      main: "#ec1c24",
    },
    success: {
      main: "#4caf50",
    },
    error: {
      main: "#f44336",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 12,
        backgroundColor: yellow["400"],
        color: "#000000",
        borderRadius: 0,
        padding: "2px 8px",
        boxShadow: `rgba(0,0,0,1) 0 14px 12px -9px`,
      },
      arrow: {
        color: yellow["400"],
      },
    },
  },
  props: {
    MuiAppBar: {
      elevation: 0,
      color: "inherit",
    },
    MuiPaper: {
      elevation: 0,
      square: true,
    },
    MuiCard: {
      elevation: 0,
    },
  },
});

export default mainThemeLight;
