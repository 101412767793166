// vendorsContext.ts
import {createContext} from "react";
import {VendorsContext} from "./types"; // Define an appropriate type for VendorsContext

const context = createContext<VendorsContext>([
  [],
  false,
  "",
  {
    // getById: async () => null,
    create: async () => null,
    update: async () => null,
    deleteById: async () => false,
  },
]);

export default context;
