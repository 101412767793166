
import React from 'react';
import { AccordionGroupProps } from './types';
import context from './context';
import { useState, useMemo } from 'react';

const AccordionGroup: React.FC<AccordionGroupProps> = props => {
  const [expanded, setExpanded] = useState<string|number|null>(props.expanded || null);
  const groupId = useMemo(() => Date.now(), []);

  return (
    <context.Provider
      value={{
        expanded,
        setExpanded,
        groupId
      }}
    >{props.children}</context.Provider>
  );
};

export default AccordionGroup;
