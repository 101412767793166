import { Place } from "../types";

const template = (places: Place[]) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Move many sheet</title>

  <style>
    body, html {
      margin: 0;
      padding: 0;
      font-size: 11pt;
      font-family: sans-serif;
    }

    .container {
      position: relative;
      width: 4in;
      height: 6in;
      overflow: hidden;
    }

    header {
      background-color: #f2f2f2;
      padding: 0.25in 0.25in 0.1in;
      margin-bottom: 0.1in;
      text-align: center;
    }

    .title-main {
      font-size: 1.35em;
      font-weight: normal;
      line-height: 1.5;
      margin: 0;
    }

    .image-main {
      box-sizing: border-box;
      width: 1in;
      height: 1in;
      line-height: 0;
      letter-spacing: 0;
      margin: 0 auto 0.15em;
    }

    .image-main img {
      width: 100%;
    }

    .insert-zone {
      display: inline-block;
      box-sizing: border-box;
      font-size: 0.85em;
      font-style: italic;
      font-family: monospace;
      padding: 0 1em;
    }

    .insert-zone .image-main {
      padding: 0.25in;
      margin-bottom: 1em;
      background-color: #f2f2f2;
    }

    .insert-zone .image-main img {
      opacity: 0.2;
    }

    .qr-codes-table {
      width: 90%;
      margin: auto;
    }

    .qr-codes-table td {
      text-align: center;
      vertical-align: top;
      width: 50%;
    }

    .qr-codes-table td > p {
      margin: 1.25em 0 1em;
      font-size: 1.2em;
      font-style: italic;
      border-bottom: 1px solid #000000;
    }

    .icon-label {
      font-size: 0.8em;
      letter-spacing: 0.08em;
      font-family: monospace;
    }

    .icon-label .icon {
      max-width: 28px;
      vertical-align: bottom;
    }

    .branding {
      position: absolute;
      bottom: 0.25in;
      right: 0.25in;
      height: 0.2in;
    }

  </style>
</head>
<body>
  ${places
    .map(
      (place) =>
        `<div class="container">
      <header>
        &rtri;
        <small><i>Move Many manual submit to</i></small>
        <h1 class="title-main"><b>${place.name || "Here"}</b></h1>
      </header>

      <table class="qr-codes-table">
        <tr>
          <td>
            <p>I</p>
            <div class="insert-zone">
              <div class="image-main">
                <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/person.svg" />
              </div>
              &bull; <span>Place your <b>ID badge</b> here</span>
            </div>
          </td>
          <td>
            <p>moved</p>
            <div class="image-main">
              <img src="https://api.3hd.us/app/qrcode_gen.create?data=${
                process.env.REACT_APP_QR_BASENAME || "3hd.us"
              }/move_many&logo=" />
            </div>
            <span class="icon-label"><img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/activity_icon.svg" class="icon"/> ${
              process.env.REACT_APP_QR_BASENAME || "3hd.us"
            }/move_many</span>
          </td>
        </tr>

        <tr>
          <td>
            <p>this</p>
            <div class="insert-zone">
              <div class="image-main">
                <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/product.svg" />
              </div>
              &bull; <span>Place product <b>QR tag</b> here</span>
            </div>
          </td>
          <td>
            <p>here.</p>
            <div class="image-main">
              <img src="https://api.3hd.us/app/qrcode_gen.create?data=${
                process.env.REACT_APP_QR_BASENAME || "3hd.us"
              }/${place.beacon_id}" />
            </div>
            <span class="icon-label"><img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/location_icon.svg" class="icon"/> ${
              process.env.REACT_APP_QR_BASENAME || "3hd.us"
            }/...${place.beacon_id.slice(-5)}</span>
          </td>
        </tr>
      </table>

      <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/logos/moment_track_logo.svg" class="branding"/>

    </div>`
    )
    .join("")}
  
</body>
</html>
`;

export default template;
