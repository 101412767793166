import apiClient from "../ordertrack-client";
import {Bin, BinFamily, BinFamilyInputData, BinInputData} from "./types";

export const getAllBins = async (): Promise<Bin[]> => {
  const res = await apiClient.get("/bins");
  return res.data.data;
};
export const getBinById = async (id: number): Promise<Bin> => {
  const res = await apiClient.get(`/bins/${id}`);
  return res.data.data;
};
export const getAllBinFamilies = async (): Promise<BinFamily[]> => {
  const res = await apiClient.get("/bin_families");
  return res.data.data;
};

export const getBinFamilyById = async (id: number): Promise<BinFamily> => {
  const res = await apiClient.get(`/bin_families/${id}`);
  return res.data.data;
};

export const createBinService = async (data: BinInputData): Promise<Bin> => {
  const res = await apiClient.post("/bins", data);
  return res.data.data;
};
export const createBinFamily = async (
  data: BinFamilyInputData
): Promise<BinFamily> => {
  const res = await apiClient.post("/bin_families", data);
  return res.data.data;
};

export const moveFamily = async (binFamilyId: number, newPlaceId: number) => {
  const url = `bin_families/${binFamilyId}/relocate`;
  const response = await apiClient.post(url, {location_id: newPlaceId});

  return response.data.data;
};

export const updateBin = async (
  binId: number,
  updatedData: BinInputData
): Promise<Bin> => {
  const res = await apiClient.put(`/bins/${binId}`, updatedData);
  return res.data.data;
};
export const updateBinFamily = async (
  bin_familyId: number,
  updatedData: BinFamilyInputData
): Promise<BinFamily> => {
  const res = await apiClient.put(`/bin_families/${bin_familyId}`, updatedData);
  return res.data.data;
};

export const deleteBin = async (vendorId: number): Promise<Bin | boolean> => {
  await apiClient.delete(`/bins/${vendorId}`);
  return true;
};
export const deleteBinFamily = async (
  bin_familyId: number
): Promise<BinFamily | boolean> => {
  await apiClient.delete(`/bin_families/${bin_familyId}`);
  return true;
};
