
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)/2,
    borderRadius: 100,
    background: `linear-gradient(to left, ${theme.palette.grey['200']}, ${theme.palette.grey['50']})`
  },
  inputBase: {
    flex: 1,
    padding: `0 ${theme.spacing(1)}px 0 ${theme.spacing(2)}px`,
    ...(theme.typography.h6),
    fontStyle: 'italic',
    lineHeight: 1,
    letterSpacing: '0.08em',
    color: theme.palette.grey['600']
  }
});

export default styles;
