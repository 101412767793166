/*

fetch template - GET - https://api-dev.lifetime.momenttrack.com/24555MPU2EKCBXRGBS666oNO6/fetch/template

fetch last user - GET - https://api-dev.lifetime.momenttrack.com/24555MPU2EKCBXRGBS666oNO6/fetch/user

fetch manufactured date - GET - https://api-dev.lifetime.momenttrack.com/24555MPU2EKCBXRGBS666oNO6/fetch/createDate

save template - POST - https://api-dev.lifetime.momenttrack.com/template/save

fetch template - GET - https://api-dev.lifetime.momenttrack.com/template/pull?orgId=4&productId=2643&orderId=1229

*/

import lifetimeClient from "./client";
import {SaveTemplateParams, TemplateResponse, templateParams} from "./types";

export async function getTemplateById(
  params: templateParams
): Promise<TemplateResponse> {
  const res = await lifetimeClient.get(
    `/template/pull?orgId=${params.orgId}&productId=${params.productId}&orderId=${params.orderId}`
  );
  return res.data;
}

export async function saveTemplate(body: SaveTemplateParams): Promise<any> {
  const res = await lifetimeClient.post("/template/save", body);

  return res.data.data;
}
