
import { ProductionOrder } from '../types';

/**
 * sheets: Array of length 30 arrays.
 * sheet: Array of 30 labels.
 */

const template = (orders: ProductionOrder[], labelsPerSheet: number = 30) => {
  const totalSheets = Math.ceil(orders.length / labelsPerSheet);
  const sheets = Array(totalSheets).fill(undefined)
    .map((_, i) =>
      orders.slice(i * labelsPerSheet, (i * labelsPerSheet) + labelsPerSheet)
    )
  ;

  return (`
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>30 labels sheet template</title>

  <style>
    body, html {
      margin: 0;
      padding: 0;
      font-size: 10px;
      font-family: sans-serif;
    }

    .container {
      position: relative;
      width: 8.5in;
      height: 11in;
      letter-spacing: 0;
      line-height: 0;
    }

    .container > .safe-area-container {
      position: absolute;
      top: 0.5in;
      bottom: 0.5in;
      left: 0.21975in;
      right: 0.21975in;
      line-height: 0;
      letter-spacing: 0;
    }

    .label-root {
      float: left;
      position: relative;
      width: 2.5935in;
      height: 1in;
      line-height: normal;
      margin-right: 0.14in;
      background: #ffffff;
    }

    .label-root > .safe-area-container {
      position: absolute;
      top: 0.035in;
      bottom: 0.035in;
      left: 0.035in;
      right: 0.035in;
    }

    .label-root:nth-child(3n) {
      margin-right: 0;
    }

    .label-root p {
      margin: 0;
    }

    .label-root .qr-code-wrapper {
      float: left;
      width: 0.83in;
      line-height: 0;
      margin-right: 0.07in;
    }

    .qr-code-wrapper .qr-code {
      max-width: 100%;
    }

    .details-wrapper .label-meta {
      margin-right: 0.07in;
      margin-bottom: 0.07in;
    }

    .label-meta .icon {
      display: inline-block;
      max-width: 32px;
      max-height: 32px;
      vertical-align: middle;
      margin-right: 0.035in;
    }

    .label-meta p {
      display: inline;
    }

    .details-wrapper .label-info {
      overflow: hidden;
    }

    .label-info .title {
      font-weight: 700;
      margin-bottom: 0.035in;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-info .subtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-root .label-footer {
      clear: both;
      font-size: 8px;
      letter-spacing: 1px;
      line-height: 0.1in;
      padding-left: 0.035in;
      height: 0.1in;
    }
  </style>
</head>
<body>
  ${sheets.map(sheet=>(`
    <div class="container">
      <div class="safe-area-container">

        ${sheet.map(order=>(`
          <!-- BEGIN: Label -->
          <div class="label-root">
            <div class="safe-area-container">
              <div class="qr-code-wrapper">
                <img src="https://api.3hd.us/app/qrcode_gen.create?data=${process.env.REACT_APP_QR_BASENAME || '3hd.us'}/${order.docid}&logo=momenttrack" class="qr-code"/>
              </div>
              <div class="details-wrapper">
                <div class="label-meta">
                  <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg" class="icon">
                  <p>Production order</p>
                </div>
                <div class="label-info">
                  <p class="title">${order.product.description || '--'}</p>
                  <p class="subtitle"><b>PN:</b> ${order.product.part_number || '--'}</p>
                  <p class="subtitle"><b>QTY:</b> ${order.requested_qty}</p>
                </div>
              </div>
              <p class="label-footer">${process.env.REACT_APP_QR_BASENAME || '3hd.us'}/${order.docid}</p>
            </div>
          </div>
          <!-- END: Label -->
        `)).join('')}

      </div>
    </div>
  `)).join('')}
</body>
</html>
`);
}

export default template;
