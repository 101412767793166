import apiClient from "../ordertrack-client";
import {StackType} from "./types";
import stackLabelTemplate from "./stack-qr-label";
import randomize from "randomatic";
import htmlToPdf, {PdfOptions} from "../utils/html-to-pdf";

export async function getAllStacks(
  page?: number,
  perPage?: number
): Promise<StackType[]> {
  const res = await apiClient.get(`/stack`);
  return res.data?.data;
}

export const generateStackSheets = (
  stacks: StackType[],
  edited?: boolean
): Promise<string> => {
  let labelsHtml: string = stackLabelTemplate(stacks, edited);
  let pdfOptions: PdfOptions = {
    pdf_mode: "portrait",
    pdf_name: `stack-sheets-${randomize("a0", 12)}`,
    page_height: "110mm",
    page_width: "152.4mm",
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  };

  return htmlToPdf(labelsHtml, pdfOptions);
};
