
import React from 'react';
import usePersons from '../../core/providers/PersonsProvider/use-persons';
import { PersonEditDialogProps } from './types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PersonForm from '../../core/forms/PersonForm';

const PersonEditDialog: React.FC<PersonEditDialogProps> = function(props) {
  const { person, onClose, ...dialogProps } = props;
  const [, busy, { update: updatePerson }] = usePersons();

  async function handleSave(data: any) {
    await updatePerson(person.id, data);
    onClose && onClose();
  }

  return (
    <Dialog
      disableBackdropClick={busy}
      disableEscapeKeyDown={busy}
      maxWidth="xs"
      fullWidth
      {...dialogProps}
    >
      <DialogTitle>Edit person profile</DialogTitle>
      <DialogContent>
        <PersonForm
          initialValues={person}
          onSubmit={handleSave}
          disabled={busy}
        >
          <DialogActions>
            <Button disabled={busy} onClick={()=>onClose && onClose()}>Cancel</Button>
            <Button disabled={busy} type="submit" >Save</Button>
          </DialogActions>
        </PersonForm>
      </DialogContent>
    </Dialog>
  );
};

export default PersonEditDialog;
