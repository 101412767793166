import {createStyles, Theme} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey["50"],
      "&:hover $titleBar": {
        // backgroundImage: `repeating-linear-gradient(
        //   45deg,
        //   rgba(255,255,255,0),
        //   rgba(255,255,255,0) 10px,
        //   ${blueGrey['50']} 10px,
        //   ${blueGrey['50']} 20px
        // )`
      },
    },
    titleBar: {
      // backgroundColor: theme.palette.grey['50'],
      // backgroundImage: `repeating-linear-gradient(
      //   45deg,
      //   rgba(255,255,255,0),
      //   rgba(255,255,255,0) 10px,
      //   ${theme.palette.grey['50']} 10px,
      //   ${theme.palette.grey['50']} 20px
      // )`
    },
    titleBarContent: {
      alignItems: "center",
      margin: 0,
      "&.Mui-expanded": {
        margin: 0,
      },
    },
    avatar: {
      backgroundColor: "rgba(255,255,255,0)",
      color: "inherit",
    },
    details: {
      padding: theme.spacing(2),
    },
  });

export const qtyStyles = (theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textCenter: "center",
      flexDirection: "column",
      gap: "0",
      margin: "auto",
      width: "100%",
    },
    qty: {
      lineHeight: "normal",
      fontSize: "25rem",
      fontWeight: "bold",
      margin: "0",
    },
    name: {
      fontSize: "1.5rem",
      marginTop: 0,
    },
    footer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "flex-end",
      marginTop: "1.5rem",
      "& img": {
        width: 100,
        height: 100,
      },
    },
  });
export default styles;
