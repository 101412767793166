import {createStyles, Theme} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    modalContentWrap: {
      width: "400px",
      maxWidth: "90vw",
    },
    videoPlayer: {
      display: "block",
      width: "100%",
      maxHeight: 600,
      backgroundColor: theme.palette.common.black,
    },
    emailModal: {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "0.5rem",
      "& img": {
        maxHeight: "100px",
      },
    },
    modalCloseBtn: {
      position: "absolute",
      top: "0.5rem",
      right: "0.5rem",
    },
    emailForm: {
      textAlign: "center",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      gap: "1rem",
      width: "100%",
      marginTop: "0.5rem",
    },
    trainingHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      gap: "1rem",
      // "@media(max-width: 600px)": {
      //   width: "50vw",
      // },
    },
    checkboxLabel: {
      textAlign: "start",
      alignItems: "flex-start",
      "& .MuiTypography-body1": {
        fontSize: "0.9rem",
      },
      "& .MuiButtonBase-root": {
        padding: "0 9px",
      },
    },
  });

export default styles;
