
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Highcharts from 'highcharts';
import Highcgarts3d from 'highcharts/highcharts-3d';
import HighchartsDrilldown from 'highcharts/modules/drilldown';
import HighchartsReact from 'highcharts-react-official';

import { usePartsDistributionReport } from '../../../../../core/providers/PlaceReportProvider';

Highcgarts3d(Highcharts);
HighchartsDrilldown(Highcharts);

const DistributionPie: React.FC = () => {
  const distributionReport = usePartsDistributionReport();
  if ( !distributionReport ) return null;

  if (distributionReport.totalQuantity === 0)
    return (
      <Typography variant="caption" color="textSecondary">No parts currently residing here.</Typography>
    );

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={{
        chart: {
          type: 'pie',
          options3d: {
            enabled: true,
            alpha: 45
          }
        },
        title: null,
        subtitle: null,
        tooltip: {
          shared: true,
          headerFormat: '<b style="font-size: 15px">{point.key}</b><br/>',
          pointFormat: '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>'
        },
        legend: {
          // labelFormat: '{name}: {point.y:.2f}',
          // @ts-ignore
          labelFormatter: function () {
            // @ts-ignore
            return `<span style="display: inline-block; padding: 2px 0 2px 4px; border-left: 1px solid ${this.color};"><b style="font-size: 0.85rem; line-height: 1;">${this.y}</b><br/><i style="font-weight: 300; font-size: 0.7rem;">${this.name}</i></span>`;
          },
          useHTML: true
        },
        plotOptions: {
          pie: {
            innerSize: 100,
            depth: 45,
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: false,
              format: '<b style="color: {point.color};">{point.name}</b>: <span >{point.percentage:.1f} %</span>'
            },
            showInLegend: true
          }
        },
        series: [{
          name: 'Quantity',
          data: Object.keys(distributionReport.distribution).map(k=>[k, distributionReport.distribution[k]])
        }]
      }}
    />
  );
};

export default DistributionPie;
