import randomize from "randomatic";
import template from "./pdf-templates/order-plate";
import {Bin, BinFamily} from "../bin-families";
import html2Pdf, {PdfOptions} from "../utils/html-to-pdf";
import {generateUrl} from "../utils/parse-momenttrack-id";

class OrderPlate {
  binFamilies: BinFamily[];
  bins: Bin[];

  constructor(data: {binFamilies: BinFamily[]; bins: Bin[]}) {
    this.binFamilies = data.binFamilies;
    this.bins = data.bins;
  }

  async getPdf() {
    const allBins = this.bins;
    const binsMap = allBins.reduce((map, bin, i) => {
      map[bin.bin_family_id] = map[bin.bin_family_id] || [];
      map[bin.bin_family_id].push(bin);
      return map;
    }, {} as {[key: number]: Bin[]});

    let labelsData: any = [];

    this.binFamilies.forEach(binFamily => {
      const bins = binsMap[binFamily.id];
      if (!bins) return;

      bins.forEach((bin, i) => {
        if (i === 1) return; // Skip the second bin

        labelsData.push({
          url: generateUrl(bin.id, "document"),
          title: binFamily.product.name,
          subtitle: binFamily.product.part_number,
        });
      });
    });

    const requestData: PdfOptions = {
      pdf_mode: "portrait",
      pdf_name: `order-plate-${randomize("a0", 6)}`,
      page_size: "Letter",
      margin_top: "0mm",
      margin_bottom: "0mm",
      margin_left: "0mm",
      margin_right: "0mm",
      disable_smart_shrinking: null,
    };

    const response = await html2Pdf(template(labelsData, 21), requestData);

    // await momentTrackService.printPdf(response.data.data?.s3_path, 'Bin family labels from ordertrack');
    return response;
  }
}

export default OrderPlate;
