
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import People from './People';

const Persons: React.FC = () => {
  return (
    <Switch>
      <Route path="/persons" component={People} />
      <Redirect to="/persons" />
    </Switch>
  );
};

export default Persons;
