import apiClient from "../ordertrack-client";
import randomize from "randomatic";
import { Place, PlaceInputData } from "./types";
import htmlToPdf, { PdfOptions } from "../utils/html-to-pdf";
import singleLabelsTemplate from "./place-label-templates/place-label-single";
import labelsSheetTemplate from "./place-label-templates/place-labels-sheet";
import moveManySheetTemplate from "./place-label-templates/move-many-sheet";
import moveOneSheetTemplate from "./place-label-templates/move-one-sheet";
import locationOnlySheetTemplate from "./place-label-templates/location-only-sheet";

export async function getAllPlaces(): Promise<Place[]> {
  const res = await apiClient.get("/locations");
  return res.data.data;
}

export async function getPlace(placeId: number): Promise<Place> {
  const res = await apiClient.get(`/locations/${placeId}`);
  return res.data.data;
}

export const createPlace = async (data: PlaceInputData) => {
  const res = await apiClient.post("/locations", data);
  return res.data.data;
};

export const updatePlace = async (
  placeId: number,
  updatedData: PlaceInputData
): Promise<Place> => {
  const res = await apiClient.put(`/locations/${placeId}`, updatedData);
  return res.data.data;
};

export const generateActivitySheets = (
  places: Place[],
  type: string
): Promise<string> => {
  let labelsHtml;
  switch (type) {
    case "move-many": {
      labelsHtml = moveManySheetTemplate(places);
      break;
    }
    case "move-one": {
      labelsHtml = moveOneSheetTemplate(places);
      break;
    }
    case "location-only": {
      labelsHtml = locationOnlySheetTemplate(places);
      break;
    }
    default:
      labelsHtml = moveOneSheetTemplate(places);
      break;
  }
  const pdfOptions: PdfOptions = {
    pdf_mode: "portrait",
    pdf_name: `move-many-sheets-${randomize("a0", 12)}`,
    page_width: "101.6mm",
    page_height: "152.4mm",
    margin_top: "0mm",
    margin_bottom: "0mm",
    margin_left: "0mm",
    margin_right: "0mm",
    disable_smart_shrinking: null,
  };

  return htmlToPdf(labelsHtml, pdfOptions);
};

export const generatePlaceLabels = (
  Places: Place[],
  templateType: "single" | "sheet"
): Promise<string> => {
  let labelsHtml = "";
  let pdfOptions: PdfOptions = {};

  switch (templateType) {
    case "single": {
      labelsHtml = singleLabelsTemplate(Places);
      pdfOptions = {
        pdf_mode: "portrait",
        pdf_name: `place-labels-${randomize("a0", 12)}`,
        page_width: "66.675mm",
        page_height: "25.4mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
      break;
    }
    case "sheet":
    default: {
      labelsHtml = labelsSheetTemplate(Places, 30);
      pdfOptions = {
        pdf_mode: "portrait",
        pdf_name: `place-label-sheets-${randomize("a0", 12)}`,
        page_size: "Letter",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
      break;
    }
  }

  return htmlToPdf(labelsHtml, pdfOptions);
};
