// VendorsProvider.tsx
import React, {useState, useLayoutEffect, useCallback} from "react";
import context from "./context";
import useNotify from "../../hooks/use-notify";
import {useAuthentication} from "../AuthenticationProvider";
import {
  Vendor,
  VendorInputData,
  createVendors,
  updateVendor,
  deleteVendor,
  getAllVendors,
} from "../../system/vendors";

const VendorsProvider: React.FC = ({children}) => {
  const authenticated = !!useAuthentication()[0];
  const [vendors, setVendors] = useState<Vendor[]>([]);
  const [loading, _setLoading] = useState(0);
  const [message, setMessage] = useState<Error | string>("");
  const notify = useNotify();

  const setLoading = (increment: boolean) =>
    _setLoading(prevVal => (increment ? ++prevVal : --prevVal));

  const fetchAllVendors = useCallback(async () => {
    try {
      setLoading(true);
      const vendors = await getAllVendors();
      vendors.sort((a, b) => a.name.localeCompare(b.name));
      setMessage(vendors.length === 0 ? "Nothing found." : "");
      setVendors(vendors);
    } catch (err) {
      notify(err as Error);
      setMessage("Failed to load vendors.");
    } finally {
      setLoading(false);
    }
  }, [notify]);

  const create = useCallback(
    async (vendorData: VendorInputData): Promise<Vendor | null> => {
      try {
        setLoading(true);
        const vendor = await createVendors(vendorData);
        setVendors(prevState => [vendor, ...prevState]);
        setLoading(false);
        return vendor;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return null;
      }
    },
    [notify]
  );

  const update = useCallback(
    async (vendorId: number, updatedData: VendorInputData) => {
      try {
        setLoading(true);
        const updatedVendor = await updateVendor(vendorId, updatedData);
        setVendors(vendors => {
          const index = vendors.findIndex(v => v.id === vendorId);
          if (index >= 0) vendors.splice(index, 1, updatedVendor);
          return [...vendors];
        });
        setLoading(false);
        notify(`Updated "${updatedVendor.name}".`);
        return updatedVendor;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return null;
      }
    },
    [notify]
  );

  const deleteById = useCallback(
    async (vendorId: number) => {
      try {
        setLoading(true);
        await deleteVendor(vendorId);
        setVendors(vendors => vendors.filter(v => v.id !== vendorId));
        setLoading(false);
        notify(`Deleted vendor with ID ${vendorId}.`);
        return true;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return false;
      }
    },
    [notify]
  );

  useLayoutEffect(() => {
    if (!authenticated) return;
    let active = true;
    (async () => {
      try {
        fetchAllVendors();
      } catch (err: any) {
        if (!active) return;
        notify(err as Error);
      }
    })();
    return () => {
      active = false;
    };
  }, [notify, authenticated, fetchAllVendors]);

  return (
    <context.Provider
      value={[vendors, !!loading, message, {create, update, deleteById}]}
    >
      {children}
    </context.Provider>
  );
};

export default VendorsProvider;
