
import { useMemo } from 'react';
import useStationReport from './use-station-report';

const usePartsDistribution = (placeId: number) => {
  const report = useStationReport(placeId);

  return useMemo(()=>{
    if(!report) return null;

    return (report.license_plates || []).reduce((map, lp)=>{
      const currCount = map.distribution[lp.product.part_number] || 0;
      map.distribution[lp.product.part_number] = currCount + lp.quantity;
      map.totalQuantity += lp.quantity;

      return map;
    }, { totalQuantity: 0, distribution: {} as Record<string, number> });
  }, [report]);
}

export default usePartsDistribution;
