// function to open a new url in a new tab
export const openInNewTab = (url: string) => {
  if (typeof window === "object") window?.open(url, "_blank");
};

// function to get baseurl : @params: {isOrderTrack}
export const getBaseUrl = ({isOrderTrack = true}: {isOrderTrack?: boolean}) => {
  if (!isOrderTrack) {
    return process.env.NODE_ENV === "development"
      ? window.location.hostname
      : window.location.href.includes("dev")
      ? `https://dev.3hd.us/`
      : `https://3hd.us/`;
  } else {
    return process.env.NODE_ENV === "development"
      ? window.location.hostname
      : window.location.href.includes("dev")
      ? `https://productiontrack-dev.momenttrack.com/`
      : `https://productiontrack.momenttrack.com/`;
  }
};
