
import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import PasswordForm, { PasswordFormValues } from '../../core/forms/PasswordForm';
import { useAuthentication } from '../../core/providers/AuthenticationProvider';
import useNotify from '../../core/hooks/use-notify';

const SecuritySettings: React.FC = () => {
  const [, loading, { updatePassword }] = useAuthentication();
  const notify = useNotify();

  const handlePasswordUpdate = (data: PasswordFormValues) => {
    if(data.password && data.password !== data.confirm_password)
      return notify(new Error('Password and confirm password fields do not match.'));

    updatePassword(data.password as string);
  };

  return (
    <>
      <Box p={2}>
        <Typography variant="h6">Change password</Typography>
      </Box>
      <Box p={2}>
        <Grid container>
          <Grid item xs={12} md={6} lg={4}>
            <PasswordForm
              onSubmit={handlePasswordUpdate}
              disabled={loading}
            >
              {!loading && (
                <Button
                  type="submit"
                  disabled={loading}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              )}
              {loading && <CircularProgress size={24} />}
            </PasswordForm>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SecuritySettings;
