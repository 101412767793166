import React, {useLayoutEffect, useState, useCallback, useRef} from "react";
import {
  getReportSections,
  saveReportsSection,
  deleteReportsSection,
  IReportsSection,
} from "./report-sections";
import useNotify from "../core/hooks/use-notify";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import Layout, {Slot, Content} from "../core/components/Layout";
import ReportsSection from "./ReportsSection";
import ReportsSectionEditor from "./ReportsSectionEditor";
import DateRangePicker, {
  DateRangeProvider,
} from "../core/components/DateRangePicker";

let from: Date = new Date(),
  to: Date = new Date();
// Fix time component from the range. set 12am for "from" date and 11:59pm for "to" date.
from = new Date(from.toDateString());
to = new Date(to.toDateString());
to.setDate(to.getDate() + 1);
to.setMilliseconds(-1);
const DATE_RANGE_FOR_TODAY = {from, to};

const Dashboard: React.FC = () => {
  const notify = useNotify();
  const editorActionsContainer = useRef<HTMLDivElement>(null);
  const [reportSections, setReportSections] = useState<IReportsSection[]>([]);
  const [editorOpen, setEditorOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  const handleReportsSectionSave = useCallback(
    async (data: Partial<IReportsSection>) => {
      try {
        setSaving(true);
        // Save reports section
        const newReportsSection = await saveReportsSection(data);
        setReportSections(sections => [...sections, newReportsSection]);
        // Close the editor dialog
        setEditorOpen(false);
      } catch (err) {
        notify(err as Error);
      } finally {
        setSaving(false);
      }
    },
    [notify]
  );

  const handleReportsSectionUpdate = useCallback(
    async (_id: string, data: Partial<IReportsSection>) => {
      const updatedReportsSection = await saveReportsSection({...data, _id});
      setReportSections(sections => {
        const currIndex = sections.findIndex(l => l._id === _id);
        if (currIndex > -1) {
          sections.splice(currIndex, 1, updatedReportsSection);
        }
        return [...sections];
      });
    },
    []
  );

  const handleReportsSectionDelete = useCallback(async (_id: string) => {
    await deleteReportsSection(_id);
    setReportSections(sections => {
      const currIndex = sections.findIndex(l => l._id === _id);
      if (currIndex > -1) {
        sections.splice(currIndex, 1);
      }
      return [...sections];
    });
  }, []);

  useLayoutEffect(() => {
    (async () => {
      try {
        const sections = await getReportSections();
        setReportSections(sections);
      } catch (err) {
        notify(err as Error);
      }
    })();
  }, [notify]);

  return (
    <DateRangeProvider initialValue={DATE_RANGE_FOR_TODAY}>
      <Layout>
        <Slot name="main-toolbar">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs="auto">
              <Typography variant="h6">Dashboard</Typography>
            </Grid>
            <Grid item xs />
            <Grid item xs="auto">
              <DateRangePicker />
            </Grid>
          </Grid>
        </Slot>

        <Content>
          <Box py={2}>
            <Container maxWidth={false}>
              <Grid container direction="column" spacing={2}>
                {reportSections.map(section => (
                  <ReportsSection
                    key={section._id}
                    {...section}
                    onUpdate={handleReportsSectionUpdate.bind(
                      undefined,
                      section._id
                    )}
                    onDelete={handleReportsSectionDelete.bind(
                      undefined,
                      section._id
                    )}
                    defaultExpanded
                  />
                ))}
                <Grid item>
                  <Button
                    onClick={() => setEditorOpen(true)}
                    startIcon={<Icon>add_chart</Icon>}
                    color="secondary"
                    size="large"
                  >
                    Add {reportSections.length === 0 ? "reports" : "another"}{" "}
                    section
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </Content>

        <Dialog
          open={editorOpen}
          fullWidth
          maxWidth="md"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <DialogTitle>New reports section</DialogTitle>
          <DialogContent>
            <ReportsSectionEditor
              onSave={handleReportsSectionSave}
              actionsContainerRef={editorActionsContainer}
              disabled={saving}
            />
          </DialogContent>
          <DialogActions>
            {saving && <CircularProgress size={24} />}
            <Button onClick={() => setEditorOpen(false)} disabled={saving}>
              Cancel
            </Button>
            <div ref={editorActionsContainer} />
          </DialogActions>
        </Dialog>
      </Layout>
    </DateRangeProvider>
  );
};

export default Dashboard;
