import React, {useState, useRef, useCallback, useMemo} from "react";
import {ReportsSectionProps, ReportsSectionData} from "./types";
import useNotify from "../../core/hooks/use-notify";
import {useLocation, useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Accordian from "@material-ui/core/Accordion";
import AccordianSummary from "@material-ui/core/AccordionSummary";
import AccordianDetails from "@material-ui/core/AccordionDetails";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Avatar from "@material-ui/core/Avatar";
import Icon from "@material-ui/core/Icon";
import ReportsSectionEditor from "../ReportsSectionEditor";
import StationReportCard from "./StationReportCard";
import OrderReportCard from "./StationReportCard/OrderReportCard";
import OrderReportProvider from "../../core/providers/PlaceReportProvider/OrderReportProvider";
import DistributionReport from "./StationReportCard/DistributionReport";

const useStyles = makeStyles(styles);
const RELOAD_INTERVAL = 15 * 60 * 1000; // 15 minutes

const ReportsSection: React.FC<ReportsSectionProps> = props => {
  const {title, details, reports, onUpdate, onDelete, ...accordianProps} =
    props;

  const classes = useStyles();
  const notify = useNotify();
  const {search} = useLocation();
  const history = useHistory();
  const editorActionsContainer = useRef<HTMLDivElement>(null);
  const [editorOpen, setEditorOpen] = useState(false);
  const [saving, setSaving] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);

  const query = useMemo(() => new URLSearchParams(search), [search]);

  const handleSave = useCallback(
    async (data: Partial<ReportsSectionData>) => {
      try {
        setSaving(true);
        if (!!onUpdate) await onUpdate(data);

        setEditorOpen(false);
      } catch (err) {
        notify(err as Error);
      } finally {
        setSaving(false);
      }
    },
    [notify, onUpdate]
  );

  const handleDelete = useCallback(async () => {
    try {
      setSaving(true);
      if (
        !!onDelete &&
        window.confirm(`Deleting section "${title}". Are you sure?`)
      ) {
        await onDelete();
      } else setSaving(false);
    } catch (err) {
      notify(err as Error);
      setSaving(false);
    }
  }, [notify, title, onDelete]);

  return (
    <Accordian {...accordianProps} classes={{root: classes.root}}>
      <AccordianSummary
        classes={{root: classes.titleBar, content: classes.titleBarContent}}
        expandIcon={<Icon>expand_more</Icon>}
      >
        <Avatar className={classes.avatar}>
          <Icon>insights</Icon>
        </Avatar>

        <Typography variant="body1">{props.title}</Typography>
        <Box flexGrow={1} />
        <IconButton
          onClick={ev => {
            ev.stopPropagation();
            setMenuAnchor(ev.currentTarget);
          }}
          onFocus={ev => ev.stopPropagation()}
          edge="end"
          size="small"
        >
          <Icon fontSize="large">more_horiz</Icon>
        </IconButton>
        <Menu
          open={Boolean(menuAnchor)}
          onClose={() => setMenuAnchor(null)}
          anchorEl={menuAnchor}
          onClick={ev => ev.stopPropagation()}
        >
          <MenuItem
            onClick={ev => {
              setMenuAnchor(null);
              setEditorOpen(true);
            }}
          >
            <ListItemIcon>
              <Icon>edit</Icon>
            </ListItemIcon>
            <ListItemText primary="Edit section" />
          </MenuItem>
          {!!props.onDelete && (
            <MenuItem
              onClick={ev => {
                setMenuAnchor(null);
                handleDelete();
              }}
            >
              <ListItemIcon>
                <Icon color="error">delete</Icon>
              </ListItemIcon>
              <ListItemText
                primary="Remove from dashboard"
                primaryTypographyProps={{color: "error"}}
              />
            </MenuItem>
          )}
        </Menu>
      </AccordianSummary>
      <AccordianDetails classes={{root: classes.details}}>
        <Grid container spacing={2}>
          {(reports || [])
            .filter(el => el.type !== "distribution-report")
            .map((_rep, i) => (
              <>
                <Grid
                  key={`${_rep.station.beacon_id}-${i}`}
                  item
                  xs={12}
                  sm={_rep.default_report === "qty" ? 12 : 6}
                  lg={_rep.default_report === "qty" ? 12 : 4}
                >
                  {_rep.type === "location" ? (
                    <StationReportCard
                      {..._rep.station}
                      id={_rep.station.id}
                      default_report={_rep.default_report}
                      text={_rep.text ? _rep.text : undefined}
                      onClickAnalytics={(placeId: number) => {
                        query.set("sec", props._id.slice(-6));
                        history.push(
                          `/reports/${placeId}/${
                            _rep.default_report
                          }?${query.toString()}`
                        );
                      }}
                    />
                  ) : (
                    <OrderReportProvider
                      id={_rep.station.id}
                      reloadInterval={RELOAD_INTERVAL}
                    >
                      <OrderReportCard
                        {..._rep.station}
                        id={_rep.station.id}
                        default_report={_rep.default_report}
                        text={_rep.text ? _rep.text : undefined}
                      />
                    </OrderReportProvider>
                  )}
                </Grid>
              </>
            ))}
          {(reports || [])
            .filter(el => el.type === "distribution-report")
            .map((_rep, i) => (
              <Grid key={`${_rep.station.beacon_id}-${i}`} item xs={12}>
                <DistributionReport stations={_rep?.stations || []} />
              </Grid>
            ))}
        </Grid>
      </AccordianDetails>

      {/* Reports section editor dialog */}
      <Dialog
        open={editorOpen}
        onClose={() => setEditorOpen(false)}
        disableBackdropClick
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          Edit reports section{" "}
          <small>
            <i>({props.title})</i>
          </small>
        </DialogTitle>
        <DialogContent>
          <ReportsSectionEditor
            initialValues={{
              title: props.title,
              details: props.details,
              reports: props.reports,
            }}
            onSave={handleSave}
            disabled={saving}
            actionsContainerRef={editorActionsContainer}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditorOpen(false)} disabled={saving}>
            Cancel
          </Button>
          <div ref={editorActionsContainer} />
        </DialogActions>
      </Dialog>
    </Accordian>
  );
};

export default ReportsSection;
