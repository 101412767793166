import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Link,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {ReactComponent as ProductSvg} from "../../assets/icons/product.svg";
import {ReactComponent as PersonSvg} from "../../assets/icons/person.svg";
import {ReactComponent as ClockSvg} from "../../assets/icons/clock.svg";
import DateTag from "../../core/components/DateTag";
import {LogsTableProps} from "./types";
import styles from "../styles";

const useStyles = makeStyles(styles);

const LogsTable: React.FC<LogsTableProps> = ({logs}) => {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell className={classes.tableCell}>
              <b>EXT. SN</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <b>PART NUMBER</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <SvgIcon>
                <ProductSvg />
              </SvgIcon>{" "}
              <b>PRODUCT QR</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <b>DESCRIPTION</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <b>QUANTITY</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <SvgIcon>
                <PersonSvg />
              </SvgIcon>{" "}
              <b>PERSON</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <SvgIcon>
                <ClockSvg />
              </SvgIcon>{" "}
              <b>ARRIVED AT</b>
            </TableCell>
            <TableCell className={classes.tableCell}>
              <SvgIcon>
                <ClockSvg />
              </SvgIcon>{" "}
              <b>LEFT AT</b>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {logs.map(log => (
            <TableRow key={log.license_plate_id + log.arrived_at}>
              <TableCell className={classes.tableCell}>
                {log.license_plate.external_serial_number || "--"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {log.product.part_number}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link
                  color="textPrimary"
                  href={`https://${
                    process.env.REACT_APP_QR_BASENAME || "3hd.us"
                  }/${log?.license_plate?.lp_id}`}
                  target="_BLANK"
                >
                  {process.env.REACT_APP_QR_BASENAME || "3hd.us"}
                  /...{(log.license_plate?.lp_id || "--")?.slice(-5)}
                </Link>
              </TableCell>
              <TableCell className={classes.tableCell}>
                {log.product.description || "--"}
              </TableCell>
              <TableCell className={classes.tableCell}>
                {log.license_plate.quantity}
              </TableCell>
              <TableCell className={classes.tableCell}>
                <Link href={`/${log.user.person_id}`} target="_BLANK">
                  {log.user.first_name}
                </Link>
              </TableCell>
              <TableCell className={classes.tableCell}>
                <DateTag date={log.arrived_at} dateInputType="utc" />
              </TableCell>
              <TableCell className={classes.tableCell}>
                {!!log.left_at ? (
                  <DateTag date={log.left_at} dateInputType="utc" />
                ) : (
                  "--"
                )}
              </TableCell>
            </TableRow>
          ))}

          {logs.length === 0 && (
            <TableRow>
              <TableCell colSpan={4}>
                <Typography variant="body2" color="textSecondary">
                  No logs found.
                </Typography>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default LogsTable;
