import {DetailedHTMLProps, ImgHTMLAttributes} from "react";

interface ImageProps {
  width?: string;
  alt?: string;
}

const Image = ({
  className,
  ...props
}: ImageProps &
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>) => (
  <img className={`${className} mt-img`} alt={props.alt} {...props} />
);

export default Image;
