import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import {LinearProgress, Typography} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "0.5rem",
    height: "100vh",
    width: "100vw",
    backgroundColor: "#f0f0f0",
    position: "fixed",
    inset: 0,
    zIndex: 100,
  },
  progressbar: {
    width: 300,
  },

  svgWrapper: {
    width: 250,
    overflow: "hidden",
    position: "relative",
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
  },
  truck: {
    fontSize: "4rem",
    position: "relative",
    left: "0",
    animation: "$moveAndWiggle 3s linear infinite",
  },
  "@keyframes moveAndWiggle": {
    "0%": {transform: "translateX(-20px)"},
    "100%": {transform: "translateX(270px)"},
  },

  // truck: {
  //   fontSize: "4rem",
  //   animation:
  //     "$moveAndWiggle 3s linear infinite, $resetPosition 5s linear infinite",
  // },
  // "@keyframes moveAndWiggle": {
  //   "0%": {transform: "translateX(-100%) rotate(0deg)"},
  //   "10%": {transform: "translateX(-80%) rotate(-5deg)"},
  //   "20%": {transform: "translateX(-60%) rotate(5deg)"},
  //   "30%": {transform: "translateX(-40%) rotate(-5deg)"},
  //   "40%": {transform: "translateX(-20%) rotate(5deg)"},
  //   "50%": {transform: "translateX(0%) rotate(0deg)"},
  //   "60%": {transform: "translateX(20%) rotate(-5deg)"},
  //   "70%": {transform: "translateX(40%) rotate(5deg)"},
  //   "80%": {transform: "translateX(60%) rotate(-5deg)"},
  //   "90%": {transform: "translateX(80%) rotate(5deg)"},
  //   "100%": {transform: "translateX(100%) rotate(0deg)"},
  // },
  // "@keyframes resetPosition": {
  //   "0%": {opacity: 1},
  //   "95%": {opacity: 1},
  //   "100%": {opacity: 0, transform: "translateX(-100%)"},
  // },
}));

const TruckLoader = ({
  message = "Sending your request...",
}: {
  message?: string;
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.svgWrapper}>
        <LocalShippingIcon className={classes.truck} />
      </div>
      <LinearProgress color="secondary" className={classes.progressbar} />
      <Typography style={{marginTop: "1rem"}} variant="h5">
        {message}
      </Typography>
    </div>
  );
};

export default TruckLoader;
