import {useEffect, useRef, useState, ReactNode, MutableRefObject} from "react";
import "./style.css";

type Props = {
  action: ReactNode;
  children: ReactNode;
  className?: string;
  closeOnChildClick?: boolean;
  position?: "top" | "bottom";
  closeOnOutsideClick?: boolean;
  extraClick?: Function;
};

const DropDownWrapper = ({
  action,
  children,
  className,
  closeOnChildClick = false,
  closeOnOutsideClick = true,
  position = "bottom",
  extraClick = () => {},
}: Props) => {
  const [showDropDown, setshowDropDown] = useState(false);
  const ref = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (closeOnOutsideClick) {
        if (showDropDown && ref.current && !ref.current?.contains(e.target)) {
          setshowDropDown(false);
        }
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [closeOnOutsideClick, showDropDown]);

  return (
    <div ref={ref} className={`drop-down-wrapper ${className || ""}`}>
      <div
        onClick={() => {
          extraClick();
          setshowDropDown(!showDropDown);
        }}
        className="drop-down-action"
      >
        {action}
      </div>
      <div
        onClick={() =>
          closeOnChildClick ? setshowDropDown(!showDropDown) : null
        }
        className={`drop-down-content ${
          position === "top"
            ? "origin-bottom-left bottom-[120%] left-0"
            : "origin-top-right top-[120%]"
        } ${showDropDown ? "show-drop-down" : ""} `}
      >
        {children}
      </div>
    </div>
  );
};

export default DropDownWrapper;
