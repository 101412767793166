
import React, { useState, useMemo, useLayoutEffect, useCallback } from 'react';
import { Person } from './types';
import context from './context';
import useNotify from '../../hooks/use-notify';
import { useAuthentication } from '../AuthenticationProvider';
import useRoles from '../../hooks/use-roles';
import { getAllPersons, sendPersonInvite, getPerson, deletePerson, updatePerson, PersonInputData } from '../../system/persons';

const PersonsProvider: React.FC = ({ children }) => {
  const authenticated = !!useAuthentication()[0];
  const [persons, setPersons] = useState<Person[]>([]);
  const [loading, _setLoading] = useState(0);
  const notify = useNotify();
  const [roles] = useRoles();

  const setLoading = (increment: boolean) => _setLoading(prevVal=>increment ? ++prevVal : --prevVal);

  const rolesMap = useMemo(()=>new Map(roles.map(r=>[r.id, r])), [roles]);
  const personsList = useMemo(()=>{
    return persons.map(p=>({
      ...p,
      role: rolesMap.get((p.role_ids || [])[0]) || null
    }));

  }, [rolesMap, persons]);

  const fetchAll = useCallback(async () => {
    try{
      setLoading(true);
      const persons = await getAllPersons();

      setPersons(persons);
      setLoading(false);
    }
    catch(err: any){
      notify(err);
    }
  }, [notify]);

  /**
   * Send person invite.
   */
  const sendInvite = useCallback(async (personEmail: string) => {
    try{
      setLoading(true);
      const message = await sendPersonInvite(personEmail);
      await fetchAll();
      notify(message);
      setLoading(false);
      return true;
    }
    catch(err: any){
      notify(err);
      setLoading(false);
      return false;
    }
  }, [notify, fetchAll]);

  /**
   * Get person by id.
   */
  const getById = useCallback(async (personId: number, forceFetch = false): Promise<Person | null> => {
    try{
      const person = persons.find(p=>p.id === personId) || null;
      if(!person || forceFetch){
        setLoading(true);
        const person = await getPerson(personId);
        setLoading(false);
        return person;
      }

      return person;
    }
    catch(err: any){
      notify(err);
      setLoading(false);
      return null;
    }

  }, [notify, persons]);

  /**
   * Update a person.
   */
  const update = useCallback(async (personId: number, data: PersonInputData): Promise<Person | null> => {
    try{
      setLoading(true);
      const updatedPerson: Person = await updatePerson(personId, data);
      updatedPerson.role = rolesMap.get((updatedPerson.role_ids || [])[0]) || null;

      setPersons(prevState=>{
        const index = prevState.findIndex(u=>u.id === personId);
        if(index > -1){
          const temp = [...prevState];
          temp.splice(index, 1, updatedPerson);
          return temp;
        }
        return [...prevState];
      });

      notify('User updated.');
      setLoading(false);
      return updatedPerson;
    }
    catch(err: any){
      notify(err);
      setLoading(false);
      return null;
    }

  }, [notify, rolesMap]);

  /**
   * Remove a person.
   */
  const remove = useCallback(async (person: Person) => {
    try{
      setLoading(true);
      await deletePerson(person.id);
      setPersons(prevState=>prevState.filter(u=>u.id !== person.id));
      setLoading(false);
      notify('Person deleted.');
      return true;
    }
    catch(err: any){
      notify(err);
      setLoading(false);
      return false;
    }

  }, [notify]);

  useLayoutEffect(()=>{
    if(authenticated)
      fetchAll();

  }, [fetchAll, authenticated]);

  return (
    <context.Provider value={[personsList, !!loading, { sendInvite, fetchAll, getById, remove, update }]}>
      {children}
    </context.Provider>
  );
}

export default PersonsProvider;
