
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewAllProducts from './ViewAllProducts';

const Products: React.FC = () => {
  return (
    <Switch>
      <Route path="/products" component={ViewAllProducts} />
      <Redirect to="/products" />
    </Switch>
  );
};

export default Products;
