import React, {useState, useRef, FormEvent, useCallback} from "react";
import {partNumberLookup, Product} from "../../core/system/products";
import {
  createOrder,
  ProductionOrder,
} from "../../core/system/production-orders";
import {makeStyles} from "@material-ui/core/styles";
import useNotify from "../../core/hooks/use-notify";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import LinearProgress from "@material-ui/core/LinearProgress";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import DataGrid, {ColumnDef} from "../../core/components/DataGrid";
import EmbeddedContentDialog, {
  EmbeddedContentDialogApi,
} from "../../core/components/EmbeddedContentDialog";
import {generateActivitySheets} from "../../core/system/production-orders";
import styles from "./styles";
import {Autocomplete} from "@material-ui/lab";
import useProducts from "../../core/hooks/use-products";
import OptionsDialog, {
  OptionsDialogApi,
} from "../../core/components/OptionsDialog";
import OrderTemplateDialog, {
  OrderTemplateDialogApi,
} from "../OrderTemplateDialog";

const columns: ColumnDef[] = [
  {
    field: "description",
    headerName: "DESCRIPTION",
    sortable: true,
    valueGetter: (data: ProductionOrder) => data.product.description || "--",
  },
  {
    field: "part_number",
    headerName: "PART #",
    sortable: true,
    padding: "checkbox",
    width: 300,
    valueGetter: (data: ProductionOrder) => data.product.part_number,
    renderCell: (data: ProductionOrder) => (
      <Link
        href={`https://${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
          data.docid
        }`}
        target="_BLANK"
        style={{textDecoration: "underline"}}
        color="textSecondary"
      >
        {data.product.part_number ||
          `${
            process.env.REACT_APP_QR_BASENAME || "3hd.us"
          }/...${data.docid?.slice(-5)}`}
      </Link>
    ),
  },
  {
    field: "requested_qty",
    headerName: "QTY",
    sortable: true,
    valueGetter: (data: ProductionOrder) => data.requested_qty,
  },
];

const useStyles = makeStyles(styles);

const NewProductionOrder: React.FC = () => {
  const activitySheetDialog = useRef<EmbeddedContentDialogApi>(null);
  const [recentOrders, setRecentOrders] = useState<ProductionOrder[]>([]);
  const [ordersSelection, setOrdersSelection] = useState<ProductionOrder[]>([]);
  const [product, setProduct] = useState<Product | null>(null);
  const [qty, setQty] = useState("");
  const [internalWorkOrder, setInternalWorkOrder] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderPartNumber, setorderPartNumber] = useState<string | null>("");
  const notify = useNotify();
  const [products, loadingProducts] = useProducts();

  const handlePartNumberSearch = async (ev: FormEvent) => {
    try {
      ev.preventDefault();
      const partNumber = orderPartNumber || "";
      if (!partNumber.trim()) return;

      setLoading(true);
      setProduct(null);
      const product = await partNumberLookup(partNumber);
      setProduct(product);
      setLoading(false);
    } catch (err: any) {
      notify((err as Error).message);
      setLoading(false);
    }
  };

  const optionsDialog = useRef<OptionsDialogApi>(null);
  const handleSubmit = useCallback(
    (option: string) => {
      activitySheetDialog.current?.openDialog(() =>
        generateActivitySheets(
          recentOrders?.length > 0 ? [recentOrders[0]] : ordersSelection,
          option
        )
      );
    },
    [ordersSelection, recentOrders]
  );

  const handleCreateOrder = async () => {
    try {
      if (!qty.trim() || +qty <= 0) throw new Error("Invalid quantity.");

      setLoading(true);
      const newOrder = await createOrder(product?.id as number, {
        requested_qty: +qty,
        external_docid: internalWorkOrder,
      });
      setRecentOrders(orders => [newOrder, ...orders]);
      setOrdersSelection([newOrder]);
      if (newOrder.org_template) {
        viewTemplateDialog.current?.openDialog(newOrder, newOrder.org_template);
      } else {
        optionsDialog.current?.openDialog();
      }
      setQty("");
      setInternalWorkOrder("");
      setorderPartNumber("");
      setProduct(null);
      setLoading(false);
      notify("Production order created.");
    } catch (err: any) {
      notify(err as Error);
      setLoading(false);
    }
  };

  const classes = useStyles();
  const viewTemplateDialog = useRef<OrderTemplateDialogApi>(null);

  return (
    <Box py={4} mt={4}>
      <Container maxWidth="sm">
        <Box>
          <Typography align="center" variant="h4">
            Create production order
          </Typography>
        </Box>

        <Box mt={4}>
          <form
            style={{position: "relative"}}
            onSubmit={handlePartNumberSearch}
          >
            <Icon className={classes.inputAdornmentLeft}>tag</Icon>
            <Autocomplete
              fullWidth
              options={products?.map(el => el.part_number)}
              value={orderPartNumber}
              onChange={(ev: any, value: string | null) => {
                setorderPartNumber(value);
              }}
              disabled={loading}
              freeSolo
              loading={loadingProducts}
              renderInput={params => (
                <div>
                  <TextField
                    {...params}
                    className={classes.searchInput}
                    placeholder="Part number..."
                    variant="outlined"
                  />
                </div>
              )}
              // variant="outlined"
            />
            <IconButton
              className={classes.inputAdornmentRight}
              type="submit"
              disabled={loading}
            >
              <Icon>keyboard_return</Icon>
            </IconButton>
          </form>
        </Box>

        {!!product && (
          <Box mt={4}>
            <Card>
              <CardHeader
                avatar={<Icon>inventory</Icon>}
                title={product.name}
                subheader={product.description}
                titleTypographyProps={{variant: "h6"}}
              />
              <CardContent>
                <Grid container direction="column" spacing={4}>
                  <Grid item>
                    <TextField
                      className={classes.qtyInput}
                      type="number"
                      label="QTY"
                      placeholder="Order quantity"
                      fullWidth
                      value={qty}
                      onChange={ev => setQty(ev.target.value)}
                      disabled={loading}
                      inputProps={{min: 0}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>production_quantity_limits</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      className={classes.qtyInput}
                      type="text"
                      label="INTERNAL WO #"
                      placeholder="Internal work order number..."
                      fullWidth
                      value={internalWorkOrder}
                      onChange={ev => setInternalWorkOrder(ev.target.value)}
                      disabled={loading}
                      inputProps={{min: 0}}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Icon>tag</Icon>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
                <div>
                  <br />
                </div>
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={loading}
                  onClick={handleCreateOrder}
                >
                  Submit
                </Button>
              </CardActions>
            </Card>
          </Box>
        )}
        {loading && (
          <Box mt={8}>
            <LinearProgress variant="indeterminate" />
          </Box>
        )}
      </Container>
      <div>
        <br />
        <br />
        <br />
      </div>
      {recentOrders.length > 0 && (
        <Container maxWidth="md">
          <Paper>
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h6">Recently added</Typography>
              <Button
                onClick={() => optionsDialog.current?.openDialog()}
                disabled={ordersSelection.length === 0}
                variant="outlined"
                color="secondary"
                startIcon={<Icon>print</Icon>}
              >
                Generate labels
              </Button>
            </Box>
            <DataGrid
              columns={columns}
              rows={recentOrders}
              checkboxSelection
              onSelectionChange={selection =>
                setOrdersSelection(selection as ProductionOrder[])
              }
            />
          </Paper>
        </Container>
      )}

      <OptionsDialog
        ref={optionsDialog}
        title="Choose label type"
        onSubmit={handleSubmit}
        options={[
          {label: "Made many", value: "made-many"},
          {label: "Made with notes", value: "made-notes"},
          {label: "Stack order", value: "stack-order"},
          {label: "Production order only", value: "production-order"},
        ]}
      />
      <EmbeddedContentDialog
        ref={activitySheetDialog}
        title="New production order"
        onCloseDialog={() => {
          optionsDialog?.current?.closeDialog();
        }}
      />
      <OrderTemplateDialog
        onClose={() => {
          optionsDialog.current?.openDialog();
        }}
        ref={viewTemplateDialog}
      />
    </Box>
  );
};

export default NewProductionOrder;
