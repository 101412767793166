import {
  ComponentClass,
  DragEvent,
  FunctionComponent,
  MouseEventHandler,
  createElement,
} from "react";

const GenericComponentDisplay = ({
  icon,
  text,
  id,
  onClick = () => {},
}: {
  icon: string | FunctionComponent<any> | ComponentClass<any, any>;
  text: string;
  id: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
}) => {
  const dragStart = (e: DragEvent<HTMLButtonElement>) => {
    e.dataTransfer.setData("field_id", id);
  };

  const dragOver = (e: DragEvent<HTMLButtonElement>) => {
    e.stopPropagation();
  };

  return (
    <button
      onDragOver={ev => {
        ev.preventDefault();
      }}
      onDragStart={dragStart}
      onDrop={dragOver}
      // draggable
      onClick={onClick}
      className="suggestion-btn"
    >
      {createElement(icon, {style: {fontSize: "40px"}})}
      <p className="suggestion-btn-text">{text}</p>
    </button>
  );
};
export default GenericComponentDisplay;
