export interface SingleLabelInfo {
  docid: string;
  title: string;
}

const template = (doc: SingleLabelInfo) => `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <title>Single label</title>

    <style>
      body,
      html {
        margin: 0;
        padding: 0;
        font-size: 10px;
        font-family: sans-serif;
      }
      .label-root {
        position: relative;
        width: 3in;
        margin: auto;
        height: 5.6in;
        line-height: normal;
        background: #ffffff;
        padding: 0.2in;
      }
      .label-root .logo {
        margin: 1rem 0;
        width: 1.8in;
        margin: auto;
        display: block;
        height: 1.5in;
      }
      .label-root .qr-code {
        width: 3in;
        height: 3in;
      }
      .title {
        font-weight: 500;
        margin-bottom: 0.1in;
        white-space: nowrap;
        overflow: hidden;
        font-size: 20px;
        text-align: center;
        font-style: italic;
        text-overflow: ellipsis;
      }
      .label-root .label-footer {
        letter-spacing: 1px;
        font-size: 14px;
        padding-left: 0.035in;
        word-wrap: break-word;
      }
    </style>
  </head>
  <body>
    <div class="label-root">
    <img alt="learning in the moment logo" src="https://momenttrack-public-read.s3.us-west-2.amazonaws.com/logos/litmon-logo.png" class="logo"/>
      <p class="title">${doc.title}</p>
      <img
        src="https://api.3hd.us/app/qrcode_gen.create?data=${
          process.env.REACT_APP_QR_BASENAME || "3hd.us"
        }/${doc.docid}&logo=momenttrack"
        class="qr-code"
      />
    <p class="label-footer">${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
  doc.docid
}</p>
    </div>
  </body>
</html>
`;

export default template;
