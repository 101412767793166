import {Person} from "../types";

const template = (persons: Person[]) => `
<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Single label template</title>

  <style>
  @font-face {
    font-family: 'Noto Sans SC';
     src: url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');
   }
    body, html {
      margin: 0;
      padding: 0;
      font-size: 10px;
      font-family: 'Noto Sans SC', Arial, sans-serif;
    }

    .container {
      width: 2.625in;
      height: 1in;
    }

    .label-root {
      position: relative;
      width: 2.625in;
      height: 1in;
      line-height: normal;
      background: #ffffff;
    }

    .label-root > .safe-area-container {
      position: absolute;
      top: 0.035in;
      left: 0.035in;
      right: 0.035in;
      bottom: 0.035in;
    }

    .label-root p {
      margin: 0;
    }

    .label-root .qr-code-wrapper {
      float: left;
      width: 0.83in;
      line-height: 0;
      margin-right: 0.07in;
    }

    .qr-code-wrapper .qr-code {
      max-width: 100%;
    }

    .details-wrapper .label-meta {
      margin-right: 0.07in;
      margin-bottom: 0.07in;
    }

    .label-meta .icon {
      display: inline-block;
      max-width: 32px;
      max-height: 32px;
      vertical-align: middle;
      margin-right: 0.035in;
    }

    .label-meta p {
      display: inline;
    }

    .details-wrapper .label-info {
      overflow: hidden;
    }

    .label-info .title {
      font-weight: 700;
      margin-bottom: 0.035in;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-info .subtitle {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .label-root .label-footer {
      clear: both;
      font-size: 8px;
      letter-spacing: 1px;
      line-height: 0.1in;
      padding-left: 0.035in;
      height: 0.1in;
    }
  </style>
</head>
<body>
  ${persons
    .map(
      person => `
    <div class="container">

      <!-- BEGIN: Label -->
      <div class="label-root">
        <div class="safe-area-container">
          <div class="qr-code-wrapper">
            <img src="https://api.3hd.us/app/qrcode_gen.create?data=${
              process.env.REACT_APP_QR_BASENAME || "3hd.us"
            }/${person.person_id}&logo=momenttrack" class="qr-code"/>
          </div>
          <div class="details-wrapper">
            <div class="label-meta">
              <img src="https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/person.svg" class="icon">
              <p>Person</p>
            </div>
            <div class="label-info">
              <p class="title">${
                (person?.first_name || "--") + " " + (person?.last_name || "--")
              }</p>
              <p class="subtitle">${person.email || "--"}</p>
            </div>
          </div>
          <p class="label-footer">${
            process.env.REACT_APP_QR_BASENAME || "3hd.us"
          }/${person.person_id}</p>
        </div>
      </div>
      <!-- END: Label -->

    </div>
  `
    )
    .join("")}
</body>
</html>
`;

export default template;
