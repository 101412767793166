import apiClient from "../ordertrack-client";
import {Vendor, VendorInputData} from "./types";

export const getAllVendors = async (): Promise<Vendor[]> => {
  const res = await apiClient.get("/vendors");
  return res.data.data;
};

export const createVendors = async (data: VendorInputData): Promise<Vendor> => {
  const res = await apiClient.post("/vendors", data);
  return res.data.data;
};

export const updateVendor = async (
  vendorId: number,
  updatedData: VendorInputData
): Promise<Vendor> => {
  const res = await apiClient.put(`/vendors/${vendorId}`, updatedData);
  return res.data.data;
};

export const deleteVendor = async (
  vendorId: number
): Promise<Vendor | boolean> => {
  await apiClient.delete(`/vendors/${vendorId}`);
  return true;
};
