import React from "react";
import {LoadingIndicatorProps} from "./types";
import {styled} from "@material-ui/core/styles";
import {useMemo} from "react";
import IndicatorGif from "../../images/loader.gif";

const Overlay = styled("div")({
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  backgroundColor: "rgba(255,255,255,0.95)",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const Indicator = styled("div")({
  display: "inline-block",
  lineHeight: 0,
  backgroundImage: `url(${IndicatorGif})`,
  backgroundSize: "550%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center 56%",
});

const LoadingIndicator: React.FC<LoadingIndicatorProps> = props => {
  const {size = 64, overlay, fixed} = props;

  return useMemo(() => {
    const ele = (
      <Indicator
        style={{
          width: size,
          height: size,
        }}
      />
    );

    if (overlay)
      return (
        <Overlay style={fixed ? {position: "fixed"} : undefined}>{ele}</Overlay>
      );

    return ele;
  }, [size, overlay, fixed]);
};

export default LoadingIndicator;
