
import axios from 'axios';
import apiClient from '../ordertrack-client';
import { Product, ProductInputData } from './types';

export async function getAllProducts(): Promise<Product[]> {
  const res = await apiClient.get('/products');
  return res.data.data;
}

export async function getProduct(productId: number): Promise<Product> {
  const res = await apiClient.get(`/products/${productId}`);
  return res.data.data;
}

export const createProduct = async (data: ProductInputData) => {
  const res = await apiClient.post('/products', data);
  // Post to firebase for VPI
  axios.post('https://api.3hd.us/app/addpartnumber.addpartfirebase', {...res.data.data, org_slug: apiClient.defaults.baseURL?.split('/').pop()})
    .catch(err=>console.log(err));
  ;
  return res.data.data;
};

export const updateProduct = async (productId: number, updatedData: ProductInputData): Promise<Product> => {
  const res = await apiClient.put(`/products/${productId}`, updatedData);
  return res.data.data;
}

export async function partNumberLookup(part_number: string): Promise<Product> {
  const res = await apiClient.get('/products/lookup', {
    params: { part_number }
  });

  return res.data.data;
}
