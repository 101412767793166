
import * as Yup from 'yup';

const schema = Yup.object({
  first_name: Yup.string().trim().required('Required.'),
  last_name: Yup.string().trim().notRequired(),
  email: Yup.string().trim().email().required('Required.'),
  phone: Yup.string().trim().notRequired()
});

export default schema;
