import React, {useState, useRef} from "react";
import {ProductCardProps} from "./types";
import {makeStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import Divider from "@material-ui/core/Divider";
import ProductInfoDialog from "../ProductInfoDialog";
import ProductForm, {ProductFormRef} from "../../core/forms/ProductForm";
import useProducts from "../../core/hooks/use-products";
import styles from "./styles";

const useStyles = makeStyles(styles);

const ProductCard: React.FC<ProductCardProps> = ({product}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editingDialogOpen, setEditingDialogOpen] = useState(false);
  const classes = useStyles();
  const [, loading, {update: updateProduct}] = useProducts();
  const productForm = useRef<ProductFormRef>(null);

  return (
    <Paper
      className={classes.root}
      onClick={ev => {
        ev.stopPropagation();
        setDialogOpen(true);
      }}
    >
      <Box px={2} py={1} display="flex" alignItems="center">
        <div className={classes.infoContainer}>
          <Typography variant="h6">{product.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {product.part_number}
          </Typography>
        </div>
        <div>
          <IconButton
            edge="end"
            onClick={ev => {
              ev.stopPropagation();
              setEditingDialogOpen(true);
            }}
          >
            <Icon color="action">edit</Icon>
          </IconButton>
        </div>
      </Box>

      <ProductInfoDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        product={product}
        maxWidth="sm"
        fullWidth
        disableBackdropClick={loading}
      />

      {/* BEGIN: Product editing dialog */}
      <Dialog
        open={editingDialogOpen}
        onClose={() => setEditingDialogOpen(false)}
        fullWidth
        maxWidth="sm"
        onClick={ev => ev.stopPropagation()}
        disableBackdropClick={loading}
      >
        <DialogTitle>
          Edit "<b>{product.name}</b>"
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box pt={2} pb={4}>
            <ProductForm
              ref={productForm}
              initialValues={product}
              onSubmit={data =>
                updateProduct(product.id, data).then(
                  updated => !!updated && setEditingDialogOpen(false)
                )
              }
              disabled={loading}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setEditingDialogOpen(false)}
            disabled={loading}
          >
            Cancel
          </Button>
          <Button
            onClick={() => productForm.current?.submitForm()}
            disabled={loading}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default ProductCard;
