
import React, { useState } from 'react';
import { DateTagProps } from './types';
import moment from 'moment/moment';

const DateTag: React.FC<DateTagProps> = props => {
  const {
    date,
    dateInputType = 'default',
    initialMode = 'date-time',
    hideModeSwitch = false
  } = props;

  const [currMode, setCurrMode] = useState(initialMode);

  if(!date)
    return <>{props.children}</>;

  const _moment = (()=>{
    switch(dateInputType){
      case 'unix':
        return moment.unix(date as number);
      case 'utc':
        return moment.utc(date);
      case 'default':
      default:
        return moment(date);
    }
  })();

  const formattedValue = (()=>{
    switch(currMode){
      case 'since-today':
        return moment.duration(_moment.diff(moment())).humanize(true);
      case 'date-time':
      default:
        return _moment.local().format('DD MMM, YYYY [at] hh:mma');
    }
  })();

  if(hideModeSwitch)
    return <>{formattedValue}</>;

  return (
    <span
      onClick={()=>setCurrMode(m=>m==='date-time'?'since-today':'date-time')}
      style={{ cursor: 'pointer' }}
    
    >{currMode === 'date-time' ? <>&#128197;</> : <>&#128337;</>} {formattedValue}</span>
  );
};

export default DateTag;
