import {
  useState,
  useImperativeHandle,
  forwardRef,
  ChangeEvent,
  FormEvent,
} from "react";
import {ViewPermissionsDialogComponent} from "./types";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import {
  ProductionOrder,
  createOrderPermissions,
} from "../../core/system/production-orders";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import useNotify from "../../core/hooks/use-notify";
import {Divider, Switch} from "@material-ui/core";
import LaunchIcon from "@material-ui/icons/Launch";
import {createStyles, Theme, makeStyles} from "@material-ui/core/styles";
import {Link} from "react-router-dom";
import {IS_DEV_MODE} from "../../subapp/utils";

const styles = (theme: Theme) =>
  createStyles({
    sectionTitle: {
      fontSize: "1.2rem",
    },
    switchWrap: {
      width: "100%",
      justifyContent: "space-between",
      marginLeft: "0px",
    },
    switch: {
      padding: "10px",
    },
    divider: {
      margin: "1rem 0",
    },
    modalTitle: {
      paddingBottom: "0",
    },
    modalAction: {
      minWidth: "100px",
    },
  });
const useStyles = makeStyles(styles);

// Define the enum to represent the permissions
enum PermissionType {
  ExtSn = 2,
  Description = 4,
  IntakeDate = 8,
  Quantity = 16,
  ActivityLogs = 64,
  PartNumber = 32,
  Orders = 128,
}

// Create a type for the permissions object
type PermissionsObject = {
  [key in keyof typeof PermissionType]: boolean;
};

// Initialize permissions state as an object with default values
const initialPermissions: PermissionsObject = {
  ExtSn: true,
  Description: false,
  IntakeDate: false,
  Quantity: false,
  ActivityLogs: false,
  PartNumber: false,
  Orders: false,
};

const ViewPermissionsDialog: ViewPermissionsDialogComponent = (props, ref) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openDisclaimer, setOpenDisclaimer] = useState(false);
  const [order, setOrder] = useState<ProductionOrder | null>(null);

  const [permissions, setPermissions] =
    useState<PermissionsObject>(initialPermissions);

  // const [orgLogo, setOrgLogo] = useState<File | null>(null);
  const classes = useStyles();

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, checked} = event.target;
    setPermissions(prev => ({
      ...prev,
      [name]: checked,
    }));
  };

  // Function to convert selected permissions into an array
  const getSelectedPermissions = (): {
    selected: number[];
    removed: number[];
  } => {
    const selectedPermissions: number[] = [];
    const removedPermissions: number[] = [];
    for (const permission in permissions) {
      if (permissions[permission as keyof typeof permissions]) {
        selectedPermissions.push(parseInt(PermissionType[permission]));
      } else {
        removedPermissions.push(parseInt(PermissionType[permission]));
      }
    }
    return {selected: selectedPermissions, removed: removedPermissions};
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        openDialog: order => {
          setOrder(order);
          setDialogOpen(!!order);

          const public_view_permissions = order.public_view_permissions || 0;

          // Check permissions using bitwise operators
          const updatedPermissions: PermissionsObject = {
            ExtSn:
              (public_view_permissions && PermissionType.ExtSn) ===
              PermissionType.ExtSn,
            Description:
              (public_view_permissions & PermissionType.Description) ===
              PermissionType.Description,
            IntakeDate:
              (public_view_permissions & PermissionType.IntakeDate) ===
              PermissionType.IntakeDate,
            Quantity:
              (public_view_permissions & PermissionType.Quantity) ===
              PermissionType.Quantity,
            ActivityLogs:
              (public_view_permissions & PermissionType.ActivityLogs) ===
              PermissionType.ActivityLogs,
            PartNumber:
              (public_view_permissions & PermissionType.PartNumber) ===
              PermissionType.PartNumber,
            Orders:
              (public_view_permissions & PermissionType.Orders) ===
              PermissionType.Orders,
          };

          // Update the permissions state with the updatedPermissions
          setPermissions(updatedPermissions);
        },
      };
    },
    []
  );

  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      await createOrderPermissions(order?.id || "", {
        add: getSelectedPermissions().selected,
        remove: getSelectedPermissions().removed,
      });
      setDialogOpen(false);
      setOpenDisclaimer(false);
      props.setRefetch(true);
      notify("Permissions updated successfully");
    } catch (error) {
      notify(error as Error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle className={classes.modalTitle}>
          Edit View Permission{" "}
        </DialogTitle>
        <form onSubmit={onSubmit}>
          <DialogContent style={{paddingTop: 0}}>
            <Typography variant="body2" gutterBottom>
              Create a custom public view page or check the boxes below to
              enable view permission for these sections
            </Typography>
            {IS_DEV_MODE && (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: "1rem",
                    marginBottom: 16,
                    marginTop: 10,
                  }}
                >
                  <Typography className={classes.sectionTitle} variant="body1">
                    Create Custom Template
                  </Typography>
                  <Button
                    component={Link}
                    to={`/template-builder/${order?.organization_id}/${order?.product_id}/${order?.id}`}
                    endIcon={<LaunchIcon />}
                    variant="outlined"
                    color="secondary"
                  >
                    Take me there
                  </Button>
                </div>
                <Divider className={classes.divider} />
              </>
            )}

            <FormGroup style={{marginBottom: 16}}>
              <Typography
                style={{marginBottom: "-0.3rem"}}
                className={classes.sectionTitle}
                variant="body1"
              >
                Summary Of Order
              </Typography>
              <FormControlLabel
                className={classes.switchWrap}
                labelPlacement="start"
                control={
                  <Switch
                    className={classes.switch}
                    checked={permissions.Orders}
                    name="Orders"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="Let customers see the summary of order"
              />
            </FormGroup>
            <Divider className={classes.divider} />

            <FormGroup style={{marginBottom: 16}}>
              <Typography className={classes.sectionTitle} variant="body1">
                Details Of Individual Items
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.ExtSn}
                    name="ExtSn"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Asset Number"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.PartNumber}
                    name="PartNumber"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Part Number"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.Description}
                    name="Description"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Description"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.IntakeDate}
                    name="IntakeDate"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Intake date"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.Quantity}
                    name="Quantity"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Quantity"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={permissions.ActivityLogs}
                    name="ActivityLogs"
                    onChange={handleCheckboxChange}
                    color="secondary"
                  />
                }
                label="View Activity Logs"
              />
            </FormGroup>

            {/* <label htmlFor="logo">
              <Typography gutterBottom>Add Organisation Logo</Typography>
              <input
                onChange={e => {
                  setOrgLogo(e.target?.files ? e.target?.files[0] : null);
                }}
                type="file"
                accept="image/*"
                id="logo"
              />
            </label> */}
          </DialogContent>
          <DialogActions style={{padding: "10px 24px 24px"}}>
            <Button
              variant="outlined"
              color="secondary"
              type="button"
              className={classes.modalAction}
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              type="button"
              className={classes.modalAction}
              onClick={() => setOpenDisclaimer(true)}
            >
              Save
            </Button>
          </DialogActions>

          {/* warning message / disclaimer modal */}
          <Dialog
            open={openDisclaimer}
            onClose={() => setOpenDisclaimer(false)}
            fullWidth
            maxWidth="xs"
          >
            <DialogTitle style={{paddingBottom: 12}}>Warning </DialogTitle>
            <form>
              <DialogContent style={{paddingTop: 8}}>
                <Typography>
                  Once you save this, it will be{" "}
                  {permissions.Orders ? "available" : "unavailable"} for public
                  view with the permissions you set.
                </Typography>
              </DialogContent>
              <DialogActions style={{padding: "10px 24px"}}>
                <Button
                  variant="outlined"
                  color="secondary"
                  type="button"
                  onClick={() => setOpenDisclaimer(false)}
                >
                  Go Back
                </Button>
                <Button
                  disabled={loading}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  Yes, I understand
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </form>
      </Dialog>
    </>
  );
};

export default forwardRef(ViewPermissionsDialog);
