import {Person, PersonInputData} from "./types";
import randomize from "randomatic";
import orderTrackApi from "../ordertrack-client";
import htmlToPdf, {PdfOptions} from "../utils/html-to-pdf";
import singleLabelsTemplate from "./person-label-templates/person-label-single";
import labelsSheetTemplate from "./person-label-templates/person-labels-sheet";
import largeLabelTemplate from "./person-label-templates/person-label-large";

export const getAllPersons = async () => {
  const res = await orderTrackApi.get("/users", {
    params: {include_inactive: true},
  });
  return res.data.data as Person[];
};

export const getPerson = async (personId: number): Promise<Person> => {
  const res = await orderTrackApi.get(`/users/${personId}`);
  return res.data.data;
};

export const sendPersonInvite = async (email: string): Promise<string> => {
  const res = await orderTrackApi.post("/users/invite", {email});
  return res.data.message;
};

export const updatePerson = async (
  personId: number,
  data: PersonInputData
): Promise<Person> => {
  const res = await orderTrackApi.put(`/users/${personId}`, data);
  return res.data.data;
};

export const deletePerson = async (personId: number) => {
  const res = await orderTrackApi.delete(`/users/${personId}`);
  return res.data.data;
};

export const generatePersonLabels = (
  persons: Person[],
  templateType: "single" | "sheet" | "large"
): Promise<string> => {
  let labelsHtml = "";
  let pdfOptions: PdfOptions = {};

  switch (templateType) {
    case "single": {
      labelsHtml = singleLabelsTemplate(persons);
      pdfOptions = {
        pdf_mode: "landscape",
        pdf_name: `person-labels-${randomize("a0", 12)}`,
        page_width: "66.675mm",
        page_height: "25.4mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
      break;
    }
    case "large": {
      labelsHtml = largeLabelTemplate(persons);
      pdfOptions = {
        pdf_mode: "landscape",
        pdf_name: `person-labels-large`,
        page_height: "150mm",
        page_width: "90mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
      break;
    }
    case "sheet":
    default: {
      labelsHtml = labelsSheetTemplate(persons, 30);
      pdfOptions = {
        pdf_mode: "portrait",
        pdf_name: `person-label-sheets-${randomize("a0", 12)}`,
        page_height: "280mm",
        page_width: "216mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
      break;
    }
  }

  return htmlToPdf(labelsHtml, pdfOptions);
};
