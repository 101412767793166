import { useState } from "react";

const usePagination = (): [
  number,
  number,
  boolean,
  (x: number) => void,
  (x: boolean) => void,
  (x: number) => void,
  () => void
] => {
  const [page, setPage] = useState(1);
  const [availablePages, setAvailablePages] = useState(1);
  const [loadingLogs, setLoadingLogs] = useState(false);

  const getAvailablePages = (totalItems: number) => {
    const availablePages = Math.ceil(totalItems / 50);
    setAvailablePages(availablePages);
  };

  const handleLoadMore = () => {
    setPage((prevState) => prevState + 1);
  };

  return [
    page,
    availablePages,
    loadingLogs,
    setPage,
    setLoadingLogs,
    getAvailablePages,
    handleLoadMore,
  ];
};

export default usePagination;
