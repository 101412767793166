
import React, { useState, useRef } from 'react';
import MainToolbar from '../MainToolbar';
import MainSideNav from '../MainSideNav';
import Layout, { Header, Content, Aside, AsideApi } from '../Layout';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

const MainLayout: React.FC = props => {
  const triggerContainer = useRef(null);
  const mainToolbarSlot = useRef<HTMLDivElement>(null);
  const asideApi = useRef<AsideApi>(null);
  const [isBelow, setIsBelow] = useState(false);

  function handleMenuItemClick() {
    asideApi.current?.collapse();
  }

  const mainSideNavTrigger = (collapsed: boolean) => {
    if(collapsed)
      return <IconButton edge="start"><Icon>menu</Icon></IconButton>;
    else
      return <IconButton edge="start"><Icon>menu_open</Icon></IconButton>;
  };

  const layoutSlots = { 'main-toolbar': ()=>mainToolbarSlot.current };

  return (
    <Layout slotsMap={layoutSlots}>
      <Aside
        ref={asideApi}
        width={256}
        variant="persistent"
        triggerContainer={()=>triggerContainer.current}
        trigger={mainSideNavTrigger}
        onBreakpoint={setIsBelow}
      >
        <MainSideNav onItemClick={isBelow ? handleMenuItemClick : undefined} />
      </Aside>
      <Layout>
        <Header>
          <MainToolbar
            contentStart={<span ref={triggerContainer} />}
            contentRef={mainToolbarSlot}
          />
        </Header>
        <Content>{props.children}</Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
