import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link as RouterLink} from "react-router-dom";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import CropFreeIcon from "@material-ui/icons/CropFree";
import styles from "./styles";
import {Slot} from "../core/components/Layout";
import {Grid} from "@material-ui/core";

const useStyles = makeStyles(styles);

const ScanBin: React.FC = function () {
  const classes = useStyles();

  return (
    <>
      <Slot name="main-toolbar">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Typography variant="h6">Scan Bin QR</Typography>
          </Grid>
          <Grid item xs />
        </Grid>
      </Slot>
      <Container maxWidth={false}>
        <Box py={3} className={classes.thumbnailWrapper}>
          <Link to="/bin/empty" underline="none" component={RouterLink}>
            <Paper elevation={1} className={classes.thumbnailButton}>
              <Typography className={classes.iconWrapper}>
                <CropFreeIcon fontSize="inherit" />
              </Typography>
              <Typography variant="h6" className={classes.thumbnailLabel}>
                Scan empty to auto add
              </Typography>
            </Paper>
          </Link>

          <Link to="/bin/custom-order" underline="none" component={RouterLink}>
            <Paper elevation={1} className={classes.thumbnailButton}>
              <Typography className={classes.iconWrapper}>
                <CropFreeIcon fontSize="inherit" />
              </Typography>
              <Typography variant="h6" className={classes.thumbnailLabel}>
                Scan to order specific amount
              </Typography>
            </Paper>
          </Link>
        </Box>
      </Container>
    </>
  );
};

export default ScanBin;
