import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import {PlaceIconsDialogApi, PlaceIconsDialogProps} from "./types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";
import {styles} from "./types";

const useStyles = makeStyles(styles);
const ICON_BASE_URL =
  "https://replenish-icons.s3.us-west-1.amazonaws.com/manufacture-steps/";
const ICONS = [
  "cable-icon.png",
  "brush.png",
  "circuit-icon.png",
  "drill-icon.png",
  "file-icon.png",
  "microscope-icon.png",
  "quote-icon.png",
  "ribbon-icon.png",
  "search-icon.png",
  "send-icon.png",
  "settings-icon.png",
  "tools.png",
  "truck.png",
  "happy.png",
  "mining-icon.png",
  "time-icon.png",
  "longevity-icon.png",
  "processing.png",
  "packaging.png",
  "farming.png",
  "sewing.png",
  "cooling.png",
  "oven.png",
];

const PlaceIcons: React.ForwardRefRenderFunction<
  PlaceIconsDialogApi,
  PlaceIconsDialogProps
> = ({selectedIcon, setSelectedIcon}, ref) => {
  const [open, setOpen] = useState(false);
  const [initialIcon, setInitialIcon] = useState(selectedIcon);
  useImperativeHandle(ref, () => ({openDialog: () => setOpen(true)}), []);
  const classes = useStyles();

  useEffect(() => {
    if (selectedIcon) {
      setInitialIcon(selectedIcon);
    }
  }, [selectedIcon]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogTitle>Select Location Icon</DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          {ICONS.map(el => (
            <Grid className={classes.grid_item} item xs={6} md={3} key={el}>
              <FormControlLabel
                className={`${classes.icon_checkbox_wrap} ${
                  ICON_BASE_URL + el === initialIcon
                    ? classes.is_icon_selected
                    : ""
                }`}
                control={
                  <Checkbox
                    size="small"
                    checked={ICON_BASE_URL + el === initialIcon}
                    name="ExtSn"
                    onChange={e =>
                      setInitialIcon(e.target.checked ? ICON_BASE_URL + el : "")
                    }
                    color="secondary"
                  />
                }
                label={
                  <Box py={2} style={{textAlign: "center"}}>
                    <img
                      style={{width: 35, height: 35}}
                      src={ICON_BASE_URL + el}
                      alt={el}
                    />
                    <Typography
                      style={{textTransform: "capitalize"}}
                      variant="body2"
                    >
                      {el.split("-icon")[0].replace(".png", "")} Icon
                    </Typography>
                  </Box>
                }
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>

        <Button
          onClick={() => {
            setSelectedIcon(initialIcon);
            setOpen(false);
          }}
          color="secondary"
          variant="contained"
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(PlaceIcons);
