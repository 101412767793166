import {IReportsSection, ITodayReportValue} from "./types";
import randomize from "randomatic";
import PouchDb from "pouchdb-browser";
import PouchdbFind from "pouchdb-find";
import {subscribe} from "../../core/system/pubsub";
import {StationActivityLog} from "../../core/system/reports";
import {DateRangeContext} from "../../core/components/DateRangePicker";

PouchDb.plugin(PouchdbFind);
const db = new PouchDb<IReportsSection>("report_sections");

db.createIndex({
  index: {
    fields: ["org_slug"],
  },
});

let authInfo = {
  org_slug: "default",
};

function updateAuthInfo(data: {org_slug: string} | null) {
  data = !data ? {org_slug: "default"} : {org_slug: data.org_slug};
  Object.assign(authInfo, data);
}

subscribe<{org_slug: string}>("update:authentication", ({data}) =>
  updateAuthInfo(data)
);
db.info().then(function (info) {
  console.log(info);
});

/**
 * ReportsSection factory. Creates a reports section.
 */
export const ReportsSection = (
  data: Partial<IReportsSection> = {}
): IReportsSection => {
  return Object.assign(
    {
      _id: `${new Date().toJSON()}-${randomize("a0", 6)}`,
      title: "New section",
      details: undefined,
      reports: [],
    },
    data,
    authInfo
  );
};

/**
 * Retrieves saved report sections.
 */
export const getReportSections = async (): Promise<IReportsSection[]> => {
  const result = await db.find({
    selector: {
      org_slug: authInfo.org_slug,
    },
    // sort: ['_id']
  });

  return result.docs;
};

/**
 * Saves ReportsSection in database.
 */
export const saveReportsSection = async (
  data: Partial<IReportsSection>
): Promise<IReportsSection> => {
  // If its new section.
  if (!data._id)
    return db.put(ReportsSection(data)).then(res => db.get(res.id));

  const _reportsSection = await db.get(data._id);
  Object.assign(_reportsSection, data);
  return db.put(_reportsSection).then(res => db.get(res.id));
};

/**
 * Removes ReportsSection from database.
 */
export const deleteReportsSection = (reportsSectionId: string) => {
  return db.get(reportsSectionId).then(line => db.remove(line));
};

/**
 * Services for Moved Today Report.
 */
export const getTodayStationLogs = (
  logsArr: StationActivityLog[],
  dateRange: DateRangeContext
) => {
  const todayFrom = dateRange.from.getTime();
  const todayTo = dateRange.to.getTime();

  const filteredLogsArr = logsArr.filter(i => {
    const logDate = new Date(i.arrived_at);
    const logTime = logDate.getTime();
    return logTime > todayFrom && logTime < todayTo;
  });

  return filteredLogsArr.length > 0 ? filteredLogsArr : null;
};

export const getTodayStationQuantity = (arr: StationActivityLog[]) => {
  const quantity = arr.reduce((previousValue, item) => {
    return item.license_plate.quantity + previousValue;
  }, 0);
  return quantity;
};

export const getTodayReportText = (arr: StationActivityLog[]) => {
  const value = arr.reduce((previousObj: ITodayReportValue, item) => {
    const product = item.product.part_number;
    const quantity = item.license_plate.quantity;

    if (!previousObj) return {[product]: quantity};

    const isMentionedProduct = Object.keys(previousObj).find(
      i => i === product
    );
    return isMentionedProduct
      ? {
          ...previousObj,
          [product]: previousObj[product] + quantity,
        }
      : {
          ...previousObj,
          [product]: quantity,
        };
  }, {});

  const reportTextsArray = Object.entries(value).map(i => i.join(": "));
  return reportTextsArray;
};
