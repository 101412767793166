import React from "react";
import {AccordionProps} from "./types";
import Typography from "@material-ui/core/Typography";
import MuiAccordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {useAccordionGroupState} from "../AccordionGroup";

const Accordion: React.FunctionComponent<AccordionProps> = props => {
  const {
    title,
    disableGutters,
    name,
    style,
    titleTypographyProps,
    ...muiAccordionProps
  } = props;

  const {expanded, setExpanded, groupId} = useAccordionGroupState();

  return (
    <MuiAccordion
      {...muiAccordionProps}
      expanded={groupId && name ? expanded === name : undefined}
      onChange={(ev, val) => name && setExpanded(val ? name : null)}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h6"
          // fontWeight={400}
          color="primary"
          {...titleTypographyProps}
        >
          {props.title || "--"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        style={{
          ...style,
          padding: props.disableGutters ? 0 : undefined,
          display: "block",
        }}
      >
        {props.children}
      </AccordionDetails>
    </MuiAccordion>
  );
};

export default Accordion;
