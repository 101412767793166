
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  inactiveUserslistItem: {
    '& .MuiListItemSecondaryAction-root': {
      opacity: 0.15
    },

    '&:hover .MuiListItemSecondaryAction-root': {
      opacity: 1
    }
  }
});

export default styles;
