
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().trim().required('Required.'),
  width: Yup.number().notRequired(),
  height: Yup.number().notRequired(),
  depth: Yup.number().notRequired(),
  unit: Yup.string().oneOf(['METER', 'FEET', 'INCHES']).notRequired()
});

export default schema;
