import React from "react";
import {Slot} from "../core/components/Layout";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataGrid, {ColumnDef} from "../core/components/DataGrid";
import PersonLabelsDialog, {PersonLabelsDialogRef} from "./PersonLabelsDialog";
import PersonInviteDialog from "./PersonInviteDialog";
import PersonActions from "./PersonActions";

import {useRef, useState, useMemo} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {usePersons, Person} from "../core/providers/PersonsProvider";
import styles from "./styles";
import SearchField from "../core/components/SearchField";
import {Avatar} from "@material-ui/core";

const useStyles = makeStyles(styles);

const columnDefinitions: ColumnDef[] = [
  {
    field: "actions",
    headerName: "ACTIONS",
    renderCell: (person: Person) => <PersonActions person={person} />,
    padding: "checkbox",
  },
  {
    field: "first_name",
    headerName: "PERSON",
    renderCell: (person: Person) => {
      return (
        <Box style={{maxWidth: "120px"}} display="flex" alignItems="center">
          <Avatar
            src=""
            alt={`${person?.first_name?.charAt(0)}${person?.last_name?.charAt(
              0
            )}`}
          />
          {/* <Icon fontSize="large">person_outline</Icon> */}
          <Box ml={1}>
            <Typography style={{lineHeight: 1}}>
              {person.first_name || "-"} {person.last_name || "-"}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <i
                style={{
                  width: "140px",
                  minWidth: "100%",
                  wordWrap: "break-word",
                  display: "block",
                  whiteSpace: "pre-wrap",
                }}
              >
                {person.email}
              </i>
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    field: "person_id",
    headerName: "QR CODE",
    renderCell: (person: Person) => (
      <Link
        href={`https://${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
          person.person_id
        }`}
        target="_BLANK"
      >
        {`${process.env.REACT_APP_QR_BASENAME || "3hd.us"}`}/...
        {person.person_id.slice(-5)}
      </Link>
    ),
  },
  // {
  //   field: "role",
  //   headerName: "ROLE",
  //   valueGetter: (person: Person) => person.role?.name.toUpperCase() || "--",
  // },
];

const People: React.FC = () => {
  const personLabelsDialog = useRef<PersonLabelsDialogRef>(null);
  const [personInviteDialogOpen, setPersonInviteDialogOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [resendInviteTo, setResendInviteTo] = useState<string>();
  const [persons, loading] = usePersons();
  const [selection, setSelection] = useState<Person[]>([]);
  const peopleMap = useMemo(() => {
    return persons.reduce(
      (map, p) => {
        switch (p.status) {
          case "ACTIVE":
            map["ACTIVE"].push(p);
            break;
          case "DELETED":
            map["DELETED"].push(p);
            break;
          case "UNCONFIRMED":
          default:
            map["UNCONFIRMED"].push(p);
            break;
        }
        return map;
      },
      {
        DELETED: [] as Person[],
        ACTIVE: [] as Person[],
        UNCONFIRMED: [] as Person[],
      }
    );
  }, [persons]);

  const classes = useStyles();

  // function to filter people by the search input
  const filterPerson = (people: Person[]) => {
    const query = searchQuery.trim().toLowerCase();
    return people.filter(el => {
      const fullName = `${el.first_name} ${el.last_name}`.toLowerCase();
      return [el.first_name, el.last_name, el.email, fullName].some(person =>
        person?.toLowerCase().includes(query)
      );
    });
  };

  const filteredActivePeople = useMemo(() => {
    const query = searchQuery.trim().toLowerCase();
    return peopleMap["ACTIVE"].filter(el => {
      const fullName = `${el.first_name} ${el.last_name}`.toLowerCase();
      return [el.first_name, el.last_name, el.email, fullName].some(person =>
        person?.toLowerCase().includes(query)
      );
    });
  }, [peopleMap, searchQuery]);

  return (
    <Container maxWidth={false}>
      <Slot name="main-toolbar">
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
        >
          <Grid item>
            {loading && <CircularProgress size={24} />}
            {!loading && <Typography variant="h6">People</Typography>}
          </Grid>
          <Grid item>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setPersonInviteDialogOpen(true)}
              disabled={loading}
              style={{marginLeft: "auto", display: "flex"}}
              fullWidth
            >
              <Icon>add</Icon> Add Person
            </Button>
          </Grid>{" "}
        </Grid>
      </Slot>
      <Grid style={{padding: "0 0rem 1rem"}} container alignItems="center">
        <Grid item xs={12} sm={5} md={4}>
          <SearchField
            value={searchQuery}
            onChange={ev => setSearchQuery(ev.target.value)}
            disabled={loading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Paper elevation={1}>
            <Box
              p={2}
              display="flex"
              // justifyContent="space-between"
              alignItems="center"
            >
              <Button
                variant="outlined"
                color="secondary"
                style={{marginRight: "1rem"}}
                size="small"
                startIcon={<Icon>print</Icon>}
                disabled={loading || selection.length === 0}
                onClick={() => personLabelsDialog.current?.openDialog()}
              >
                Get People QR tags
              </Button>
              <Typography variant="h6">
                Active Users{" "}
                <small>({filterPerson(peopleMap["ACTIVE"]).length})</small>
              </Typography>
            </Box>
            <Box>
              <DataGrid
                columns={columnDefinitions}
                rows={filteredActivePeople}
                checkboxSelection
                disableSelectionOnClick
                onSelectionChange={selection =>
                  setSelection(selection as Person[])
                }
              />
            </Box>
          </Paper>

          <Box mt={2}>
            <Paper>
              <Box p={2}>
                <Typography variant="h6">
                  Pending Invites{" "}
                  <small>
                    ({filterPerson(peopleMap["UNCONFIRMED"]).length})
                  </small>
                </Typography>
              </Box>
              <Box>
                <List>
                  {filterPerson(peopleMap["UNCONFIRMED"]).map(person => (
                    <ListItem
                      divider
                      key={person.id}
                      classes={{container: classes.inactiveUserslistItem}}
                    >
                      <ListItemText
                        primary={person.email}
                        secondary={`3hd.us/...${person.person_id.slice(-5)}`}
                        secondaryTypographyProps={{variant: "caption"}}
                      />
                      <ListItemSecondaryAction>
                        <Button
                          onClick={() => setResendInviteTo(person.email)}
                          disabled={loading}
                          size="small"
                          color="secondary"
                          endIcon={<Icon>send</Icon>}
                        >
                          Resend invite
                        </Button>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
                <PersonInviteDialog
                  title="Re-send invitation email"
                  maxWidth="xs"
                  fullWidth
                  disabledInput
                  initialValues={{email: resendInviteTo}}
                  open={!!resendInviteTo}
                  onClose={() => setResendInviteTo(undefined)}
                />
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper elevation={1}>
            <Box p={2}>
              <Typography variant="h6">
                Inactive Users{" "}
                <small>({filterPerson(peopleMap["DELETED"]).length})</small>
              </Typography>
            </Box>
            <Box>
              <List>
                {filterPerson(peopleMap["DELETED"]).map(person => (
                  <ListItem
                    divider
                    key={person.id}
                    classes={{container: classes.inactiveUserslistItem}}
                  >
                    <ListItemText
                      primary={person.email}
                      secondary={`3hd.us/...${person.person_id.slice(-5)}`}
                      secondaryTypographyProps={{variant: "caption"}}
                    />
                    <ListItemSecondaryAction>
                      <Tooltip
                        title="Restore & Reset credentials"
                        placement="left"
                      >
                        <IconButton
                          edge="end"
                          onClick={() => setResendInviteTo(person.email)}
                          disabled={loading}
                        >
                          <Icon>restore_from_trash</Icon>
                        </IconButton>
                      </Tooltip>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <PersonLabelsDialog ref={personLabelsDialog} persons={selection} />

      <PersonInviteDialog
        maxWidth="xs"
        fullWidth
        open={personInviteDialogOpen}
        onClose={() => setPersonInviteDialogOpen(false)}
      />
    </Container>
  );
};

export default People;
