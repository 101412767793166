
import { AuthenticatedPerson, AuthenticationData } from './types';
import apiClient, { authClient } from '../ordertrack-client';

export async function getCurrentPerson() {
  const res = await apiClient.get('/users/me');
  const person: AuthenticatedPerson = res.data.data;
  return person;
}

export const updateCurrentPerson = async (updateData: any): Promise<AuthenticatedPerson> => {
  const res = await apiClient.put('/users/me', updateData);
  return res.data.data;
};

export const updateCurrentPassword = async (password: string) => {
  const res = await apiClient.post('/users/update_password', { password });
  return res.data;
};

export async function loginPerson(email: string, password: string): Promise<AuthenticationData> {
  const formData = new FormData();
  formData.append('email', email);
  formData.append('password', password);

  const res = await authClient.post('/system/login', formData);
  return res.data.data;
}

export const updatePassword = async (password: string): Promise<string> => {
  const response = await apiClient.post('/users/update_password', { password });
  return response.data.data;
}

export const requestPasswordReset = async (email: string): Promise<string> => {
  if(typeof email !== 'string' || !email.trim().length)
    throw new Error('Enter a valid email address.');

  const formData = new FormData();
  formData.append('email', email);

  const response = await authClient.post('/system/forgot', formData);
  return response.data.message || 'Success! Password reset link sent over email.'; // Success message.
}

export const verifyResetToken = async (token: string): Promise<{access_token: string}> => {
  const response = await authClient.get(`/system/reset/${token}`);
  return response.data.data;
}

export const acceptPersonInvite = async (token: string): Promise<string> => {
  const response = await authClient.get(`/system/invite-accept/${token}`);
  return response.data.data;
}
