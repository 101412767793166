
import React from 'react';
import { FirstTimeRightReportProps } from './types';
import { useFirstTimeRight } from '../../core/providers/StationReportsProvider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { useDateRange } from '../../core/components/DateRangePicker';

const chartOptions = {
  chart: {
    plotShadow: false,
    type: 'pie'
  },
  tooltip: {
      pointFormat: '{series.name}: <b>{point.y}</b>'
  },
  accessibility: {
      point: {
          valueSuffix: '%'
      }
  },
  plotOptions: {
      pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
              enabled: true,
              format: '<span style="font-weight: 400;">{point.name}</span>: <b>{point.percentage:.0f} %</b>'
          }
      }
  }
};

const FirstTimeRightReport: React.FC<FirstTimeRightReportProps> = props => {
  const dateRange = useDateRange();
  const ftrReport = useFirstTimeRight(props.placeId, { fromDate: dateRange.from, toDate: dateRange.to });

  return (
    <Box p={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8} md={6}>
          <Box py={2}>
            {!!ftrReport && (
              <HighchartsReact
                highcharts={Highcharts}
                options={{...chartOptions, title: { text: '' }, series: [{
                  name: 'Quantity',
                  colorByPoint: true,
                  type: 'pie',
                  data: [
                    {
                      name: 'First time right',
                      y: ftrReport[0]
                    },
                    {
                      name: 'Rework',
                      y: ftrReport[1]
                    }
                  ]
                }]}}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={6}>
          <List>
            <ListItem divider>
              <ListItemIcon><Icon fontSize="large">assignment_turned_in</Icon></ListItemIcon>
              <ListItemText
                primary={!!ftrReport ? ftrReport[0] : '--'}
                secondary="Items processed right the first time."
                primaryTypographyProps={{ variant: 'h3', color: 'primary' }}
              />
            </ListItem>
            <ListItem>
              <ListItemIcon><Icon fontSize="large">assignment_return</Icon></ListItemIcon>
              <ListItemText
                primary={!!ftrReport ? ftrReport[1] : '--'}
                secondary="Items returned for rework."
                primaryTypographyProps={{ variant: 'h5' }}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstTimeRightReport;
