import React, {useState, useImperativeHandle, useRef, forwardRef} from "react";
import {BinDialogProps, BinDialogRef} from "./types";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import BinForm, {BinFormRef, BinData} from "../core/forms/BinForm";
import useNotify from "../core/hooks/use-notify";

const BinDialog: React.ForwardRefRenderFunction<BinDialogRef, BinDialogProps> =
  function (props, ref) {
    const {
      bin,
      title = "Bin",
      primaryActionText = "Done",
      secondaryActionText = "Close",
      primaryAction = () => {},
      secondaryAction = () => {},
    } = props;

    const binForm = useRef<BinFormRef>(null);
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [editable, setEditable] = useState(false);

    async function handlePrimaryAction(data: BinData) {
      try {
        setLoading(true);
        await primaryAction(data);
        setOpen(false);
      } catch (err) {
        notify(err as Error);
      } finally {
        setLoading(false);
      }
    }

    async function handleSecondaryAction() {
      try {
        setLoading(true);
        await secondaryAction();
        setOpen(false);
      } catch (err) {
        notify(err as Error);
      } finally {
        setLoading(false);
      }
    }

    useImperativeHandle(
      ref,
      () => {
        function openDialog(editable = false) {
          setEditable(editable);
          setOpen(true);
        }

        return {openDialog};
      },
      []
    );

    return (
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>{title}</DialogTitle>
        {!editable && <Divider />}
        <DialogContent>
          <Box pt={2} pb={4}>
            {editable && (
              <BinForm
                ref={binForm}
                initialValues={bin}
                onSubmit={handlePrimaryAction}
                disabled={loading}
              />
            )}

            {!editable && !!bin && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="caption" color="textSecondary">
                    NAME
                  </Typography>
                  <Typography variant="body1">{bin.name}</Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          {loading && <CircularProgress size={24} color="primary" />}

          <Button disabled={loading} onClick={handleSecondaryAction}>
            {secondaryActionText}
          </Button>

          {editable && (
            <Button
              disabled={loading}
              variant="contained"
              color="primary"
              onClick={() => binForm.current?.submitForm()}
            >
              {primaryActionText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  };

export default forwardRef(BinDialog);
