import React, {useState, useCallback, useEffect} from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {DistributionReportProps} from "./types";
import usePlaces from "../../../core/hooks/use-places";
import {
  Box,
  Card,
  CardHeader,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  makeStyles,
} from "@material-ui/core";
import styles from "../styles";

interface Identifiable {
  id: number;
}

function extractDataByIds<T extends Identifiable>(
  largeArray: T[],
  idArray: number[]
): T[] {
  const idSet = new Set(idArray);
  return largeArray.filter(item => idSet.has(item.id));
}

const useStyles = makeStyles(styles);

const DistributionReport: React.FC<DistributionReportProps> = props => {
  const [places, loading] = usePlaces();
  const classes = useStyles();

  const fullCategories = extractDataByIds(
    places,
    props.stations.map(el => el.id)
  );
  const categories = fullCategories.map(el => el.name);

  const data = fullCategories.map(el => el?.lp_qty);

  const [selectedIndices, setSelectedIndices] = useState<number[]>(
    Array.from(Array(categories.length).keys())
  );

  const updateSelectedIndices = useCallback((index: number) => {
    setSelectedIndices(prev => {
      if (prev.includes(index)) {
        return prev.filter(i => i !== index);
      } else {
        return [...prev, index];
      }
    });
  }, []);

  const chartOptions = {
    chart: {
      type: "column",
    },
    title: {
      text: "",
    },
    xAxis: {
      categories: selectedIndices.map(i => categories[i]),
      crosshair: true,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Number of items",
      },
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [
      {
        name: "Stations",
        data: selectedIndices.map(i => data[i]),
        colorByPoint: true,
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            "downloadPNG",
            "downloadSVG",
            "separator",
            "downloadCSV",
            "downloadXLS",
          ],
        },
      },
    },
  };

  useEffect(() => {
    setSelectedIndices(Array.from(Array(categories.length).keys()));
  }, [categories.length]);

  return (
    <Card
      style={{
        background: "white",
        padding: "1rem",
      }}
      className={classes.root}
    >
      <CardHeader title={`Location Distribution Report`} />
      {loading ? (
        <Box
          style={{display: "flex", height: "100px"}}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <div id="container" style={{width: "100%", height: "400px"}}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
          </div>
          <div id="controls">
            <h3>Select Locations:</h3>
            {categories.map((category, index) => (
              <FormControlLabel
                key={category + index}
                control={
                  <Checkbox
                    name="Stations"
                    value={index}
                    checked={selectedIndices.includes(index)}
                    onChange={() => updateSelectedIndices(index)}
                    color="secondary"
                  />
                }
                label={category}
              />
            ))}
          </div>
        </>
      )}
    </Card>
  );
};

export default DistributionReport;
