import {TemplateComponent} from "../_base/components/TemplateParser";

export const BUTTON_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "Button",
    children: "Button Text",
    props: {
      component: "a",
      href: "https://www.momenttrack.com/electronics/",
      size: "large",
      target: "_BLANK",
      variant: "contained",
      color: "primary",
      style: {
        textTransform: "uppercase",
      },
    },
  },
  props: {
    p: 2,
    textAlign: "center",
  },
};

export const HEADER_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "Image",
    props: {
      alt: "Default Image",
      src: "https://www.momenttrack.com/assets/images/favicon-white-13256da2d2.png",
      style: {
        maxWidth: "70%",
        maxHeight: "170px",
        margin: "auto",
      },
    },
  },
  props: {
    bgcolor: "primary.main",
    style: {
      padding: "1rem",
      display: "flex",
    },
  },
};

export const IMAGE_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "Image",
    props: {
      alt: "Default Image",
      src: "https://www.momenttrack.com/assets/images/electronics-banner-1463e0162b.jpg",
    },
  },
  props: {
    bgcolor: "#ffffff",
    style: {
      display: "inline-block",
      width: "100%",
    },
  },
};

export const ACCORDION_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "Accordion",
    children: [],
    props: {
      name: "accordion",
      title: "Accordion Title",
    },
  },
};

export const VIDEO_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "iframe",
    props: {
      width: "100%",
      frameBorder: 0,
      title: "This is a demo video",
      src: "https://www.youtube.com/embed/XTSAi5Cnbi0",
      height: "250px",
    },
  },
};

export const TYPOGRAPHY_DEFAULT: TemplateComponent = {
  type: "Box",
  children: {
    type: "Typography",
    children: "Lorem Ipsum",
    props: {
      style: {
        margin: "0.6rem 0",
        textAlign: "left",
      },
      variant: "h4",
    },
  },
};

export const SOCIALS_DEFAULT: TemplateComponent = {
  type: "Box",
  children: [
    {
      type: "SocialIcons",
      props: {
        fontSize: "1.35rem",
        icons: {
          youtube:
            "https://www.youtube.com/playlist?list=PLj9hU1O4UB02b4ePoWE2dbA7t8zuUPfmQ",
          twitter: "https://twitter.com/Signifyusa",
          instagram: "https://instagram.com/PhilipsLightUS",
          linkedin: "https://www.linkedin.com/company/signifycompany/",
          rss: "https://www.signify.com/en-us/blog",
          facebook: "https://www.facebook.com/PhilipsLightUs",
        },
        title: "Connect with Us",
        mb: 4,
      },
    },
  ],
  props: {
    px: 2,
    py: 4,
    textAlign: "center",
  },
};

export const FOOTER_DEFAULT: TemplateComponent = {
  type: "Box",
  children: [
    {
      type: "SocialIcons",
      props: {
        fontSize: "1.35rem",
        icons: {
          youtube: "username",
          twitter: "username",
          instagram: "username",
          linkedin: "username",
          facebook: "username",
        },
        title: "Connect with Us",
        mb: 3,
      },
    },
    {
      type: "Image",
      props: {
        alt: "Default Image",
        src: "https://www.momenttrack.com/assets/images/favicon-white-13256da2d2.png",
        style: {
          width: "50%",
        },
      },
    },
  ],
  props: {
    bgcolor: "primary.main",
    style: {
      padding: "2rem 1rem",
      textAlign: "center",
    },
  },
};
