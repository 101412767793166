// vendorsContext.ts
import {createContext} from "react";
import {BinFamilyContext} from "./types"; // Define an appropriate type for VendorsContext

const context = createContext<BinFamilyContext>([
  {binFamilies: [], bins: []},
  false,
  "",
  {
    // getById: async () => null,
    createBinFam: async () => null,
    editBinFamily: async () => null,
    deleteById: async () => false,
    createBin: async () => null,
    generateLabels: async () => "",
    fetchAllBins: async () => null,
  },
]);

export default context;
