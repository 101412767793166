import React, {useRef, useState} from "react";
import randomize from "randomatic";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import useBinFamily from "../core/hooks/use-bin-families";
import useNotify from "../core/hooks/use-notify";
import useSearchFilter from "../core/hooks/use-search-filter";
import SearchField from "../core/components/SearchField";
import EmbeddedContentDialog, {
  EmbeddedContentDialogApi,
} from "../core/components/EmbeddedContentDialog";
import {BinFamily, BinFamilyInputData} from "../core/system/bin-families";
import {BinFamilyDialogRef} from "./types";
import BinFamilyDialog from "./BinFamilyDialog";
import DataGrid, {ColumnDef, RowData} from "../core/components/DataGrid";
import {
  Link,
  CircularProgress,
  Typography,
  Button,
  Icon,
  Hidden,
  IconButton,
  Paper,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Layout, {Header, Content, Slot} from "../core/components/Layout";
import DropDownWrapper from "../core/components/DropDownWrapper";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useConfirmations from "../core/hooks/use-confirmations";
import {Link as RouterLink} from "react-router-dom";
import VisibilityIcon from "@material-ui/icons/Visibility";

const BinFamiliesPage: React.FC = function () {
  // const [filters, setFilters] = useState<FiltersMap>({ place: '', vendor: '' });
  const [{binFamilies}, loading, , binFamiliesService] = useBinFamily();
  const [searchTerm, setSearchTerm] = useState("");
  const searchedBinFamilies = useSearchFilter(
    binFamilies,
    "product",
    searchTerm
  );
  const binFamilyDialog = useRef<BinFamilyDialogRef>(null);
  const editBinFamilyDialog = useRef<BinFamilyDialogRef>(null);
  const pdfLinkDialog = useRef<EmbeddedContentDialogApi>(null);
  const notify = useNotify();
  const [selection, setSelection] = useState<BinFamily[]>([]);
  const [editData, seteditData] = useState<BinFamily>();

  async function handleBinFamilyCreate(data: BinFamilyInputData) {
    const binFamily = await binFamiliesService.createBinFam(data);

    notify("Creating bins...");
    if (binFamily) {
      const randomString = randomize("A0", 4);
      await Promise.all([
        binFamiliesService.createBin({
          name: `BIN A-${binFamily.id}-${randomString}`,
          bin_family_id: binFamily.id,
        }),
        binFamiliesService.createBin({
          name: `BIN B-${binFamily.id}-${randomString}`,
          bin_family_id: binFamily.id,
        }),
      ]);
      notify("Generating labels...");
      pdfLinkDialog.current?.openDialog(() =>
        binFamiliesService.generateLabels(
          [binFamily as BinFamily],
          "sheet",
          true
        )
      );
      notify("New bin family created.");
    }
  }
  const [generatingLabels, setGeneratingLabels] = useState(false);
  async function handleGenerateLables(templateType: "single" | "sheet") {
    try {
      if (!selection.length)
        throw new Error(
          "Please select one or more bin families to print labels for."
        );

      setGeneratingLabels(true);
      pdfLinkDialog.current?.openDialog(() =>
        binFamiliesService.generateLabels(selection, templateType)
      );
      setSelection([]);
    } catch (err) {
      notify(err as Error);
    } finally {
      setGeneratingLabels(false);
    }
  }

  const handleSelection = (selection: RowData[]) => {
    setSelection(selection as BinFamily[]);
  };

  async function handleBinFamilySave(data: BinFamilyInputData) {
    if (data?.id) {
      await binFamiliesService.editBinFamily(data?.id, data);
      notify("Bin family updated.");
    }
  }

  const confirm = useConfirmations();
  async function handleBinFamilyDelete(data: BinFamily) {
    try {
      if (
        await confirm(
          `Are you sure? You are about to delete bin family "${data.product.name}". This action cannot be undone.`
        )
      ) {
        await binFamiliesService.deleteById(data.id);
      } else return;
    } catch (err) {
      notify(err as Error);
    }
  }

  const columns: ColumnDef[] = [
    {
      field: "product",
      headerName: "PRODUCT",
      sortable: true,
      padding: "checkbox",
      valueGetter: (data: BinFamily) => data.product.name,
    },
    {
      field: "qty",
      headerName: "DEFAULT QTY",
      valueGetter: (data: BinFamily) => `${data.default_quantity}`,
    },
    {
      field: "place",
      headerName: "PLACE",
      sortable: true,
      padding: "checkbox",
      renderCell: (data: BinFamily) => (
        <Link
          href={`https://${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
            data.location.beacon_id
          }`}
          target="_BLANK"
          variant="body1"
        >
          {data.location.name}
        </Link>
      ),
    },
    {
      field: "vendor",
      headerName: "VENDOR",
      valueGetter: (data: BinFamily) => `${data?.preferred_vendor?.name || ""}`,
    },
    {
      field: "actions",
      headerName: " ",
      padding: "checkbox",
      renderCell: (data: BinFamily) => (
        <DropDownWrapper
          className="more-actions"
          action={
            <IconButton className="more-action-btn" aria-label="actions">
              <MoreVertIcon />
            </IconButton>
          }
        >
          <Link
            underline="none"
            noWrap
            color="textPrimary"
            component={RouterLink}
            to={`/bin-families/${data.id}`}
            style={{display: "flex", padding: "6px 8px"}}
          >
            <VisibilityIcon fontSize="small" />
            View
          </Link>
          <Button
            variant="text"
            type="button"
            onClick={() => {
              seteditData(data);
              editBinFamilyDialog.current?.openDialog(true);
            }}
          >
            <EditIcon fontSize="small" />
            Edit
          </Button>
          <Button
            variant="text"
            type="button"
            onClick={() => handleBinFamilyDelete(data)}
          >
            <DeleteIcon fontSize="small" />
            Delete
          </Button>
        </DropDownWrapper>
      ),
    },
  ];

  return (
    <>
      <Slot name="main-toolbar">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            {loading && <CircularProgress size={24} />}
            {!loading && <Typography variant="h6">Bin Families</Typography>}
          </Grid>
          <Grid item xs />

          <Grid item>
            <Button
              onClick={() => binFamilyDialog.current?.openDialog(true)}
              disabled={loading}
              color="secondary"
              variant="contained"
            >
              <Icon>add</Icon> Add Bin Family
            </Button>
          </Grid>
        </Grid>
      </Slot>

      <Layout>
        <Header>
          <Container maxWidth={false}>
            <SearchField
              style={{marginBottom: "1rem"}}
              value={searchTerm}
              onChange={ev => setSearchTerm(ev.target.value)}
              disabled={loading}
            />
            <Paper>
              <Box px={2} py={1}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Hidden xsDown>
                      <Button
                        color="secondary"
                        startIcon={<Icon>print</Icon>}
                        onClick={() => handleGenerateLables("sheet")}
                        disabled={
                          loading || generatingLabels || !selection.length
                        }
                      >
                        Get labels
                      </Button>
                    </Hidden>
                    <Hidden smUp>
                      <IconButton
                        edge="start"
                        color="secondary"
                        onClick={() => handleGenerateLables("sheet")}
                        disabled={
                          loading || generatingLabels || !selection.length
                        }
                      >
                        <Icon>print</Icon>
                      </IconButton>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <b>{selection.length || "No"}</b> rows selected.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </Header>
        <Content>
          <Container maxWidth={false} style={{height: "calc(100% - 72px)"}}>
            <DataGrid
              rows={searchedBinFamilies}
              columns={columns}
              windowHeight="100%"
              checkboxSelection
              loading={loading}
              disableSelectionOnClick
              onSelectionChange={handleSelection}
            />
          </Container>
        </Content>
      </Layout>

      <EmbeddedContentDialog ref={pdfLinkDialog} title="Production orders" />

      <BinFamilyDialog
        ref={binFamilyDialog}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleBinFamilyCreate}
      />
      <BinFamilyDialog
        ref={editBinFamilyDialog}
        primaryActionText="Update"
        secondaryActionText="Close"
        binFamily={editData}
        primaryAction={handleBinFamilySave}
      />
    </>
  );
};

export default BinFamiliesPage;
