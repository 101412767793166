import React, { useEffect, useRef } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import useChartSvg from "../../hooks/use-chart-svg";

const chartOptions = {
  chart: {
    type: "bar",
    height: 300,
  },
  title: {
    text: "Stacked Bar Chart",
  },
  tooltip: {
    pointFormat: "{series.name}: <b>{point.y}</b>",
  },
  plotOptions: {
    series: {
      stacking: "normal",
    },
  },
  accessibility: {
    point: {
      valueSuffix: "%",
    },
  },
};

const StackedBarChart = function (props) {
  const chartComponent = useRef(null);
  const [chartSvg] = useChartSvg(chartComponent.current?.chart);

  const options = {
    ...chartOptions,
    title: { text: props.title },
    series: props.series,
    ...props?.options,
  };

  useEffect(() => {
    if (!chartSvg || !props.getSvg) return;
    props.getSvg(chartSvg);
    // eslint-disable-next-line
  }, [chartSvg]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponent}
    />
  );
};

export default StackedBarChart;
