
import { AuthenticatedPerson } from '../../system/authentication';
import { createStore } from '../../system/store';
const store = createStore('pt_auth::');

const ACCESS_TOKEN_KEY = 'access_token';
const REFRESH_TOKEN_KEY = 'refresh_token';
const ORG_SLUG_KEY = 'org_slug';
const CURR_PERSON_KEY = 'curr_person';
const DATA_VALIDITY_DURATION = 1000 * 60 * 60 * 12; // == 12 hours.

export const getToken = () => store.get<string>(ACCESS_TOKEN_KEY);
export const getRefreshToken = () => store.get<string>(REFRESH_TOKEN_KEY);
export const getOrganization = () => store.get<string>(ORG_SLUG_KEY);
export const getCurrPerson = () => {
  const data = store.get<{data: AuthenticatedPerson; timestamp: number}>(CURR_PERSON_KEY);
  if(!data) return null;
  
  const { data: currPerson, timestamp = 0 } = data;
  if(Date.now() - timestamp >= DATA_VALIDITY_DURATION ) return null;
  else return currPerson;
};

export const setToken = (token: string | null) => store.set(ACCESS_TOKEN_KEY, token);
export const setRefreshToken = (token: string | null) => store.set(REFRESH_TOKEN_KEY, token);
export const setOrganization = (org_slug: string | null) => store.set(ORG_SLUG_KEY, org_slug);
export const setCurrPerson = (person: AuthenticatedPerson | null) => {
  store.set(CURR_PERSON_KEY, (!!person ? { data: person, timestamp: Date.now() } : null));
}

export const clearAll = () => store.clear();
