import {createStyles, Theme} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    searchInput: {
      "& .MuiOutlinedInput-root": {
        borderRadius: 100,
        paddingLeft: 45,
      },
      "& input": {
        ...theme.typography.h5,
      },
    },
    qtyInput: {
      "& input": {
        ...theme.typography.h5,
      },
    },
    inputAdornmentRight: {
      position: "absolute",
      right: "1rem",
      top: "0.55rem",
    },
    inputAdornmentLeft: {
      position: "absolute",
      left: "1rem",
      top: "1.2rem",
    },
  });

export default styles;
