import {createStyles, Theme} from "@material-ui/core/styles";

const styles = (theme: Theme) =>
  createStyles({
    listItem: {
      color: theme.palette.text.primary,
      borderRadius: "0 100px 100px 0",
      opacity: 0.5,
      "&:hover": {
        opacity: 1,
        background: `linear-gradient(to left, ${theme.palette.grey["300"]}, ${theme.palette.grey["100"]})`,
        "& $listItemIcon": {opacity: 1, transform: `scale(1.4)`},
      },
    },
    listItemChild: {
      // // Styles for sub menu item
      // "&$activeNavListItem": {
      //   "& $listItemText": {
      //     borderBottom: 0,
      //   },
      // },
      "& $listItemIcon": {
        minWidth: "35px",
      },
    },
    listItemText: {},
    listItemIcon: {
      color: "inherit",
      // justifyContent: 'flex-end',
      opacity: 0.6,
      fontSize: "1.5rem",
      transition: "all 200ms",
    },
    activeNavListItem: {
      opacity: 1,
      color: theme.palette.common.black,
      "&:hover": {background: theme.palette.grey["100"]},
      "& $listItemIcon": {
        opacity: 1,
        transform: `scale(1.25)`,
      },
      "& $listItemText": {
        borderBottom: `1px solid`,
      },
    },
  });

export default styles;
