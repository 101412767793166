
import React, { useState, useEffect, useRef, FormEvent } from 'react';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Countdown, { CountdownRef } from '../core/components/Countdown';
import useNotify from '../core/hooks/use-notify';
import { requestPasswordReset } from '../core/system/authentication';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [requestSent, setRequestSent] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const timer = useRef<CountdownRef>(null);

  async function handleSubmit(ev: FormEvent) {
    try{
      ev.preventDefault();
      setLoading(true);

      const message = await requestPasswordReset(email);
      setRequestSent(true);
      setWaiting(true);
      notify(message);
    }
    catch(err: any){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    if(waiting)
      timer.current?.start();

  }, [waiting]);

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column" justifyContent="center">
      <Container maxWidth="xs">
        <Paper>
          {loading && <LinearProgress />}
          <Box p={3}>
            <Typography variant="h6" gutterBottom>Forgot password?</Typography>
            {!requestSent && <Typography variant="body2" color="textSecondary">
              Enter your email address and hit continue to get password reset link.
            </Typography>}
            {requestSent && <Typography variant="body1" color="textSecondary">
              A password reset link has been sent to your email address.
            </Typography>}
          </Box>

          <form onSubmit={handleSubmit}>
            <Box px={3} pt={3} pb={6}>
              {!requestSent && (
                <TextField
                  label="Email address"
                  type="email"
                  value={email}
                  onChange={ev=>setEmail(ev.target.value)}
                  disabled={loading}
                  fullWidth
                  autoFocus
                />
              )}
              {requestSent && (
                <>
                  <Typography variant="subtitle2" color="textSecondary" gutterBottom>Email address</Typography>
                  <Typography variant="body1" color="primary">{ email }</Typography>
                </>
              )}
            </Box>

            <Box p={3}>
              <Grid container spacing={2} alignItems="center" justify="flex-end">
                <Grid item>
                  <Typography>
                    <Link
                      component={RouterLink}
                      to="/login"
                      color="textPrimary"
                      variant="body1"
                      underline="always"
                    >
                      back to login
                    </Link>
                  </Typography>
                </Grid>
                <Grid item>
                  {!requestSent && (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={loading || waiting || email.length === 0}>Send link</Button>
                  )}
                  {requestSent && (
                    <Button type="submit" variant={waiting ? 'outlined' : 'contained'} color="primary" disabled={loading || waiting}>
                      Re-send {waiting && (
                        <Countdown
                          ref={timer}
                          startValue={30}
                          onEnd={()=>setWaiting(false)}
                          textBefore="("
                          textAfter="s)"
                        />
                      )}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default ForgotPassword;
