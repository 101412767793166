import { createStyles } from "@material-ui/core/styles";

const styles = () =>
  createStyles({
    title: {
      marginBottom: "15px",
      fontSize: "36px",
    },
    text: {
      textAlign: "center",
      fontSize: "36px",
    },
  });

export default styles;
