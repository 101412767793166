// BinFamiliesProvider.tsx
import React, {useState, useLayoutEffect, useCallback} from "react";
import context from "./context";
import useNotify from "../../hooks/use-notify";
import {useAuthentication} from "../AuthenticationProvider";
import {
  Bin,
  BinFamily,
  BinFamilyInputData,
  BinInputData,
  getAllBinFamilies,
} from "../../system/bin-families";
import {
  createBinFamily,
  createBinService,
  deleteBinFamily,
  getAllBins,
  updateBinFamily,
} from "../../system/bin-families/services";
import publicApiClient from "../../system/ordertrack-client/public-api-client";
import binLabelsTemplate from "../../system/bin-families/bin-labels";
import binLabelsSheetTemplate from "../../system/bin-families/bin-labels-sheet";
import {generateUrl} from "../../system/utils/parse-momenttrack-id";

const BinFamiliesProvider: React.FC = ({children}) => {
  const authenticated = !!useAuthentication()[0];
  const [binFamilies, setbinFamilies] = useState<BinFamily[]>([]);
  const [bins, setBins] = useState<Bin[]>([]);
  const [loading, _setLoading] = useState(0);
  const [message, setMessage] = useState<Error | string>("");
  const notify = useNotify();

  const setLoading = (increment: boolean) =>
    _setLoading(prevVal => (increment ? ++prevVal : --prevVal));

  const fetchAllBinFamilies = useCallback(async () => {
    try {
      setLoading(true);
      const res = await getAllBinFamilies();
      // res.sort((a, b) => a.product.name.localeCompare(b.product.name));
      setMessage(res.length === 0 ? "Nothing found." : "");
      setbinFamilies(res);
    } catch (err) {
      notify(err as Error);
      setMessage("Failed to load bin families.");
    } finally {
      setLoading(false);
    }
  }, [notify]);

  const fetchAllBins = useCallback(async (): Promise<Bin[] | null> => {
    try {
      setLoading(true);
      const res = await getAllBins();
      setMessage(res.length === 0 ? "Nothing found." : "");
      setBins(res);
      return res;
    } catch (err) {
      notify(err as Error);
      setMessage("Failed to load bins.");
      return null;
    } finally {
      setLoading(false);
    }
  }, [notify]);

  const createBinFam = useCallback(
    async (data: BinFamilyInputData): Promise<BinFamily | null> => {
      try {
        setLoading(true);
        const res = await createBinFamily(data);
        setbinFamilies(prevState => [res, ...prevState]);
        setLoading(false);
        return res;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return null;
      }
    },
    [notify]
  );

  const createBin = useCallback(
    async (data: BinInputData): Promise<Bin | null> => {
      try {
        setLoading(true);
        const res = await createBinService(data);
        setBins(prevState => [res, ...prevState]);
        setLoading(false);
        return res;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return null;
      }
    },
    [notify]
  );

  const editBinFamily = useCallback(
    async (itemId: number, updatedData: BinFamilyInputData) => {
      try {
        setLoading(true);
        const res = await updateBinFamily(itemId, updatedData);
        setbinFamilies(prev => {
          const index = prev.findIndex(v => v.id === itemId);
          if (index >= 0) prev.splice(index, 1, res);
          return [...prev];
        });
        setLoading(false);
        notify(`Updated "${res.product.name}".`);
        return res;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return null;
      }
    },
    [notify]
  );

  const deleteById = useCallback(
    async (itemId: number) => {
      try {
        setLoading(true);
        await deleteBinFamily(itemId);
        setbinFamilies(vendors => vendors.filter(v => v.id !== itemId));
        setLoading(false);
        notify(`Deleted bin family with ID ${itemId}.`);
        return true;
      } catch (err: any) {
        setLoading(false);
        notify(err as Error);
        return false;
      }
    },
    [notify]
  );

  const generateLabels = async (
    binFamilies: BinFamily[],
    templateType: "single" | "sheet" = "sheet",
    refetchBins?: boolean
  ) => {
    let allBins = await fetchAllBins();

    const binsMap = (allBins || bins).reduce((map, bin, i) => {
      map[bin.bin_family_id] = map[bin.bin_family_id] || [];
      map[bin.bin_family_id].push(bin);
      return map;
    }, {} as {[key: number]: Bin[]});

    let labelsData: any = [];

    binFamilies.forEach(binFamily => {
      const bins = binsMap[binFamily.id];
      if (!bins) return;
      bins.forEach((bin, i) => {
        const url = generateUrl(bin.id, "document");

        labelsData.push({
          type: `Bottom ${i === 0 ? "A" : "B"} - Reorder`,
          url,
          iconUrl:
            "https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/document.svg",
          part_number: binFamily.product.part_number,
          product_name: binFamily.product.name,
        });

        labelsData.push({
          type: `Front ${i === 0 ? "A" : "B"} - Reorder`,
          url,
          iconUrl:
            "https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/product.svg",
          part_number: binFamily.product.part_number,
          product_name: binFamily.product.name,
        });
      });
    });

    let requestData;

    if (templateType === "single") {
      requestData = {
        html: binLabelsTemplate(labelsData),
        pdf_mode: "portrait",
        pdf_name: "bin-labels",
        page_width: "66.675mm",
        page_height: "25.4mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
    } else {
      const singleSheetLabelsCount = 10;
      const sheetsCount = Math.ceil(labelsData.length / singleSheetLabelsCount);
      const sheets: any = [];

      for (let i = 0; i < sheetsCount; i++) {
        const startIndex = i * singleSheetLabelsCount;
        const endIndex = startIndex + singleSheetLabelsCount;
        sheets.push(labelsData.slice(startIndex, endIndex));
      }

      requestData = {
        html: binLabelsSheetTemplate(sheets),
        pdf_mode: "portrait",
        pdf_name: "bin-labels",
        // page_size: "Letter",
        page_width: "8.5in",
        page_height: "11in",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
    }

    const response = await publicApiClient.post(
      process.env.REACT_APP_HTML_2_PDF_URL || "",
      requestData
    );
    // await momentTrackService.printPdf(response.data.data?.s3_path, 'Bin family labels from ordertrack');
    return response.data?.s3_path;
  };

  useLayoutEffect(() => {
    if (!authenticated) return;
    let active = true;
    (async () => {
      try {
        await fetchAllBinFamilies();
      } catch (err: any) {
        if (!active) return;
        notify(err as Error);
      }
    })();
    return () => {
      active = false;
    };
  }, [notify, authenticated, fetchAllBinFamilies]);

  useLayoutEffect(() => {
    if (!authenticated) return;
    let active = true;
    (async () => {
      try {
        await fetchAllBins();
      } catch (err: any) {
        if (!active) return;
        notify(err as Error);
      }
    })();
    return () => {
      active = false;
    };
  }, [notify, authenticated, fetchAllBins]);

  return (
    <context.Provider
      value={[
        {binFamilies, bins},
        !!loading,
        message,
        {
          createBinFam,
          editBinFamily,
          deleteById,
          createBin,
          generateLabels,
          fetchAllBins,
        },
      ]}
    >
      {children}
    </context.Provider>
  );
};

export default BinFamiliesProvider;
