
import React, { useRef, FormEvent } from 'react';
import { SearchFieldProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import styles from './styles';

const useStyles = makeStyles(styles);

const SearchField: React.FC<SearchFieldProps> = props => {
  const {
    onSearch,
    inputRef,
    disabled,

    ...inputBaseProps
  } = props;

  const _inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();
    onSearch && onSearch(_inputRef.current?.value || '');
  };

  const classes = useStyles();

  return (
    <form onSubmit={handleSubmit}>
      <Paper elevation={0} className={classes.root}>
        <InputBase
          inputRef={_inputRef}
          className={classes.inputBase}
          fullWidth
          disabled={disabled}
          placeholder="Search..."
          {...inputBaseProps}
        />
        <Fab type="submit" size="medium" color="secondary" disabled={disabled}>
          <Icon>search</Icon>
        </Fab>
      </Paper>
    </form>
  );
};

export default SearchField;
