import React from "react";
import {SocialShareButtonsProps} from "./types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "next-share";

import {useMemo} from "react";

const SocialShareButtons: React.FC<SocialShareButtonsProps> = props => {
  const {title, buttons, url, text, hashtag} = props;

  const buttonsList = useMemo(() => {
    if (!Array.isArray(buttons)) return [];

    return buttons.reduce((btns, btnType, i) => {
      switch (btnType) {
        case "facebook":
          btns.push(
            <FacebookShareButton
              key={`${btnType}-${i}`}
              style={{margin: "0 2px"}}
              url={url || ""}
              quote={text || ""}
              hashtag={hashtag}
            >
              <FacebookIcon round size={42} />
            </FacebookShareButton>
          );
          break;
        case "twitter":
          btns.push(
            <TwitterShareButton
              key={`${btnType}-${i}`}
              style={{margin: "0 2px"}}
              url={url || ""}
              title={text || ""}
              hashtags={hashtag ? [hashtag] : undefined}
            >
              <TwitterIcon round size={42} />
            </TwitterShareButton>
          );
          break;
        case "whatsapp":
          btns.push(
            <WhatsappShareButton
              key={`${btnType}-${i}`}
              style={{margin: "0 2px"}}
              url={url || ""}
              title={text || ""}
            >
              <WhatsappIcon round size={42} />
            </WhatsappShareButton>
          );
          break;
        case "email":
          btns.push(
            <EmailShareButton
              key={`${btnType}-${i}`}
              style={{margin: "0 2px"}}
              url={url || ""}
              body={text || ""}
            >
              <EmailIcon round size={42} />
            </EmailShareButton>
          );
          break;
        default:
          break;
      }
      return btns;
    }, [] as JSX.Element[]);
  }, [buttons, url, text, hashtag]);

  return (
    <Box textAlign="center">
      {!!title && (
        <Typography component="p" variant="overline">
          {title}
        </Typography>
      )}
      {buttonsList}
    </Box>
  );
};

export default SocialShareButtons;
