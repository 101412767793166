
import React, { useState } from 'react';
import { Confirmation, ConfirmFunction } from './types';
import context from './context';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const generateId = (()=>{
  let id = 0;
  return ()=>++id;

})();

const ConfirmationsProvider: React.FC = props => {
  const [confirmations, setConfirmations] = useState<Confirmation[]>([]);

  const confirm: ConfirmFunction = message => new Promise<boolean>(resolve=>{
    setConfirmations(confirmations => [...confirmations, {
      id: generateId(),
      message,
      open: true,
      respond: resolve
    }]);
  });

  const closeAndRespond = (confirmation: Confirmation, response: boolean) => {
    confirmation.respond(response);
    setConfirmations(confirmations=>confirmations.filter(c=>c.id !== confirmation.id));
  };

  return (
    <context.Provider value={confirm}>
      {props.children}

      {confirmations.map(confirmation=>(
        <Dialog
          key={confirmation.id}
          open
          onClose={()=>closeAndRespond(confirmation, false)}
        >
          <DialogTitle title="Confirm" />
          <DialogContent>
            {typeof confirmation.message === 'string' && (
              <DialogContentText>{confirmation.message}</DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={()=>closeAndRespond(confirmation, false)}

            >Cancel</Button>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>closeAndRespond(confirmation, true)}
            
            >Ok</Button>
          </DialogActions>
        </Dialog>
      ))}
    </context.Provider>
  );
};

export default ConfirmationsProvider;
