import React from "react";
import {DateRange, DateRangeProviderProps} from "./types";
import dateRangeContext from "./context";

import {useState} from "react";

const DateRangeProvider: React.FC<DateRangeProviderProps> = props => {
  const [range, setRange] = useState<DateRange>(
    props.initialValue || {
      from: new Date(0),
      to: new Date(),
    }
  );

  return (
    <dateRangeContext.Provider
      value={{
        ...range,
        update: (from, to) =>
          setRange(currRange => ({
            from: from || currRange.from,
            to: to || currRange.to,
          })),
      }}
    >
      {props.children}
    </dateRangeContext.Provider>
  );
};

export default DateRangeProvider;
