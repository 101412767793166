import React from "react";
import { StationOverviewProps } from "./types";
import {
  useStationReport,
  usePartsDistribution,
  useArrivalStats,
} from "../../core/providers/StationReportsProvider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import Highcharts from "highcharts";
import Highcgarts3d from "highcharts/highcharts-3d";
import HighchartsDrilldown from "highcharts/modules/drilldown";
import HighchartsReact from "highcharts-react-official";
import moment from "moment/moment";
// import { useDateRange } from "../../core/components/DateRangePicker";

Highcgarts3d(Highcharts);
HighchartsDrilldown(Highcharts);

const QR_BASENAME = process.env.REACT_APP_QR_BASENAME || "3hd.us";

const StationOverview: React.FC<StationOverviewProps> = (props) => {
  // const dateRange = useDateRange();
  const report = useStationReport(props.placeId);
  const partsDistributionReport = usePartsDistribution(props.placeId);
  const arrivalStats = useArrivalStats(props.placeId);

  return (
    <Box p={2}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          <Box py={2}>
            <Typography variant="h6">{report?.name || "--"}</Typography>
            <Link
              variant="caption"
              href={`https://${QR_BASENAME}/${report?.beacon_id}`}
              target="_BLANK"
            >
              {QR_BASENAME}/...{report?.beacon_id.slice(-5)}
            </Link>
          </Box>
          <List>
            <ListItem divider>
              <ListItemIcon>
                <Icon fontSize="large">engineering</Icon>
              </ListItemIcon>
              <ListItemText
                primary={
                  !report?.current_user ? (
                    "--"
                  ) : (
                    <Link
                      variant="body1"
                      href={`https://${QR_BASENAME}/${report.current_user.person_id}`}
                      target="_BLANK"
                    >
                      {report.current_user.first_name}
                    </Link>
                  )
                }
                secondary={
                  !report?.current_user ? "No operator" : "Currrent operator."
                }
              />
            </ListItem>
            <ListItem divider>
              <ListItemIcon>
                <Icon fontSize="large">style</Icon>
              </ListItemIcon>
              <ListItemText
                primary={partsDistributionReport?.totalQuantity || "--"}
                secondary="Items currently residing"
              />
            </ListItem>
            {/* <ListItem divider>
              <ListItemIcon><Icon fontSize="large">qr_code_scan</Icon></ListItemIcon>
              <ListItemText
                primary={todayStats?.arrivedToday.quantity || '--'}
                secondary="Items arrived today."
              />
            </ListItem> */}
            <ListItem divider>
              <ListItemIcon>
                <Icon fontSize="large">timelapse</Icon>
              </ListItemIcon>
              <ListItemText
                primary={
                  !report?.average_duration
                    ? "N/A"
                    : moment.duration(report.average_duration, "s").humanize()
                }
                secondary="Average duration per item"
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Icon fontSize="large">elderly</Icon>
              </ListItemIcon>
              <ListItemText
                primary={
                  !report?.oldest_license_plate ? (
                    "--"
                  ) : (
                    <Link
                      variant="body1"
                      href={`https://${QR_BASENAME}/${report.oldest_license_plate.lp_id}`}
                      target="_BLANK"
                    >
                      {report.oldest_license_plate.product.part_number}
                    </Link>
                  )
                }
                secondary="Oldest item"
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box py={2}>
            <Typography variant="h6">Currently Residing</Typography>
            <Typography variant="caption" color="textSecondary">
              Parts currently residing here
            </Typography>
          </Box>

          <Box py={2}>
            {!!partsDistributionReport && (
              <HighchartsReact
                key={`currently-residing-${report?.beacon_id}`}
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "pie",
                    options3d: {
                      enabled: true,
                      alpha: 45,
                    },
                  },
                  title: null,
                  subtitle: null,
                  tooltip: {
                    shared: true,
                    headerFormat:
                      '<b style="font-size: 15px">{point.key}</b><br/>',
                    pointFormat:
                      '<span style="color:{point.color}">\u25CF</span> {series.name}: <b>{point.y}</b><br/>',
                  },
                  plotOptions: {
                    pie: {
                      innerSize: 100,
                      depth: 45,
                      allowPointSelect: true,
                      cursor: "pointer",
                      dataLabels: {
                        enabled: true,
                        format:
                          '<b style="color: {point.color};">{point.name}</b>: <span >{point.percentage:.1f} %</span>',
                      },
                    },
                  },
                  series: [
                    {
                      name: "Quantity",
                      data: Object.keys(
                        partsDistributionReport.distribution
                      ).map((k) => [
                        k,
                        partsDistributionReport.distribution[k],
                      ]),
                    },
                  ],
                }}
              />
            )}
          </Box>

          <Box py={2}>
            <Typography variant="h6">Arrived</Typography>
            <Typography variant="caption" color="textSecondary">
              Quantity arrived at station during selected range.
            </Typography>
          </Box>

          <Box py={2}>
            {!!arrivalStats && !!report && (
              <HighchartsReact
                key={`line-graph-${report?.beacon_id}`}
                highcharts={Highcharts}
                options={{
                  chart: {
                    type: "area",
                  },
                  title: null,
                  subtitle: null,
                  yAxis: {
                    title: {
                      useHTML: true,
                      text: "Quantity",
                    },
                  },
                  xAxis: {
                    type: "datetime",
                  },
                  tooltip: {
                    shared: true,
                    headerFormat:
                      '<span style="font-size:12px"><b>{point.key}</b></span><br>',
                  },
                  plotOptions: {
                    area: {
                      stacking: "normal",
                      lineColor: "#666666",
                      lineWidth: 1,
                      marker: {
                        lineWidth: 1,
                        lineColor: "#666666",
                      },
                    },
                  },
                  series: arrivalStats,
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StationOverview;
