import React, {useState, useImperativeHandle, useRef, forwardRef} from "react";
import {PlaceInputDialogProps, PlaceInputDialogApi} from "./types";
import useNotify from "../../core/hooks/use-notify";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import usePlaces from "../../core/hooks/use-places";
import PlaceForm, {
  PlaceFormData,
  PlaceFormRef,
} from "../../core/forms/PlaceForm";

const PlaceInputDialog: React.ForwardRefRenderFunction<
  PlaceInputDialogApi,
  PlaceInputDialogProps
> = (props, ref) => {
  const placeForm = useRef<PlaceFormRef>(null);
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [, loading, placeActions] = usePlaces();

  const handleSubmit = async (data: PlaceFormData) => {
    await placeActions.create(data);
    notify(`Created place "${data.name}"`);
    setOpen(false);
  };

  useImperativeHandle(ref, () => ({openDialog: () => setOpen(true)}), []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown={loading}
      onClose={() => setOpen(false)}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Box pt={2} pb={4}>
          <PlaceForm
            ref={placeForm}
            onSubmit={handleSubmit}
            disabled={loading}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && <CircularProgress size={24} />}
        <Button onClick={() => setOpen(false)} disabled={loading}>
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => placeForm.current?.submitForm()}
          disabled={loading}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(PlaceInputDialog);
