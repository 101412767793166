
class Store {
  private cache: {[key: string]: any} = {};

  constructor(
    private storage = localStorage,
    private prefix = 'store::'
  ) {}

  private makeLocalKey(key: string) {
    return this.prefix + key;
  }

  get<T>(key: string): T | null {
    const _key = this.makeLocalKey(key);

    try{
      return this.cache.hasOwnProperty(_key) ? this.cache[_key] : (this.cache[_key] = JSON.parse(this.storage.getItem(_key) as string));
    }
    catch(err: any){
      return this.cache[_key] = null;
    }
  }
  
  set<T>(key: string, value: T | null): boolean {
    try {
      // Avoid accidently setting null and undefined values as strings "null" and "undefined".
      if(value === null || value === undefined)
        value !== undefined && this.remove(key);
      else{
        const _key = this.makeLocalKey(key);
        this.cache.hasOwnProperty(_key) && (this.cache[_key] = value);

        this.storage.setItem(_key, JSON.stringify(value));
      }
      
      return true;
    } catch (e) {
      return false;
    }
  }
  
  remove(key: string) {
    const _key = this.makeLocalKey(key);
    if(this.cache.hasOwnProperty(_key)){
      const cache = this.cache;
      delete cache[_key];
    }

    return this.storage.removeItem(_key);
  }
  
  clear() {
    const keys = Object.keys(this.storage).filter(k=>k.startsWith(this.prefix));
    keys.forEach(k=>this.storage.removeItem(k));
    this.cache = {};
  }
  
  backend(storage: Storage): Storage {
    storage && (this.storage = storage);
  
    return this.storage;
  }
}

export default Store;
