
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ViewAllPlaces from './ViewAllPlaces';

const Places: React.FC = () => {
  return (
    <Switch>
      <Route path="/Places" component={ViewAllPlaces} />
      <Redirect to="/Places" />
    </Switch>
  );
};

export default Places;
