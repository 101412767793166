
import React, { useState, useEffect, useCallback } from 'react';
import context from './context';
import useNotify from '../../hooks/use-notify';
import { getAllRoles, Role } from '../../system/roles';

const RolesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [roles, setRoles] = useState<Role[]>([]);

  const getAll = useCallback(async () => {
    try{
      setLoading(true);
      const roles = await getAllRoles();
      setRoles(roles);

    }
    catch(err: any){
      notify(err);
    }
    finally{
      setLoading(false);
    }

  }, [notify]);

  useEffect(()=>{
    getAll();

  }, [getAll]);

  return (
    <context.Provider
      value={[
        roles,
        loading,
        {
          getAll
        }
      ]}

    >{children}</context.Provider>
  );
};

export default RolesProvider;
