import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {useMemo} from "react";

export type SplitStringProps = {
  qrcode: string;
  value?: string | "qrcode";
  splitBy?: string;
  indexLabels: string[];
  indexSlices: (number | number[])[];
};

const SplitString: React.FC<SplitStringProps> = props => {
  const {
    qrcode,
    value = "qrcode",
    splitBy = "",
    indexLabels = [],
    indexSlices = [],
  } = props;

  const parts = useMemo(() => {
    const str = value === "qrcode" ? qrcode : value || "";
    return str?.split(splitBy || "").map((part, i) => {
      const slice = (
        Array.isArray(indexSlices[i]) ? indexSlices[i] : [indexSlices[i] || 0]
      ) as number[];
      return part.slice(...slice);
    });
  }, [qrcode, value, splitBy, indexSlices]);

  return (
    <Box>
      {parts.map((part, i) => (
        <Box key={`${indexLabels[i]}-${i}`} mb={2}>
          <Typography variant="overline">
            {indexLabels[i] || `${i} >>`}
          </Typography>
          <Typography variant="h6">{part}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default SplitString;
