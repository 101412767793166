import React, {useMemo, useRef, useState} from "react";
import {Link, RouteComponentProps} from "react-router-dom";
import BinDialog from "./BinDialog";
import EmbeddedContentDialog, {
  EmbeddedContentDialogApi,
} from "../core/components/EmbeddedContentDialog";
import BinCard from "./BinCard";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import AddIcon from "@material-ui/icons/Add";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
// import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import PinDropIcon from "@material-ui/icons/PinDrop";
import useBinFamily from "../core/hooks/use-bin-families";
import parseMomenttrackId from "../core/system/utils/parse-momenttrack-id";
import useSingleBinFamily from "../core/hooks/use-bin-family";
import useNotify from "../core/hooks/use-notify";
import {moveFamily} from "../core/system/bin-families";
import useConfirmations from "../core/hooks/use-confirmations";
import QrCodeScanner from "../core/components/QrCodeScanner/QrCodeScanner";
import {BinDialogRef} from "./types";
import {BinData} from "../core/forms/BinForm";
import {Slot} from "../core/components/Layout";
import {CircularProgress, IconButton} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const BinFamilyPage: React.FC<RouteComponentProps<{binFamilyId: string}>> =
  function (props) {
    const {match} = props;

    const binDialog = useRef<BinDialogRef>(null);
    const pdfLinkDialog = useRef<EmbeddedContentDialogApi>(null);
    const notify = useNotify();
    const confirm = useConfirmations();
    const binFamilyId = Number(match.params.binFamilyId);
    const [binFamily, loadingBinFamily, message] =
      useSingleBinFamily(binFamilyId);
    const [{bins: allBins}, loadingBins, , binFamiliesService] = useBinFamily();

    const bins = useMemo(() => {
      return allBins.filter(bin => bin.bin_family_id === binFamilyId);
    }, [allBins, binFamilyId]);

    const [generatingLabels, setGeneratingLabels] = useState(false);
    const [movingBin, setMovingBin] = useState(false);

    async function handleBinCreate(data: BinData) {
      await binFamiliesService.createBin(data);
      notify("New bin added to family.");
    }

    async function handleGenerateLabels(templateType: "sheet" | "single") {
      try {
        if (
          !binFamily ||
          !(await confirm("Continue?, Generating pdf document for labels"))
        )
          return;

        setGeneratingLabels(true);
        const labelsPdf = await binFamiliesService.generateLabels(
          [binFamily],
          templateType
        );
        pdfLinkDialog.current?.openDialog(labelsPdf);
        notify("Labels generated.");
      } catch (err) {
        notify(err as Error);
      } finally {
        setGeneratingLabels(false);
      }
    }

    async function handlePlaceScan(placeUrl: string) {
      try {
        const idInfo = parseMomenttrackId(placeUrl);

        if (idInfo.type !== "place" || !Number(idInfo.id))
          throw new Error("Invalid place id.");

        if (
          !(await confirm("Are you sure?, Move this bin family to this place."))
        )
          return notify("Cancelled.");

        setMovingBin(true);
        const updatedFamily = await moveFamily(binFamilyId, Number(idInfo.id));
        notify(
          `Bin family moved to new place "${updatedFamily.location.name}".`
        );
      } catch (err) {
        notify(err as Error);
      } finally {
        setMovingBin(false);
      }
    }

    const loading =
      loadingBinFamily || loadingBins || generatingLabels || movingBin;
    const canAddBins = !loading && !!binFamily && bins.length < 2;
    const canPrintLabels = bins.length === 2;

    return (
      <>
        <Slot name="main-toolbar">
          <Grid container alignItems="center" spacing={2}>
            <Grid item>
              <div style={{display: "flex", alignItems: "center"}}>
                <Link to="/bin-families">
                  <IconButton>
                    <ArrowBackIosIcon />
                  </IconButton>
                </Link>
                {loading && <CircularProgress size={24} />}
                {!loading && <Typography variant="h6">Bin Family</Typography>}
              </div>
            </Grid>
            <Grid item xs />
          </Grid>
        </Slot>
        <Container maxWidth={false}>
          <Box py={2}>
            <Paper>
              <Box p={2}>
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="h6">
                      {loading
                        ? "..."
                        : binFamily
                        ? binFamily.product.name
                        : message}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Bin family
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>

            <Box py={2}>
              <Grid container spacing={1}>
                {bins.map(bin => (
                  <Grid key={"" + bin.id} item xs={12} sm={4} md={3} xl={2}>
                    <BinCard bin={bin} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Box py={2}>
              {canAddBins && (
                <Button
                  color="primary"
                  disabled={loading}
                  startIcon={<AddIcon />}
                  size="large"
                  onClick={() => binDialog.current?.openDialog(true)}
                >
                  Add bin
                </Button>
              )}
              {canPrintLabels && (
                <>
                  <Button
                    color="primary"
                    size="large"
                    startIcon={<PdfIcon />}
                    disabled={loading}
                    onClick={() => handleGenerateLabels("sheet")}
                  >
                    Generate labels
                  </Button>
                </>
              )}
            </Box>

            <Box pb={2}>
              <QrCodeScanner
                title="Scan place QR"
                buttonText="Move to new place"
                onResult={handlePlaceScan}
                ButtonProps={{
                  color: "primary",
                  disabled: loading,
                  startIcon: <PinDropIcon />,
                  size: "large",
                }}
              />
            </Box>
          </Box>

          <BinDialog
            ref={binDialog}
            bin={{bin_family_id: binFamilyId}}
            primaryActionText="Create"
            secondaryActionText="Cancel"
            primaryAction={handleBinCreate}
          />

          <EmbeddedContentDialog
            ref={pdfLinkDialog}
            title="Bin family labels"
          />
        </Container>
      </>
    );
  };

export default BinFamilyPage;
