import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  Popover,
  TextField,
  ThemeProvider,
  Toolbar,
} from "@material-ui/core";
import {DragEvent, useLayoutEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import ContactlessIcon from "@material-ui/icons/Contactless";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import FormatShapesIcon from "@material-ui/icons/FormatShapes";
import GenericComponentDisplay from "../_base/components/GenericComponentDisplay";
import AddIcon from "@material-ui/icons/Add";
import {
  BUTTON_DEFAULT,
  IMAGE_DEFAULT,
  VIDEO_DEFAULT,
  TYPOGRAPHY_DEFAULT,
  HEADER_DEFAULT,
  FOOTER_DEFAULT,
} from "./constants";
import TemplateParser from "../_base/components/TemplateParser";
import {generateId} from "../utils";
import EditTemplateDrawer from "../_base/components/EditTemplateDrawer";
import defaultTheme from "../_base/themes/default-theme";
import "./style.css";
import {TemplateResponse, templateParams} from "../_base/system/types";
import {getTemplateById, saveTemplate} from "../_base/system";
import useNotify from "../../core/hooks/use-notify";
import TruckLoader from "../../core/components/TruckLoader";

export type componentTypes =
  | "Button"
  | "Text"
  | "Image"
  | "Accordion"
  | "Video"
  | "Social"
  | "Header"
  | "Footer";

// component mappings for default display
export const MappedData: {[key: string]: any} = {
  Button: BUTTON_DEFAULT,
  Image: IMAGE_DEFAULT,
  // Social: SOCIALS_DEFAULT,
  Video: VIDEO_DEFAULT,
  // Accordion: ACCORDION_DEFAULT,
  Text: TYPOGRAPHY_DEFAULT,
  Header: HEADER_DEFAULT,
  Footer: FOOTER_DEFAULT,
};

function TemplateBuilder() {
  const {orgId, productId, orderId} = useParams<templateParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const notify = useNotify();

  useLayoutEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const template = await getTemplateById({orgId, productId, orderId});
        setData(template);
      } catch (err) {
        console.log(err as Error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [orderId, orgId, productId]);

  const [isDragOver, setisDragOver] = useState(false);
  const [isSuggestionsOpen, setisSuggestionsOpen] = useState(false);
  const [editDrawerOption, setEditDrawerOptions] = useState({
    open: false,
    type: "",
    componentId: "",
  });

  // Drag and Drop Event Handlers
  const handleDragEnter = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setisDragOver(true);
    e.stopPropagation();
  };
  const handleDragLeave = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setisDragOver(false);
    e.stopPropagation();
  };
  const handleDragOver = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setisDragOver(true);
    e.stopPropagation();
  };
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  // default data state
  const [data, setData] = useState<TemplateResponse>({
    type: "Theme",
    children: [],
    props: {
      palette: {
        primary: {
          main: "#ec1c24",
        },
      },
    },
  });

  // Component Action Handlers
  const onComponentDelete = (id?: string) => {
    setData(prev => ({
      ...prev,
      children: prev?.children.filter(el => el?.id !== id),
    }));
  };

  const onComponentEdit = ({type, id}: {type?: string; id: string}) => {
    setEditDrawerOptions({
      open: true,
      componentId: id,
      type: type || "",
    });
  };

  // Handles click event for adding a component
  const handleClick = (type: componentTypes) => {
    const defaultData = MappedData[type];
    const id = generateId();
    setData(prev => ({
      ...prev,
      children: [
        ...prev.children,
        {
          ...defaultData,
          id,
          editType: type,
        },
      ],
    }));
    setisSuggestionsOpen(false);
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleTemplateSave = async () => {
    try {
      setIsSaveLoading(true);
      await saveTemplate({
        _meta: {
          organization_id: Number(orgId),
          product_id: Number(productId),
          production_order_id: Number(orderId),
        },
        _template: data,
      });
      notify("Template saved successfully");
    } catch (error) {
      notify(error as Error);
    } finally {
      setIsSaveLoading(false);
    }
  };
  return (
    <div className="template-builder-page">
      {isSaveLoading && <TruckLoader message="Saving template..." />}
      <AppBar
        className="MuiPaper-root"
        position="relative"
        color="inherit"
        elevation={2}
      >
        <Toolbar>
          <Box
            style={{
              justifyContent: "space-between",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
            }}
            className="MuiBox-root"
            px={1}
            lineHeight={0}
          >
            <Link to="/">
              <img
                alt="Production track trademark"
                src={"/logo-productiontrack.png"}
                style={{maxHeight: 40}}
              />
              {/* <img
                src={momenttrackLogoM}
                alt="Momenttrack logo"
                className={classes.logo}
              /> */}
            </Link>
            <Button
              component={Link}
              to="/orders"
              variant="outlined"
              color="primary"
            >
              Go Back
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <div className="grid grid-cols-3 bg-white rounded-lg form-wrapper-head">
        <div className="flex justify-between items-center col-span-2 p-6 border-r border-b form-head-content">
          <div>
            <p className="title">Customize your template</p>
            <p className="subtitle">
              Create a template for general customer view
            </p>
          </div>
          <div className="flex space-x-4 btn-cont">
            <Button
              aria-describedby={id}
              onClick={event => setAnchorEl(event.currentTarget)}
              size="large"
              color="primary"
              variant="outlined"
            >
              <span>Template Color</span>
              <span
                style={{
                  backgroundColor: data.props.palette.primary.main,
                  width: 15,
                  height: 15,
                  marginLeft: "0.5rem",
                }}
              ></span>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <div style={{width: "300px", padding: "1rem"}}>
                <TextField
                  fullWidth
                  label="Select Theme Color"
                  type="color"
                  value={data.props.palette.primary.main}
                  onChange={e => {
                    let newTheme = {...data};
                    newTheme.props.palette.primary.main = e.target.value;
                    setData(newTheme);
                  }}
                  name="main"
                />
              </div>
            </Popover>

            <Button
              disabled={isSaveLoading}
              onClick={handleTemplateSave}
              color="primary"
              size="large"
            >
              {isSaveLoading ? "Saving template..." : "Save"}
            </Button>
          </div>
        </div>
        <div
          onClick={() => setisSuggestionsOpen(false)}
          className={`${isSuggestionsOpen ? "" : "d-none"} other-border`}
        ></div>
        <div
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          className="drop-cont right p-6 col-span-2 h-[80vh] border-r overflow-y-auto"
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
                textAlign: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
              <p>Searching for existing tempate</p>
            </div>
          ) : (
            <Container className="MuiContainer-root" maxWidth="xs">
              <TemplateParser
                editMode={true}
                onDelete={onComponentDelete}
                onEdit={onComponentEdit}
                template={data}
                qrcode=""
              />
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                className={`drag-box ${isDragOver ? "active" : ""}`}
                onClick={() => setisSuggestionsOpen(true)}
              >
                <Button
                  variant="text"
                  startIcon={<AddIcon />}
                  color="primary"
                  className="drag-btn"
                >
                  Add field
                </Button>
                <p className="drag-text">
                  {isDragOver
                    ? "Drop here to add a section"
                    : "Click any of the fields on the right side to add fields"}
                </p>
              </div>
            </Container>
          )}
        </div>

        <div className={`suggestions ${isSuggestionsOpen ? "open" : ""}`}>
          <div className="show-fields-cont">
            <p style={{fontSize: 18}}>Suggested fields</p>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setisSuggestionsOpen(false)}
            >
              Close
            </Button>
          </div>
          <div className="suggestion-btn-container">
            <GenericComponentDisplay
              onClick={() => {
                handleClick("Header");
              }}
              id="header"
              icon={FormatShapesIcon}
              text="Header"
            />
            <GenericComponentDisplay
              onClick={() => {
                handleClick("Button");
              }}
              id="button"
              icon={TouchAppIcon}
              text="Button"
            />
            <GenericComponentDisplay
              onClick={() => {
                handleClick("Text");
              }}
              id="text"
              icon={TextFieldsIcon}
              text="Text"
            />
            {/* <GenericComponentDisplay
              id="accordion"
              onClick={() => {
                handleClick("Accordion");
              }}
              icon={HorizontalSplitIcon}
              text="Accordion"
            /> */}
            <GenericComponentDisplay
              id="video"
              icon={OndemandVideoIcon}
              onClick={() => {
                handleClick("Video");
              }}
              text="Video"
            />
            <GenericComponentDisplay
              id="image"
              icon={AddPhotoAlternateIcon}
              onClick={() => {
                handleClick("Image");
              }}
              text="Image"
            />
            <GenericComponentDisplay
              id="footer"
              icon={ContactlessIcon}
              onClick={() => {
                handleClick("Footer");
              }}
              text="Footer"
            />
            {/* <GenericComponentDisplay
              id="social"
              icon={ConnectWithoutContactIcon}
              onClick={() => {
                handleClick("Social");
              }}
              text="Social media"
            /> */}
          </div>
        </div>

        <EditTemplateDrawer
          {...editDrawerOption}
          data={data}
          // TODO: use the data state as props and update it locally in the child state which is in turn pased as editData
          onEditSave={editData => {
            setData(editData);
            setEditDrawerOptions(prev => ({...prev, open: false}));
          }}
          onClose={() => setEditDrawerOptions(prev => ({...prev, open: false}))}
        />
      </div>
    </div>
  );
}

const TemplateBuilderWrapper = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <TemplateBuilder />
    </ThemeProvider>
  );
};
export default TemplateBuilderWrapper;
