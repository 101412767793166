import React, {useState, forwardRef, useImperativeHandle} from "react";
import {EEmbeddedContentDialogComponent} from "./types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

const EmbeddedContentDialog: EEmbeddedContentDialogComponent = (
  {title = "", onCloseDialog = () => {}, dialogFooter},
  ref
) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [srcUri, setSrcUri] = useState("");

  useImperativeHandle(
    ref,
    () => ({
      openDialog: async contentSrc => {
        try {
          let srcUri: string;

          setErrMsg("");
          setSrcUri("");
          setLoading(true);
          setOpen(true);
          if (typeof contentSrc === "function") srcUri = await contentSrc();
          else srcUri = await contentSrc;

          setSrcUri(srcUri);
          setLoading(false);
        } catch (err: any) {
          setErrMsg(err.message);
          setLoading(false);
        }
      },
      closeDialog: () => {
        setOpen(false);
      },
    }),
    []
  );

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
      disableBackdropClick={loading}
    >
      <DialogTitle>{title}</DialogTitle>
      {loading && (
        <>
          <LinearProgress />
          <Typography style={{marginTop: "1rem"}} align="center">
            Loading Content...
          </Typography>
        </>
      )}
      <Box>
        {!!errMsg && (
          <Box p={2}>
            <Typography color="error">{errMsg}</Typography>
          </Box>
        )}
        {!!srcUri && (
          <iframe
            title={title}
            src={srcUri}
            width="100%"
            style={{border: "none", height: "70vh"}}
          />
        )}
      </Box>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            setOpen(false);
            onCloseDialog();
          }}
        >
          Close
        </Button>
        {dialogFooter}
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(EmbeddedContentDialog);
