
import React, { useContext, useState, useCallback } from 'react';
import { LayoutProps, LayoutContextProps } from './types';
import context from './context';
import Box from '@material-ui/core/Box';

const Layout: React.FC<LayoutProps> = props => {
  const {
    slotsMap = {},
    ...boxProps
  } = props;

  const [asides, setAsides] = useState<(string|number)[]>([]);
  const hasAside = asides.length > 0;

  const parentContext = useContext(context);
  const availableSlotsMap = {...parentContext.slotsMap, ...slotsMap};

  const registerAside = useCallback((asideId: string | number) => setAsides(prevVal=>[...prevVal, asideId]), []);

  const contextValue: LayoutContextProps = {
    slotsMap: availableSlotsMap,
    getSlot: k=>availableSlotsMap[k],
    registerAside
  };

  return (
    <context.Provider value={contextValue}>
      <Box
        component="section"
        height="100%"
        overflow="hidden"
        display="flex"
        flexDirection={hasAside ? 'row' : 'column'}
        flex="auto"
        position="relative"
        {...boxProps}
      />
    </context.Provider>
  );
};

export default Layout;
