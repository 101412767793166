
import * as Yup from 'yup';

const schema = Yup.object().shape({
  name: Yup.string().trim().required('Required.'),
  description: Yup.string().trim().required('Required.'),
  part_number: Yup.string().trim(),
  erp_part_number: Yup.string().trim().notRequired(),
  preferred_vendor_id: Yup.number().required('Required.'),
  preferred_vendor_part_number: Yup.string().trim().notRequired()
});

export default schema;
