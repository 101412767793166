import React, {useState} from "react";
import {LookupQueryFormProps, ProductLookupQuery} from "./types";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import InputBase from "@material-ui/core/InputBase";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Slot} from "../core/components/Layout";
import useNotify from "../core/hooks/use-notify";
import {lookupLicensePlates, LicensePlate} from "../core/system/license-plates";

const LookupQueryForm: React.FC<LookupQueryFormProps> = props => {
  const {onSubmit = () => {}, disabled} = props;

  const [value, setValue] = useState("");
  const [searchBy, setSearchBy] = useState("external_serial_number");
  const [matchType, setMatchType] = useState("exact");

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        if (value.trim().length === 0) return;

        onSubmit({
          value: value.trim(),
          type: searchBy,
          matchType,
        });
      }}
    >
      <RadioGroup
        value={searchBy}
        onChange={(ev, val) => setSearchBy(val)}
        row
        style={{alignItems: "center"}}
      >
        <Typography component="em" style={{marginRight: 16}}>
          Where
        </Typography>
        <FormControlLabel
          label="Asset #"
          value="external_serial_number"
          disabled={disabled}
          control={<Radio />}
        />
        <FormControlLabel
          label="Part #"
          value="part_number"
          disabled={true || disabled}
          control={<Radio />}
        />
        <FormControlLabel
          label="Product QR"
          value="lp_id"
          disabled={true || disabled}
          control={<Radio />}
        />
      </RadioGroup>
      <Paper
        elevation={1}
        style={{
          display: "flex",
          alignItems: "center",
          borderRadius: "0 100px 100px 0",
          padding: "2px 2px 2px 16px",
        }}
      >
        <Select
          disableUnderline
          value={matchType}
          onChange={ev => setMatchType(ev.target.value as string)}
          disabled={disabled}
        >
          <MenuItem value="exact">is exactly</MenuItem>
          <MenuItem value="contains" disabled>
            contains
          </MenuItem>
          <MenuItem value="begins" disabled>
            begins with
          </MenuItem>
          <MenuItem value="ends" disabled>
            ends with
          </MenuItem>
        </Select>
        <InputBase
          placeholder="Value"
          value={value}
          onChange={ev => setValue(ev.target.value)}
          disabled={disabled}
          required
          style={{padding: "0 8px 0", flex: 1}}
        />
        <Fab type="submit" color="primary" size="medium" disabled={disabled}>
          <Icon>search</Icon>
        </Fab>
      </Paper>
    </form>
  );
};

const ProductsLookup: React.FC = () => {
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState<LicensePlate[]>([]);

  const handleLookup = async (query: ProductLookupQuery) => {
    try {
      setLoading(true);
      const products = await lookupLicensePlates(query.value);
      setProducts(products);
      setLoading(false);
    } catch (err) {
      notify(err as any);
      setLoading(false);
    }
  };

  return (
    <>
      <Slot name="main-toolbar">
        {!loading && <Typography variant="h6">Search products</Typography>}
        {loading && <CircularProgress size={24} />}
      </Slot>
      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container>
            <Grid item xs={12} md={8} lg={6}>
              <LookupQueryForm onSubmit={handleLookup} disabled={loading} />
            </Grid>
          </Grid>
        </Box>
        <Box py={2}>
          {!loading && products.length === 0 && (
            <Typography color="textSecondary">Nothing to show.</Typography>
          )}
          <Grid container spacing={2}>
            {products.map(lp => (
              <Grid key={`${lp.id}`} item xs={12} sm={6} md={4} lg={3}>
                <Card>
                  <CardHeader
                    title={`${lp.product.part_number}`}
                    subheader={`x${lp.quantity}`}
                    action={
                      <Link
                        href={`https://${
                          process.env.REACT_APP_QR_BASENAME || "3hd.us"
                        }/${lp.lp_id}`}
                        target="_BLANK"
                      >
                        <IconButton>
                          <Icon>open_in_new</Icon>
                        </IconButton>
                      </Link>
                    }
                  />
                  <Divider light />
                  <CardContent>
                    <Typography variant="caption" color="textSecondary">
                      EXTERNAL SN
                    </Typography>
                    <Typography>{lp.external_serial_number || "--"}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ProductsLookup;
