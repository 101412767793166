import React, {useState, useLayoutEffect, useCallback, useRef} from "react";
import {
  StackType,
  generateStackSheets,
  getAllStacks,
} from "../core/system/stacks";
import stackLabelTemplate from "../core/system/stacks/stack-qr-label";
import useNotify from "../core/hooks/use-notify";
import {useAuthentication} from "../core/providers/AuthenticationProvider";
import Layout, {Header, Content, Slot} from "../core/components/Layout";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Icon from "@material-ui/core/Icon";
import CircularProgress from "@material-ui/core/CircularProgress";
import DataGrid, {ColumnDef, RowData} from "../core/components/DataGrid";
import moment from "moment";
import DateTag from "../core/components/DateTag";
import EmbeddedContentDialog, {
  EmbeddedContentDialogApi,
} from "../core/components/EmbeddedContentDialog";
import Chip from "@material-ui/core/Chip";
import SendEmailModal, {
  SendEmailModalApi,
} from "../core/components/SendEmailModal";
import randomize from "randomatic";
import EditContainerQuantityDialog from "./EditContainerQuantityDialog";
import {EditContainerQuantityDialogApi} from "./types";
import EditIcon from "@material-ui/icons/Edit";

const ViewAllProducts: React.FC = () => {
  const [authenticatedUser] = useAuthentication();
  const authenticated = !!authenticatedUser;

  const [stackList, setStackList] = useState<StackType[] | null>(null);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [selection, setSelection] = useState<StackType[]>([]);
  const [mailData, setmailData] = useState<StackType | null>(null);

  const fetchAllStacks = useCallback(async () => {
    try {
      setLoading(true);
      const persons = await getAllStacks();

      setStackList(persons);
      setLoading(false);
    } catch (err: any) {
      notify(err);
    }
  }, [notify]);
  useLayoutEffect(() => {
    if (authenticated) fetchAllStacks();
  }, [fetchAllStacks, authenticated]);

  const activitySheetsDialog = useRef<EmbeddedContentDialogApi>(null);
  const editSheetsDialog = useRef<EditContainerQuantityDialogApi>(null);
  const sendEmailDialog = useRef<SendEmailModalApi>(null);
  const handleButtonClick = () => {
    activitySheetsDialog.current?.openDialog(() =>
      generateStackSheets(selection)
    );
  };

  const handleSelection = (selection: RowData[]) => {
    setSelection(selection as StackType[]);
  };

  const columns: ColumnDef[] = [
    {
      field: "created_at",
      headerName: "CREATED",
      sortable: true,
      valueGetter: (data: StackType) => moment.utc(data.created_at).valueOf(),
      renderCell: (data: StackType) => (
        <DateTag
          date={data?.created_at}
          dateInputType="utc"
          initialMode="since-today"
        />
      ),
    },
    {
      field: "part_number",
      headerName: "PART #",
      sortable: true,
      // padding: "checkbox",
      valueGetter: (data: StackType) => data?.part_number,
      renderCell: (data: StackType) => (
        <span
          style={{
            maxWidth: "200px",
            wordWrap: "break-word",
            display: "block",
            fontSize: "1rem",
            whiteSpace: "pre-wrap",
          }}
        >
          {data?.part_number}
        </span>
      ),
    },
    {
      field: "stack_id",
      headerName: "QR code",
      renderCell: (data: StackType) => (
        <Typography>
          <small>{process.env.REACT_APP_QR_BASENAME || "3hd.us"}/...</small>
          {data?.stack_id?.slice(-5)}
        </Typography>
      ),
    },
    {
      field: "item_count",
      headerName: "QTY",
      sortable: true,
      valueGetter: (data: StackType) => data?.item_count,
    },
    {
      field: "description",
      headerName: "Description",
      renderCell: (data: StackType) => (
        <span
          style={{
            maxWidth: 250,
            overflowWrap: "break-word",
            display: "block",
            fontSize: "1rem",
            whiteSpace: "pre-wrap",
            width: "max-content",
          }}
        >
          {data?.description}
        </span>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      sortable: true,
      valueGetter: (data: StackType) => data?.status || "--",
      renderCell: (data: StackType) => (
        <Chip
          style={{
            background: data.status === "open" ? "#4caf50" : "#f44336",
            color: "#fff",
            width: "100%",
          }}
          label={data?.status?.toUpperCase()}
        />
      ),
    },
    {
      field: "actions",
      headerName: " ",
      sortable: true,
      valueGetter: (data: StackType) => data?.stack_id || "--",
      renderCell: (data: StackType) => (
        <div>
          <Button
            color="secondary"
            type="button"
            onClick={() => {
              sendEmailDialog.current?.openDialog();
              setmailData(data);
            }}
          >
            Send Label
          </Button>
          <IconButton
            onClick={() => {
              editSheetsDialog.current?.openDialog(data);
            }}
          >
            <EditIcon />
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <>
      <Slot name="main-toolbar">
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            {loading && <CircularProgress size={24} />}
            {!loading && <Typography variant="h6">Containers</Typography>}
          </Grid>
        </Grid>
      </Slot>

      <Layout>
        <Header>
          <Container maxWidth={false}>
            <Paper>
              <Box px={2} py={1}>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <Hidden xsDown>
                      <Button
                        color="secondary"
                        startIcon={<Icon>print</Icon>}
                        onClick={handleButtonClick}
                        disabled={loading || selection.length === 0}
                      >
                        Get labels
                      </Button>
                    </Hidden>
                    <Hidden smUp>
                      <IconButton
                        edge="start"
                        color="secondary"
                        onClick={handleButtonClick}
                        disabled={loading || selection.length === 0}
                      >
                        <Icon>print</Icon>
                      </IconButton>
                    </Hidden>
                  </Grid>
                  <Grid item>
                    <Typography>
                      <b>{selection.length || "No"}</b> rows selected.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Container>
        </Header>
        <Content>
          <Container maxWidth={false} style={{height: "calc(100% - 72px)"}}>
            <DataGrid
              rows={stackList || []}
              columns={columns}
              windowHeight="100%"
              checkboxSelection
              loading={loading}
              disableSelectionOnClick
              onSelectionChange={handleSelection}
            />
          </Container>
        </Content>
      </Layout>

      <EmbeddedContentDialog ref={activitySheetsDialog} title="Stack Sheets" />
      <SendEmailModal
        ref={sendEmailDialog}
        title="Send Email"
        mailSubject={`You have recieved a pdf from from ${authenticatedUser?.first_name} ${authenticatedUser?.last_name}, click the button below to download it`}
        mailTitle={`Stack label`}
        message="Fill in the field below to send a mail of the stack pdf label"
        template={mailData ? stackLabelTemplate([mailData as StackType]) : ""}
        pdfOptions={{
          pdf_mode: "portrait",
          pdf_name: `stack-sheets-${randomize("a0", 12)}`,
          page_height: "110mm",
          page_width: "203.2mm",
          margin_top: "0mm",
          margin_bottom: "0mm",
          margin_left: "0mm",
          margin_right: "0mm",
          disable_smart_shrinking: null,
        }}
      />
      <EditContainerQuantityDialog ref={editSheetsDialog} />
    </>
  );
};

export default ViewAllProducts;
