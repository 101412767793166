import React, {useImperativeHandle, forwardRef} from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/EmailOutlined";
import LockIcon from "@material-ui/icons/LockOutlined";
import {
  AuthenticationData,
  AuthenticationFormProps,
  AuthenticationFormRef,
} from "./types";
import {useFormik} from "formik";
import validationSchema from "./validation-schema";

const initialValues: AuthenticationData = {email: "", password: ""};

const AuthenticationForm: React.ForwardRefRenderFunction<
  AuthenticationFormRef,
  AuthenticationFormProps
> = function (props, ref) {
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: values => {
      if (props.onSubmit) props.onSubmit(values);
    },
  });

  useImperativeHandle(ref, () => formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <TextField
            label="Email address"
            type="email"
            {...formik.getFieldProps("email")}
            disabled={props.disabled}
            error={!!(formik.touched["email"] && formik.errors["email"])}
            helperText={formik.touched["email"] && formik.errors["email"]}
            variant="outlined"
            fullWidth
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Password"
            type="password"
            {...formik.getFieldProps("password")}
            disabled={props.disabled}
            error={!!(formik.touched["password"] && formik.errors["password"])}
            helperText={formik.touched["password"] && formik.errors["password"]}
            variant="outlined"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <input
        type="submit"
        tabIndex={-1}
        style={{position: "absolute", left: -999999, width: 1, height: 1}}
      />
    </form>
  );
};

export default forwardRef(AuthenticationForm);
