import React from "react";
import {TemplateParserProps, TemplateComponent} from "./types";
import Theme from "../Theme";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Image from "../Image";
import SplitString from "../SplitString";
import Dialog from "../Dialog";
import AccordionGroup from "../AccordionGroup";
import Accordion from "../Accordion";
import SocialIcons from "../SocialIcons";
import SocialShareButtons from "../SocialShareButtons";
import {useMemo} from "react";
import {IconButton} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";

// return the appropriate component based on the type string
function parseComponentType(type: string): string | any {
  switch (type.trim()) {
    case "Theme":
      return Theme;
    case "Box":
      return Box;
    case "Container":
      return Container;
    case "Divider":
      return Divider;
    case "Typography":
      return Typography;
    case "Link":
      return Link;
    case "Button":
      return Button;
    case "Image":
      return Image;
    case "SplitString":
      return SplitString;
    case "Dialog":
      return Dialog;
    case "AccordionGroup":
      return AccordionGroup;
    case "Accordion":
      return Accordion;
    case "SocialIcons":
      return SocialIcons;
    case "SocialShareButtons":
      return SocialShareButtons;
    default:
      return type.trim().toLowerCase();
  }
}

// Define a mapping type for shorthand properties to CSS properties
type SxMap = {
  mt?: React.CSSProperties["marginTop"];
  mx?: string | number;
  my?: string | number;
  mb?: React.CSSProperties["marginBottom"];
  p?: React.CSSProperties["padding"];
  m?: React.CSSProperties["margin"];
  bgColor?: React.CSSProperties["backgroundColor"];
};

// Extend React.CSSProperties with the shorthand properties
type SxProps = React.CSSProperties & Partial<SxMap>;

// Define the map with correct CSS property names
const sxMaps: {[K in keyof SxMap]: keyof React.CSSProperties} = {
  mt: "marginTop",
  mx: "margin", // Note: Handling 'mx' and 'my' correctly will require additional logic
  my: "margin",
  mb: "marginBottom",
  p: "padding",
  m: "margin",
  bgColor: "backgroundColor",
};

// Converts numeric spacing values to a string with px or rem
const convertSpacingValue = (value: number | string): string => {
  if (typeof value === "number") {
    return `${value * 8}px`; // Adjust multiplier as needed
  }
  return value; // Return string values directly
};

function sxToStyle(sx?: SxProps): React.CSSProperties {
  const style: React.CSSProperties = {};
  if (sx) {
    for (const [key, value] of Object.entries(sx)) {
      if (key in sxMaps) {
        const cssProperty = sxMaps[
          key as keyof SxMap
        ] as keyof React.CSSProperties;
        if (typeof value === "number" || typeof value === "string") {
          const convertedValue = convertSpacingValue(value);
          if (cssProperty === "margin" && key === "mx") {
            style.marginLeft = convertedValue;
            style.marginRight = convertedValue;
          } else {
            (style as any)[cssProperty] = convertedValue;
          }
        }
      } else {
        (style as any)[key as keyof React.CSSProperties] = value;
      }
    }
  }
  return style;
}

const TemplateParser: React.FC<TemplateParserProps> = _props => {
  const {template, qrcode, onDelete, onEdit, editMode} = _props;
  return useMemo(() => {
    let {type, children, id = "", editType} = template || {};
    let props =
      type === "Button"
        ? {...template.props, color: "primary", variant: "contained"}
        : template.props;

    // convert sx props to css styles
    props = {
      ...props,
      style: {
        ...sxToStyle(template.props?.sx),
        ...(template.props?.style || {}),
      },
    };

    // Parse type
    if (typeof type !== "string" || type.trim().length === 0)
      return React.createElement("p", {style: {color: "red"}}, "__??");

    // Parse children
    if (
      ["string", "number", "object"].indexOf(typeof children) >= 0 &&
      !Array.isArray(children)
    )
      children = [children as string | number | TemplateComponent];

    // Create parent nodes for edit mode
    const parentNodes = editMode ? (
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.5rem",
        }}
        className="action-stack"
      >
        <IconButton onClick={() => onDelete(id || "")}>
          <DeleteForeverIcon />
        </IconButton>
        <IconButton onClick={() => onEdit({type: editType, id})}>
          <EditIcon />
        </IconButton>
      </div>
    ) : null;

    // Parse children recursively
    const parsedChildren =
      Array.isArray(children) &&
      children.map((child, i) => {
        return ["string", "number"].indexOf(typeof child) >= 0
          ? (child as string | number)
          : React.createElement(TemplateParser, {
              key: "" + i,
              qrcode,
              onDelete,
              onEdit,
              editMode,
              template: child as TemplateComponent,
            });
      });

    // Return parsed element
    return React.createElement(
      parseComponentType(type),
      {...props, type, id, qrcode},
      id ? parentNodes : null,
      ...(parsedChildren || [])
    );
  }, [template, editMode, onDelete, onEdit, qrcode]);
};

export default TemplateParser;
