
import React, { useState, useLayoutEffect, useCallback } from 'react';
import context from './context';
import useNotify from '../../hooks/use-notify';
import { useAuthentication } from '../AuthenticationProvider';
import { getAllPlaces, getPlace, createPlace, updatePlace, Place, PlaceInputData } from '../../system/places';

const PlacesProvider: React.FC = ({ children }) => {
  const authenticated = !!useAuthentication()[0];
  const [places, setPlaces] = useState<Place[]>([]);
  const [loading, _setLoading] = useState(0);
  const notify = useNotify();

  const setLoading = (increment: boolean) => _setLoading(prevVal=>increment ? ++prevVal : --prevVal);

  /**
   * Get place by id.
   */
  const getById = useCallback(async (placeId: number, forceFetch = false): Promise<Place | null> => {
    try{
      const place = places.find(p=>p.id === placeId) || null;
      if(!place || forceFetch){
        setLoading(true);
        const place = await getPlace(placeId);
        setLoading(false);
        return place;
      }

      return place;
    }
    catch(err: any){
      notify(err);
      setLoading(false);
      return null;
    }

  }, [notify, places]);

  /**
   * Create new place.
   */
  const create = useCallback(async (placeData: PlaceInputData): Promise<Place | null> => {
    try{
      setLoading(true);
      const place = await createPlace(placeData);
      setPlaces(prevState=>[place, ...prevState]);
      setLoading(false);
      return place;
    }
    catch(err: any){
      setLoading(false);
      notify(err);
      return null;
    }

  }, [notify]);

  const update = useCallback(async (placeId: number, updatedPlaceData: PlaceInputData): Promise<Place | null> => {
    try{
      setLoading(true);
      const updatedPlace = await updatePlace(placeId, updatedPlaceData);
      setPlaces(places=>{
        const placeIndex = places.findIndex(p=>p.id === placeId);
        if(placeIndex >= 0)
          places.splice(placeIndex, 1, updatedPlace);
        else
          return places;

        return [...places];
      });

      setLoading(false);
      notify(`Place "${updatedPlace.name}" updated!`);
      return updatedPlace;
    }
    catch(err: any){
      setLoading(false);
      notify(err);
      return null;
    }
  }, [notify]);

  useLayoutEffect(()=>{
    if(!authenticated) return;
    
    let active = true;
    (async ()=>{
      try{
        setLoading(true);
        const places = await getAllPlaces();

        if(!active) return;
        setPlaces(places.sort((a,b)=>a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
        setLoading(false);
      }
      catch(err: any){
        if(!active) return;
        notify(err);
      }
    })();

    return ()=>{ active=false; };
  }, [notify, authenticated]);

  return (
    <context.Provider value={[places, !!loading, { getById, create, update }]}>
      {children}
    </context.Provider>
  );
}

export default PlacesProvider;
