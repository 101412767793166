
import { createContext, useContext } from 'react';
import { DateRangeContext } from './types';

const context = createContext<DateRangeContext>({
  from: new Date(0),
  to: new Date(),
  update () {}
});

export const useDateRange = () => useContext(context);
export default context;
