import React from "react";
import Dialog, {DialogProps} from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {openInNewTab} from "../../core/system/utils";

const SingleTrainingLabelDialog: React.FC<
  DialogProps & {pdfUrl: string; docid: string; isLoading?: boolean}
> = props => {
  return (
    <Dialog {...props} maxWidth={!props.pdfUrl ? props.maxWidth : "md"}>
      <DialogTitle>Print Training Label</DialogTitle>
      <DialogContent>
        {!!props.pdfUrl && (
          <iframe
            title="Training QRs (empty)"
            src={props.pdfUrl}
            width="100%"
            style={{border: "none", height: "70vh"}}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          type="button"
          onClick={() => props.onClose && props.onClose({}, "escapeKeyDown")}
          color="secondary"
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="secondary"
          type="button"
          onClick={() =>
            openInNewTab(
              `https://${process.env.REACT_APP_QR_BASENAME || "3hd.us"}/${
                props.docid
              }`
            )
          }
        >
          Open Training Video
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SingleTrainingLabelDialog;
