
import { useMemo } from 'react';
import moment from 'moment/moment';
import { StationActivityLog } from '../../system/reports';
import useStationReport from './use-station-report';

const useFirstTimeRight = (placeId: number, {fromDate=new Date(0), toDate=new Date()} = {}) => {

  const report = useStationReport(placeId);

  fromDate = fromDate || new Date(0);
  toDate = toDate || new Date();
  
  const reportData: [number, number] | null = useMemo(()=>{
    if(!report) return null;

    const lpLogsMap = (report.logs || []).reduce((map, log)=>{
      const arrivedAt = moment.utc(log.arrived_at).toDate();
      if(arrivedAt >= fromDate && arrivedAt <= toDate){
        const currSet = (map[log.license_plate.lp_id] || []);
        currSet.push(log);
        map[log.license_plate.lp_id] = currSet;
      }

      return map;
    }, {} as Record<string, StationActivityLog[]>);

    let totalRight = 0;
    let totalRework = 0;

    for(let key in lpLogsMap){
      if(lpLogsMap[key].length === 1 && !!lpLogsMap[key][0].left_at)
        totalRight++;
      else if(lpLogsMap[key].length > 1)
        totalRework++;
    }

    return [totalRight, totalRework];
  }, [fromDate, toDate, report]);

  return reportData; // [<number of lps right first time>, <reworked>]
};

export default useFirstTimeRight;
