import React from "react";
import {EnabledIcons, SocialIconsProps} from "./types";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import YouTubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import RssFeedIcon from "@material-ui/icons/RssFeed";

// import {useMemo} from "react";
import styled from "@material-ui/core/styles/styled";

const Anchor = styled("a")({
  color: "inherit",
  fontSize: "inherit",
  margin: "4px 8px",
});

// TODO: opitimize the render function and debug why the useMemo function is not working when icon changes
const SocialIcons: React.FC<SocialIconsProps> = props => {
  const {
    title,
    icons,

    ...BoxProps
  } = props;

  if (typeof icons !== "object") return null;
  const iconsList = Object.keys(icons).reduce((list, iconType, i) => {
    if (!icons[iconType as EnabledIcons]) return list; // Skip if the icon is empty

    switch (iconType) {
      case "facebook":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["facebook"] || "https://www.facebook.com"}
          >
            <FacebookIcon />
          </Anchor>
        );
        break;
      case "twitter":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["twitter"] || "https://www.twitter.com"}
          >
            <TwitterIcon />
          </Anchor>
        );
        break;
      case "instagram":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["instagram"] || "https://www.instagram.com"}
          >
            <InstagramIcon />
          </Anchor>
        );
        break;
      case "youtube":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["youtube"] || "https://www.youtube.com"}
          >
            <YouTubeIcon />
          </Anchor>
        );
        break;
      case "linkedin":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["linkedin"] || "https://www.linkedin.com"}
          >
            <LinkedInIcon />
          </Anchor>
        );
        break;
      case "rss":
        list.push(
          <Anchor
            key={`${iconType}-${i}`}
            target="_blank"
            href={icons["rss"] || "https://www.google.com"}
          >
            <RssFeedIcon />
          </Anchor>
        );
        break;
      default:
        break;
    }
    return list;
  }, [] as JSX.Element[]);

  return (
    <Box textAlign="center" {...BoxProps}>
      {!!title && (
        <Typography component="p" variant="overline" color="inherit">
          {title}
        </Typography>
      )}
      {iconsList}
    </Box>
  );
};

export default SocialIcons;
