
import React from 'react';
import { Switch, Route, Redirect, Link as RouterLink, useLocation } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Layout, { Header, Content, Slot } from '../core/components/Layout';
import ProfileSettings from './ProfileSettings';
import SecuritySettings from './SecuritySettings';
import { Container } from '@material-ui/core';

const navItems = [
  { label: 'Profile', pathname: '/settings/profile', component: ProfileSettings },
  { label: 'Password & security', pathname: '/settings/security', component: SecuritySettings }
];

const Settings: React.FC = () => {
  const location = useLocation();
  const activeTabIndex = navItems.findIndex(item=>item.pathname === location.pathname);

  return (
    <Layout>
      <Header>
        <Container maxWidth={false}>
          <Slot name="main-toolbar">
            <Typography variant="h6">Settings</Typography>
          </Slot>
          <Tabs
            value={activeTabIndex}
          >
            {navItems.map(item=>(
              <Tab key={item.pathname} component={RouterLink} to={item.pathname} label={item.label} />
            ))}
          </Tabs>
        </Container>
      </Header>
      <Content>
        <Container maxWidth={false}>
          <Paper>
            <Switch>
              {navItems.map(item=>(
                <Route path={item.pathname} component={item.component} />
              ))}

              <Redirect to="/settings/profile" />
            </Switch>
          </Paper>
        </Container>
      </Content>
    </Layout>
  );
};

export default Settings;
