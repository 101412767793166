import {useEffect, useState} from "react";

export default function useSearchFilter<T>(
  data: T[],
  keys: string,
  searchTerm: string
) {
  const [searchResults, setSearchResults] = useState(data);

  useEffect(() => {
    function applySearch() {
      const keysList = keys.split(/\s*,\s*/);
      setSearchResults(
        data.filter((item: any) => {
          let matched = false;
          for (let i = 0; i < keysList.length; i++) {
            const key = keysList[i];
            const value = item[key];
            if (typeof value === "object" && value !== null) {
              // If the value is an object, search within its properties
              for (const prop in value) {
                if (
                  value[prop] &&
                  value[prop]
                    .toString()
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  matched = true;
                  break;
                }
              }
            } else if (
              value &&
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            ) {
              matched = true;
              break;
            }
          }
          return matched;
        })
      );
    }

    applySearch();
  }, [data, keys, searchTerm]);

  return searchResults;
}
