
import { createContext } from 'react';
import { RolesContext } from './types';

const context = createContext<RolesContext>([
  [],
  false,
  {
    getAll: ()=>{}
  }
]);

export default context;
