import {createTheme} from "@material-ui/core/styles";
import grey from "@material-ui/core/colors/grey";

const theme = createTheme({
  palette: {
    primary: {
      main: "#ec1c24",
    },
    secondary: {
      main: "#007dc8",
    },
    background: {
      default: grey[100],
    },
  },
  shape: {
    borderRadius: 8,
  },
  overrides: {
    // For MuiPaper
    MuiPaper: {
      root: {
        lineHeight: 0,
        overflow: "hidden",
      },
    },
    // For MuiButton
    MuiButton: {
      root: {
        textTransform: "initial",
      },
    },
    // Add other component overrides here
  },
  props: {
    // For MuiContainer
    MuiContainer: {
      maxWidth: false,
    },
    // For MuiPaper
    MuiPaper: {
      square: true,
    },
    // For MuiAccordion in v4, it's known as ExpansionPanel
    MuiExpansionPanel: {
      square: true,
    },
    // For MuiButton
    MuiButton: {
      variant: "contained",
    },
    // Add other component props here
  },
});

export default theme;
