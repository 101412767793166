
import React, { useState } from 'react';
import { RoleEditDialogProps } from './types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import usePersons from '../../core/providers/PersonsProvider/use-persons';
import useRoles from '../../core/hooks/use-roles';

const RoleEditDialog: React.FC<RoleEditDialogProps> = props => {
  const {
    person,
    disableBackdropClick,

    children,
    ...dialogProps
  } = props;

  const [roles, loadingRoles] = useRoles();
  const [, loadingPersons, { update }] = usePersons();
  const [roleId, setRoleId] = useState(person.role?.id.toString() || '');

  const handleSave = () => {
    if(roleId === person.role?.id.toString())
      return dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');

    update(person.id, { role_ids: roleId ? [Number(roleId)] : null })
      .then(()=>{
        dialogProps.onClose && dialogProps.onClose({}, 'backdropClick');
      })
    ;
  };

  return (
    <Dialog
      disableBackdropClick={loadingPersons || disableBackdropClick}
      {...dialogProps}
    >
      <DialogTitle>Choose role for <small><u>{person.first_name} {person.last_name || ''}</u></small></DialogTitle>

      <DialogContent>
        <TextField
          label="Role"
          value={roleId}
          onChange={ev=>setRoleId(ev.target.value)}
          disabled={loadingRoles || loadingPersons}
          SelectProps={{ displayEmpty: true }}
          InputLabelProps={{ shrink: true }}
          fullWidth
          variant="outlined"
          select
        >
          <MenuItem value="">
            <div>
              <Typography variant="body1">Unassigned</Typography>
              <Typography variant="body2" color="textSecondary">This user hasn't been assigned a role.</Typography>
            </div>
          </MenuItem>
          {roles.map(role=>(
            <MenuItem key={role.id.toString()} value={role.id.toString()}>
              <div>
                <Typography variant="body1">{role.name.toUpperCase()}</Typography>
                <Typography variant="body2" color="textSecondary">{role.description || 'No description.'}</Typography>
              </div>
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={()=>dialogProps.onClose && dialogProps.onClose({}, 'backdropClick')}
          disabled={loadingPersons}
        >
          Cancel
        </Button>
        {loadingPersons && (
          <CircularProgress size={24} />
        )}
        {!loadingPersons && (
          <Button
            onClick={handleSave}
            disabled={!roleId || loadingPersons}
            color="primary"
          >Save</Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default RoleEditDialog;
